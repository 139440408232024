/**
 * Returns condensed image attribution info for any image from an external service
 * that requires attribution.
 * Currently compatible with unsplash.
 * Will return null if there is no image metadata or if the source
 * doesn't require attribution.
 * @param {*} imageMeta JS object representing image metadata
 * @returns object in format { id, authorName, imageUrl, authorUrl }
 */
const imageMetadataToAttribution = imageMetadata => {
  // not an image!
  if (!imageMetadata) {
    return null;
  }
  // I guess this could be anything, but these are the essential props
  // on an unsplash image that are required for attribution
  if (imageMetadata.links && imageMetadata.user && imageMetadata.user.links) {
    return {
      id: imageMetadata.id,
      authorName: imageMetadata.user.name,
      imageUrl: imageMetadata.links.html,
      authorUrl: imageMetadata.user.links.html,
      source: 'unsplash', // some day we could group by source if we end up with more than one source
    };
  }

  // it's something else, can't attribute it
  return null;
};

// used to return null if there's no uploaded or 3rd party catalog image,
// to return the uploaded image directly if there is one, or return the catalogImage translated into the uploaded image
// if that exists instead
const resolveCardImage = ({ uploadedImage, catalogImage }) => {
  if (!uploadedImage && !catalogImage) {
    return null;
  }

  // uploaded image wins
  if (uploadedImage) {
    return { ...uploadedImage, uri: uploadedImage.url, type: 'upload', source: 'nudge' };
  }

  // catalog image from unsplash
  if (catalogImage && catalogImage.urls) {
    return {
      ...catalogImage,
      extension: 'jpg', // it seems like unsplash tends to make everything jpegs of various sizes
      mime: 'image/jpeg', // this a) might not be entirely correct, and b) probably doesn't matter
      width: catalogImage.width,
      height: catalogImage.height,
      url: catalogImage.urls.regular,
      uri: catalogImage.urls.regular, // uri is used by React Native image sources, we may remove it later
      thumbnailUrl: catalogImage.urls.thumb,
      type: 'catalog',
      source: 'unsplash', // only source right now
    };
  }

  // nothing - possibly bad meta?
  return null;
};

export { imageMetadataToAttribution, resolveCardImage };
