import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Platform } from 'react-native';
import { observer } from 'mobx-react';
import getLogDisplayComponents from './getLogDisplayComponents';

class ValueComponent extends Component {
  render() {
    const {
      value,
      units,
      trackerColor,
      style,
      valueTextStyle,
      unitsTextStyle,
      valueTextProps,
      unitsTextProps,
      showSpaceAfter = true,
    } = this.props;
    return (
      <View style={{ flexDirection: 'row', flexShrink: 1, ...style }}>
        <Text
          style={[
            valueTextStyle,
            ...(Platform.OS === 'web' ? [{ width: '100%' }] : []),
            {
              color: value ? trackerColor : '#666666',
            },
          ]}
          {...valueTextProps}>
          {value || value === 0 ? value : '-' /* show zeroes for totals instead of dash */}
        </Text>
        {units ? (
          <Text
            style={[
              {
                alignSelf: 'flex-end',
              },
              unitsTextStyle,
            ]}
            {...unitsTextProps}>
            {' ' + units}
          </Text>
        ) : null}
        {showSpaceAfter ? <Text>{` ` /* single space between fields */}</Text> : null}
      </View>
    );
  }
}

/**
 * show a tracker based on stats collected about the tracker OR an individual log
 */
function TrackerValue({
  stats,
  log,
  tracker,
  trackerColor,
  useMetricUnits,
  valueTextStyle,
  darkTextColor,
  fieldAbbreviations,
  valueComponentStyle,
  unitsTextStyle,
  valueTextProps,
  unitsTextProps,
  useLongFormForUnits = false,
  useGoalOnlyFormatForZeroTotal = false,
  usePreferredTimeUnit = false,
  goalPreamble,
  TextComponent,
  ViewComponent,
}) {
  const myTrackerColor =
    tracker.palettesId && tracker.clientMeta.type !== 'custom-question-freeform'
      ? trackerColor
      : darkTextColor;

  const components = getLogDisplayComponents({
    stats,
    log,
    tracker,
    useMetricUnits,
    fieldAbbreviations,
    usePreferredTimeUnit: usePreferredTimeUnit || (stats && !!stats.goal),
    useLongFormForUnits,
    useGoalOnlyFormatForZeroTotal,
  });

  const goalComponents = components.goal;
  const valueComponents = components.value;

  const combinedUnitsTextStyle = [
    {
      alignSelf: 'flex-end',
    },
    unitsTextStyle,
  ];

  if (
    goalComponents &&
    !(
      stats.aggregateType === 'count' && stats.goal <= 1
    ) /* this keeps a goal from showing for an assessment, which is always "enter this one time" */
  ) {
    if (stats.aggregateType === 'sum') {
      if (useGoalOnlyFormatForZeroTotal && !stats.latestLog && !stats.total) {
        return (
          <ViewComponent style={{ flexDirection: 'row', flexShrink: 1 }}>
            {goalPreamble}
            <TextComponent style={combinedUnitsTextStyle}>(Goal: </TextComponent>
            {goalComponents.map(component => (
              <ValueComponent
                key={component.field}
                value={component.value}
                units={component.units}
                trackerColor={myTrackerColor}
                style={valueComponentStyle}
                valueTextStyle={combinedUnitsTextStyle}
                unitsTextStyle={unitsTextStyle}
                unitsTextProps={unitsTextProps}
                valueTextProps={valueTextProps}
                showSpaceAfter={false}
              />
            ))}
            <TextComponent style={combinedUnitsTextStyle}>)</TextComponent>
          </ViewComponent>
        );
      }
      return (
        <ViewComponent style={{ flexDirection: 'row', flexShrink: 1 }}>
          {valueComponents.map(component => (
            <ValueComponent
              key={component.field}
              value={component.value}
              trackerColor={myTrackerColor}
              style={valueComponentStyle}
              valueTextStyle={valueTextStyle}
              unitsTextStyle={unitsTextStyle}
              unitsTextProps={unitsTextProps}
              valueTextProps={valueTextProps}
            />
          ))}
          <TextComponent style={combinedUnitsTextStyle}>{'of '}</TextComponent>
          {goalComponents.map(component => (
            <ValueComponent
              key={component.field}
              value={component.value}
              units={component.units}
              trackerColor={myTrackerColor}
              style={valueComponentStyle}
              valueTextStyle={valueTextStyle}
              unitsTextStyle={unitsTextStyle}
              valueTextProps={valueTextProps}
              unitsTextProps={unitsTextProps}
            />
          ))}
        </ViewComponent>
      );
    }
  }

  // TODO: separate "times" format for number of logs goal

  return (
    <View style={{ flexDirection: 'row', flexShrink: 1 }}>
      {valueComponents.map(component => (
        <ValueComponent
          key={component.field}
          value={component.value}
          units={component.units}
          trackerColor={myTrackerColor}
          style={valueComponentStyle}
          valueTextStyle={valueTextStyle}
          unitsTextStyle={unitsTextStyle}
          valueTextProps={valueTextProps}
          unitsTextProps={unitsTextProps}
        />
      ))}
    </View>
  );
}

TrackerValue.propTypes = {
  stats: PropTypes.object,
  log: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  tracker: PropTypes.object.isRequired,
  trackerColor: PropTypes.string.isRequired,
  useMetricUnits: PropTypes.bool,
  valueTextStyle: PropTypes.any,
  darkTextColor: PropTypes.string,
  fieldAbbreviations: PropTypes.object,
  unitsTextStyle: PropTypes.any,
  valueTextProps: PropTypes.object,
  useLongFormForUnits: PropTypes.bool,
  useGoalOnlyFormatForZeroTotal: PropTypes.bool,
  goalPreamble: PropTypes.any,
  TextComponent: PropTypes.any.isRequired,
  ViewComponent: PropTypes.any.isRequired,
};

TrackerValue.defaultProps = {
  stats: null,
  log: null,
  useMetricUnits: false,
  valueTexstStyle: null,
  darkTextColor: 'black',
  fieldAbbreviations: {
    SYSTOLIC: 'sys',
    DIASTOLIC: 'dia',
    MINHEARTRATE: 'min',
    MAXHEARTRATE: 'max',
    AVGHEARTRATE: 'avg',
    HEARTRATE: 'bpm',
  },
  unitsTextStyle: null,
  valueTextProps: {},
  useLongFormForUnits: false,
  useGoalOnlyFormatForZeroTotal: false,
  goalPreamble: null,
};

export { ValueComponent, TrackerValue };
export default observer(TrackerValue);
