import { types } from 'mobx-state-tree';
import ProgramAssignment from './ProgramAssignment';

const UserProgramAssignment = types
  .model({
    id: types.identifierNumber,
    startAt: types.maybeNull(types.string),
    endAt: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    programShareId: types.maybeNull(types.number),
    share: types.maybeNull(ProgramAssignment),
  })
  .views(self => ({
    get distributor() {
      return self.share.distributor;
    },
  }));

export default UserProgramAssignment;
