const uomEnum = {
  percent: 'percent',
  servings: 'svg',
  miles: 'mi',
  kilometers: 'km',
  milligramsPerDeciliter: 'mg/dL',
  pounds: 'lbs',
  kilograms: 'kg',
  calories: 'cal',
  steps: 'steps',
  nudgeFactor: 'nudge_factor',
  hours: 'hours',
  minutes: 'minutes',
};

const unitsForValueAndUom = (value, uom, useLongForm) => {
  if (uom === uomEnum.percent) {
    return '%';
  }
  if (uom === uomEnum.pounds) {
    return value === 1 ? 'lb' : 'lbs';
  }
  if (uom === uomEnum.servings) {
    if (useLongForm) {
      return value === 1 ? 'serving' : 'servings';
    }
    return value === 1 ? 'svg' : 'svgs';
  }
  if (uom === uomEnum.calories) {
    // use long form across the board
    //if (useLongForm) {
    return value === 1 ? 'calorie' : 'calories';
    //}
    //return 'cal';
  }
  if (uom === uomEnum.milligramsPerDeciliter) {
    return 'mg/dL';
  }
  if (uom === uomEnum.steps) {
    return value === 1 ? 'step' : 'steps';
  }
  if (uom === uomEnum.nudgeFactor) {
    return '';
  }
  if (uom === uomEnum.hours) {
    return value === 1 ? 'hour' : 'hours';
  }
  if (uom === uomEnum.minutes) {
    return value === 1 ? 'minute' : 'minutes';
  }
  // TODO more of these
  return uom;
};

export { unitsForValueAndUom };
