import { types, flow, getEnv } from 'mobx-state-tree';
import { find, sortBy, uniqBy } from 'lodash';
import LoadingState from './LoadingState';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function lowercaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

/**
 * Used to create generic stores or parts of stores that load a data set and can then page through it.
 * Outputs an MST model with customized props, views, and actions:
 * Props:
 *  - {objectName} - array containing loaded ojbects
 *  - load{objectName}State - LoadingState for initial load
 *  - loadAdditional{objectName}State - LoadingState for subsequent loads
 *  - {objectName}NextPage - next page number to load
 *  - id: client or user ID that is the focus of the store
 * Views:
 *  - {objectName}Sorted - sorted array of objects
 *  - isAtEndOf{objectName}History - if true, no more additional pages to load
 * Actions:
 *  - load{objectName} - initial load
 *  - loadAdditional{objectName} - load more data
 *
 * friendlyObjectPluralName - used to name load functions.
 *  So, if friendly plural name was "Pandas", creates loadPandas() and loadAdditionalPandas(), loadPandasState and loadAdditionalPandasState, pandas array prop, and pandasSorted + isAtEndOfPandasHistory view
 * ObjectClass - MST model used for resulting objects
 * repositoryName - name of dependency used to load objects, e.g., "pandaRespository"
 * repositoryFetchFunctionName - name of function called on repository, which takes an object with id (for user ID and page (optional, for pages other than 1))
 * sortKeyGeneratorFunction - function that takes ObjectClass and generates a sort key, used
 * pagingMode - defaults to modern v4+ paging API, but can be overridden
 * hasId - if true, this store has its own ID for the purpose of keeping track of its parent object and passing that ID to requests
 * objectClassIdPropName - the ID prop for the ObjectClass

 * @returns MST model conforming to the description above
 */
function pageableStoreFactory({
  friendlyObjectSingularName,
  friendlyObjectPluralName,
  ObjectClass,
  repositoryName,
  repositoryFetchFunctionName,
  sortKeyGeneratorFunction = () => 1,
  pagingMode = 'modern', // options: v3, none
  identifier = 'number', // options: number, string
  getExtraFetchParams = () => null,
  hasId = true,
  objectClassIdPropName = 'id',
}) {
  const lowerCaseObjectName = lowercaseFirstLetter(friendlyObjectPluralName);
  const upperCaseObjectName = capitalizeFirstLetter(friendlyObjectPluralName);
  const lowerCaseSingularObjectName = lowercaseFirstLetter(friendlyObjectSingularName);

  const modelProps = {
    [`load${upperCaseObjectName}State`]: types.optional(LoadingState, {}),
    [`loadAdditional${upperCaseObjectName}State`]: types.optional(LoadingState, {}),
    [`${lowerCaseObjectName}`]: types.optional(types.array(ObjectClass), []),
    [`${lowerCaseObjectName}NextPage`]: types.maybeNull(types.number),
  };

  if (hasId) {
    modelProps.id = types.maybeNull(
      identifier === 'number' ? types.identifierNumber : types.identifier
    );
  }

  const PageableStore = types
    .model(`${upperCaseObjectName}Store`, modelProps)
    .views(self => {
      return {
        get [`${lowerCaseObjectName}Sorted`]() {
          return sortBy(self[`${lowerCaseObjectName}`], obj => sortKeyGeneratorFunction(obj));
        },
        get [`isAtEndOf${upperCaseObjectName}History`]() {
          return self[`${lowerCaseObjectName}NextPage`] === null;
        },
        [`${lowerCaseSingularObjectName}ForId`](id) {
          return find(self[`${lowerCaseObjectName}`], f => f[objectClassIdPropName] === id);
        },
      };
    })
    .actions(self => {
      const loadObjects = flow(function* loadObjects() {
        const loadObjectsState = `load${upperCaseObjectName}State`;
        self[loadObjectsState].setPending();
        try {
          const extraFetchParams = getExtraFetchParams(self);
          const response = yield getEnv(self)[repositoryName][repositoryFetchFunctionName]({
            id: self.id,
            ...extraFetchParams,
          });
          let updatedObjects;
          if (pagingMode === 'v3' || pagingMode === 'none') {
            updatedObjects = response;
            if (pagingMode === 'v3') {
              if (updatedObjects.length < 20) {
                self[`${lowerCaseObjectName}NextPage`] = null;
              } else {
                self[`${lowerCaseObjectName}NextPage`] = 2;
              }
            }
          } else {
            updatedObjects = response.data;
            if (response.currentPage < response.lastPage) {
              self[`${lowerCaseObjectName}NextPage`] = 2;
            } else {
              self[`${lowerCaseObjectName}NextPage`] = null;
            }
          }
          self[lowerCaseObjectName].replace(updatedObjects);
          self[loadObjectsState].setDone();
        } catch (error) {
          console.log(error);
          self[loadObjectsState].setFailed(error);
        }
      });

      const loadAdditionalObjects = flow(function* loadAdditionalObjects() {
        const loadAdditionalObjectsState = `loadAdditional${upperCaseObjectName}State`;
        // should we prevent this from being called if history is complete? We do that in TopicFeed
        if (!self[loadAdditionalObjectsState].isPending) {
          self[loadAdditionalObjectsState].setPending();
          try {
            const extraFetchParams = getExtraFetchParams(self);
            const response = yield getEnv(self)[repositoryName][repositoryFetchFunctionName]({
              id: self.id,
              page: self[`${lowerCaseObjectName}NextPage`],
              ...extraFetchParams,
            });
            let additionalObjects;
            if (pagingMode === 'v3') {
              additionalObjects = response;
              if (additionalObjects.length < 20) {
                self[`${lowerCaseObjectName}NextPage`] = null;
              } else {
                self[`${lowerCaseObjectName}NextPage`] = self[`${lowerCaseObjectName}NextPage`] + 1;
              }
            } else {
              additionalObjects = response.data;
              if (response.currentPage < response.lastPage) {
                self[`${lowerCaseObjectName}NextPage`] = self[`${lowerCaseObjectName}NextPage`] + 1;
              } else {
                self[`${lowerCaseObjectName}NextPage`] = null;
              }
            }
            self[`${lowerCaseObjectName}`].replace(
              uniqBy(
                self[lowerCaseObjectName].concat(additionalObjects),
                m => m[objectClassIdPropName]
              )
            );
            self[loadAdditionalObjectsState].setDone();
          } catch (error) {
            self[loadAdditionalObjectsState].setFailed(error);
          }
        }
      });

      const myActions = {
        [`load${upperCaseObjectName}`]: loadObjects,
      };

      if (pagingMode !== 'none') {
        myActions[`loadAdditional${upperCaseObjectName}`] = loadAdditionalObjects;
      }

      return myActions;
    });

  return PageableStore;
}

export default pageableStoreFactory;
