import { DateTime } from 'luxon';

/**
 * Turn data points into a series of graph x,y coordinates that can be turned into a line graph or polygon.
 *
 * @params
 * dateValuePairs: equivalent to data prop, includes an array of objects with dates and numerical values
 * tailValuePair: dateValuePair that should trail off to the left (optional)
 * headValuePair: dateValuePair that should trail off to the right (optional)
 * drawHeight/ drawWidth/ columnWidth: dimensions to determine drawable width, spacing between points
 * minValue/ maxValue: extend this past the actual min/ max data point to make the possible values covered by the graph larger
 * @returns object with following props:
 * dataPoints: array of x,y plots that represent actual data points
 * pathPoints: array of x,y plots that represent lines to draw (includes head and tail lines, which do not have equivalent data points)
 */
function getLinePoints({
  dateValuePairs,
  minValue,
  maxValue,
  drawHeight,
  drawWidth,
  columnWidth,
  tailValuePair,
  headValuePair,
}) {
  const data = dateValuePairs;
  const tail = tailValuePair;
  const head = headValuePair;

  const minDate = Math.max.apply(null, data.map(g => DateTime.fromISO(g.date).toUTC()));
  const maxDate = Math.min.apply(null, data.map(g => DateTime.fromISO(g.date).toUTC()));

  let graphMaxValue = maxValue;
  let graphMinValue = minValue;

  // filter out null values so we show a gap at the beginning of the graph
  const dataPoints = [];
  data.forEach((value, index) => {
    if (value.value !== null && value.value !== undefined) {
      dataPoints.push({
        x: columnWidth * index + columnWidth / 2,
        y:
          drawHeight -
          drawHeight * ((value.value - graphMinValue) / (graphMaxValue - graphMinValue)),
        date: value.date,
        value: value.value,
      });
    }
  });
  // this shouldn't happen but just in case
  if (dataPoints.length === 0 && !tail) {
    return { pathPoints: [], dataPoints: [] };
  }

  let pathPoints = [...dataPoints];

  if (tail /*&& data[0].value === null*/) {
    // Find the x and y coordinates of the tail
    const tailX = 0;
    let tailY =
      drawHeight - drawHeight * ((tail.value - graphMinValue) / (graphMaxValue - graphMinValue));
    // limits the accuracy of the angle, but negative values make weird stuff happen
    if (tailY < 0) {
      tailY = 0;
    }
    if (tailY > drawHeight) {
      tailY = drawHeight;
    }
    let point = dataPoints[0];
    // if no data points to branch off of, create a virtual point that corresponds to the tail value
    // This gives us a starting point for a flat line (or a line that goes in the direction of a head)
    // in the case that there are no data points
    if (!point) {
      point = {
        x: tailX,
        y: tailY,
        date: minDate,
        value: tail.value,
      };
    }
    // Interpolate the y value at x = 0
    let finalYPoint =
      tailX === point.x ? point.y : point.y - (point.x * (point.y - tailY)) / (point.x - tailX);
    if (finalYPoint < 0) {
      finalYPoint = 0;
    }
    if (finalYPoint > drawHeight) {
      finalYPoint = drawHeight;
    }
    pathPoints.unshift({
      x: 0,
      y: finalYPoint,
      date: minDate,
    });
  }

  // The head is either the head (first point after slice)
  // Or if there are no data points at all besides a tail, we can make a head out of the tail and the last date
  // This effectively makes a flat line.
  const effectiveHead =
    head ||
    (dataPoints.length === 0 ? { value: tail.value, date: data[data.length - 1].date } : null);

  if (effectiveHead /* && data[data.length - 1].value === null*/) {
    //Find the x and y coordinates of the head
    const headX = drawWidth;
    let headY =
      drawHeight -
      drawHeight * ((effectiveHead.value - graphMinValue) / (graphMaxValue - graphMinValue));
    // limits the accuracy of the angle, but negative values make weird stuff happen
    if (headY < 0) {
      headY = 0;
    }
    if (headY > drawHeight) {
      headY = drawHeight;
    }
    let point = dataPoints[dataPoints.length - 1];
    // If no data points to branch off of, use the first path point (the tail start in this case)
    // This helps with our flat line when there are no values
    if (!point) {
      point = pathPoints[0];
    }
    //Interpolate the y value at x = drawWidth
    let finalYPoint =
      headX === point.x
        ? point.y
        : ((drawWidth - point.x) * (headY - point.y)) / (headX - point.x) + point.y;
    if (finalYPoint < 0) {
      finalYPoint = 0;
    }
    if (finalYPoint > drawHeight) {
      finalYPoint = drawHeight;
    }
    pathPoints.push({
      x: drawWidth,
      y: finalYPoint,
      date: maxDate,
    });
  }

  if (!pathPoints.length) {
    return { pathPoints, dataPoints };
  }

  return { pathPoints, dataPoints };
}

export default getLinePoints;
