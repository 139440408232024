import { types, getEnv, getParentOfType, getSnapshot, applySnapshot } from 'mobx-state-tree';
import { sortBy, remove, unionBy, filter } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { v4 as uuid } from 'uuid';
import { computedFn } from 'mobx-utils';
import idx from 'idx';
import { getGraphSlice } from '../../graphs/data';
import { latestHistoricalForDate } from '../../data/trackers';
import {
  userTimeToJsDate,
  jsDateToUserTime,
  dateRangeForInterval,
  toDateTime,
} from '../../lib/dates';
// something is not quite right when trying to use these two from the common lib so far
// totals are zero. Could be an error with either one.
import { totalForDate, logsForDate, totalForWeek } from './trackerStatUtils';

// validation for primary log value entry
const validators = {
  number: (log, fieldName) => {
    return !!(log[fieldName] && log[fieldName] > 0);
  },
  bloodPressure: log => !!(log.systolic && log.diastolic),
  string: () => true,
  heartRate: log => !!log.heartRate || !!(log.avgHeartRate || log.minHeartRate || log.maxHeartRate),
  none: () => true,
};

const computedGraphSlice = computedFn(function(startDate, endDate, timeframe, self) {
  return getGraphSlice({
    startDate,
    endDate,
    logs: self.logs.slice(),
    graphType: self.clientMeta.graphConfig.type,
    primaryField: self.clientMeta.primaryField,
    requiresDeduplication: !!self.clientMeta.deduplicationRule,
    deduplicationRule: self.clientMeta.deduplicationRule,
    calculateAverage: self.clientMeta.aggregateType === 'total',
    includeExtraData: true,
    timeframe,
    interpolateDates: self.clientMeta.interpolateDates,
  });
});

const computedStatsFor = computedFn(function(date, interval, aggregateType, today, self) {
  const myDate = date || today;
  let statEntry = {
    total: null, //any single number totals - sum or count
    interval,
    latestLog: null, // any latest records
    aggregateType,
    // used to track stats for affirmative zero
    hasValidEntries: false,
  };

  //console.log('count stats' + '|' + myDate + '|' + self.id);

  const filteredLogs = self.logsCountableForStats;
  statEntry.numEntriesForInterval = filteredLogs;

  if (aggregateType === 'sum') {
    let totalStats;
    if (interval === 'day') {
      totalStats = totalForDate({
        date: myDate,
        logs: filteredLogs,
      });
    }
    if (interval === 'week') {
      totalStats = totalForWeek({
        date: myDate,
        logs: filteredLogs,
      });
    }
    statEntry.total = totalStats.total;
    statEntry.hasValidEntries =
      totalStats.total || (totalStats.numRecords > 0 && self.clientMeta.zeroEntryAllowed);
  } else if (aggregateType === 'count') {
    if (interval === 'day') {
      statEntry.total = filteredLogs.filter(l => l.date === myDate).length;
    }
    if (interval === 'week') {
      const range = dateRangeForInterval({ date: myDate, interval });
      statEntry.total = filteredLogs.filter(
        l => l.date >= range.startDate && l.date <= range.endDate
      ).length;
    }
  } else if (aggregateType === 'latestForInterval') {
    const range = dateRangeForInterval({ date: myDate, interval });
    statEntry.latestLog = latestHistoricalForDate({
      date: myDate,
      logs: filter(filteredLogs, l => l.date >= range.startDate && l.date <= range.endDate),
    });
  } else if (aggregateType === 'latestHistorical') {
    statEntry.latestLog = latestHistoricalForDate({
      date: myDate,
      logs: filteredLogs,
    });
    // also report a total for that latest date
    if (self.canBeTotaled) {
      const totalStats = totalForDate({
        date: statEntry.latestLog ? statEntry.latestLog.date : null,
        logs: filteredLogs,
      });
      statEntry.total = totalStats.total;
      statEntry.hasValidEntries =
        totalStats.total || (totalStats.numRecords > 0 && self.clientMeta.zeroEntryAllowed);
    }
  }

  return statEntry;
});

/**
 * The Log! Contains all log data properties as well as userTime and source.
 * Provides display, entry, and validation of log entries. Also differentiates between a log loaded from the server
 * and a log just created for local editing.
 * Volatile state manages some convenience props to help with various display states during editing.
 * This is constructed by loading a standard tracker from the API and augmenting it with log metadata from
 * the TrackerMetadataProvider, which in particular provides deduplication props.
 */
const Log = types
  .model('Log', {
    // server ID or temporary local id. Use UUID for ID during temp creation
    id: types.optional(types.identifier, () => uuid()),
    // could be cardio or pedometer- for activity types to indicate timed vs. untimed
    // this type must be fed into the getLogCatelog() from client-common in order to calculate crossed-out
    // values for steps and activity.
    // hack for getLogCatalog() timed vs untimed detection
    type: types.maybeNull(types.string),
    source: types.string,
    via: types.maybeNull(types.string),
    userTime: types.string,
    notes: types.maybeNull(types.string),
    // ALWAYS IMPERIAL - we no longer do converation at the repo level
    distance: types.maybeNull(types.number),
    duration: types.maybeNull(types.number),
    hiDuration: types.maybeNull(types.number),
    quantity: types.maybeNull(types.number),
    bloodGlucose: types.maybeNull(types.number),
    systolic: types.maybeNull(types.number),
    diastolic: types.maybeNull(types.number),
    calories: types.maybeNull(types.number),
    carbohydrates: types.maybeNull(types.number),
    fat: types.maybeNull(types.number),
    fiber: types.maybeNull(types.number),
    protein: types.maybeNull(types.number),
    sodium: types.maybeNull(types.number),
    fatRatio: types.maybeNull(types.number),
    // ALWAYS IMPERIAL - we no longer do converation at the repo level
    weight: types.maybeNull(types.number),
    response: types.maybeNull(types.string),
    // cardio activity ID. These are fixed in the tracker client metadata
    activityId: types.maybeNull(types.number),
    // heart rate fields
    heartRate: types.maybeNull(types.number),
    minHeartRate: types.maybeNull(types.number),
    maxHeartRate: types.maybeNull(types.number),
    avgHeartRate: types.maybeNull(types.number),
    // finance fields
    revenue: types.maybeNull(types.number),
    revenueLy: types.maybeNull(types.number),
    revenueYtd: types.maybeNull(types.number),
    grossProfit: types.maybeNull(types.number),
    grossProfitLy: types.maybeNull(types.number),
    grossProfitYtd: types.maybeNull(types.number),
    cogs: types.maybeNull(types.number),
    grossProfitMargin: types.maybeNull(types.number),
    grossProfitMarginLy: types.maybeNull(types.number),
    netIncome: types.maybeNull(types.number),
    netIncomeLy: types.maybeNull(types.number),
    netIncomeYtd: types.maybeNull(types.number),
    netCashIncrease: types.maybeNull(types.number),
    netCashIncreaseLy: types.maybeNull(types.number),

    meta: types.frozen(), // metadata from the server

    // Exerbotics fields
    strIdx: types.maybeNull(types.number),
    baseline: types.maybeNull(types.number),
    baselineUserTime: types.maybeNull(types.string),

    // log-level metadata used to determine de-duplicating, other formatting and summation stuff
    clientMeta: types.frozen(),
    // use to indicate a record is saved. Prefill with server ID when loading fresh from API
    // not required for read-only use
    serverId: types.maybeNull(types.number),
  })
  .volatile(() => ({
    // *** editing state helpers for client app ***

    // for new log entry state only - way easier to attach this to the log
    // if true, comments are open for a new log entry
    showNotesForNewEntry: false,
    // for updating logs, if dirty, we try to save over it
    // TODO: might no longer be needed (was for inline program card entry)
    isDirty: false,
    // stores original state before editing, so we can cancel edits
    undoSnapshot: null,
  }))
  .views(self => ({
    // *** display helpers ***

    get sortKey() {
      // don't mix your cases here! (at least not in the same segment)
      // not good for ASCII ordering!

      // synced towards the top, group synced together
      let sourceModifier =
        self.source.toLowerCase() === 'nudge'
          ? 'zz'
          : self.source.length > 1
            ? self.source.substr(0, 2).toLowerCase()
            : 'aa';
      // crossed out at the bottom of each synced section
      const crossedOutModifier = self.isCrossedOut ? 'z' : 'a';
      // untimed cardio at the top
      const untimedCardioModifier = self.isUntimedActivity ? 'a' : 'a';
      // after that, sort by time
      const dateTime = DateTime.fromSQL(self.userTime, { zone: 'utc' });
      const timeModifier = dateTime
        .toJSDate()
        .getTime()
        .toString();

      const isNewModifier = self.isNew ? 'z' : 'a';

      return [
        isNewModifier,
        sourceModifier,
        crossedOutModifier,
        untimedCardioModifier,
        timeModifier,
      ].join('|');
    },
    // gets the primary value if available
    get primaryValue() {
      const primaryField = self.trackerClientMeta.primaryField;
      // if array of multiple fields, output composite of those fields
      if (Array.isArray(primaryField)) {
        const primaryValueObj = {};
        primaryField.forEach(field => {
          primaryValueObj[field] = self[field];
        });
        return primaryValueObj;
      }
      if (primaryField) {
        return self[primaryField];
      }
      return null;
    },
    // true if user time (probably) does not reflect the time of day
    get isTimeNotLogged() {
      return (
        self.isSynced &&
        self.jsUserTime.getHours() === 0 &&
        self.jsUserTime.getMinutes() === 0 &&
        self.jsUserTime.getSeconds() === 0 &&
        self.isUntimedActivity
      );
    },
    get activityName() {
      if (self.activityId) {
        const definition = self.trackerClientMeta;
        if (definition.activityOptions) {
          const activity = definition.activityOptions.find(a => a.id === self.activityId);
          if (activity) {
            return activity.name;
          }
        }
      }
      return null;
    },
    // source with data provider generalized - basically this is for apple and its different source names
    get effectiveSource() {
      if (self.via && self.via.startsWith('Apple')) {
        return 'Apple';
      }
      return self.source;
    },

    // *** stat helpers ***

    isLoggedOnDate(dateString) {
      // really important! Always start from userTime on date comparisons
      // Don't risk time zone translations!
      // Usertime is timezone independent, so if you start from SQL, no risk it gets
      // adjusted to another day due to time zone stuff
      const logDateTime = DateTime.fromSQL(self.userTime);
      const comparisonDateTime = DateTime.fromSQL(dateString);
      return logDateTime.hasSame(comparisonDateTime, 'day');
    },
    get date() {
      const segments = self.userTime.split(' ');
      return segments[0];
    },

    // *** editing helpers ***

    get isNew() {
      return self.serverId === null;
    },
    // whether it's a new or edited record, return true if the record should be saved
    get shouldSave() {
      return (self.isNew && self.isValid) || (self.canEdit && self.isDirty);
    },
    get isValid() {
      const definition = self.trackerClientMeta;

      return validators[definition.validator](self, definition.primaryField);
    },
    // helper to indicate when a log counts for stats (because some don't)
    get showNotes() {
      return (self.isNew && self.showNotesForNewEntry) || (self.notes && self.notes.length);
    },
    get isSynced() {
      return self.source.toLowerCase() !== 'nudge';
    },
    get isManualEntry() {
      return self.source.toLowerCase() === 'nudge';
    },
    get canEdit() {
      return self.source.toLowerCase() === 'nudge';
    },
    // only returns true if record is editable and has been edited
    get hasPendingEdits() {
      return self.undoSnapshot && !self.isNew && self.isDirty;
    },

    // *** convenience props - used to be on main object, still used by client app ***

    get jsUserTime() {
      return userTimeToJsDate(self.userTime);
    },
    get activityType() {
      return self.type;
    },
    // log with a specific time associated (all manual entries, workouts)
    get isTimedActivity() {
      if (!self.clientMeta) {
        return true;
      }
      return self.clientMeta.isTimedActivity;
    },
    // log without a specific time (daily aggregate activity/ steps from synced source)
    get isUntimedActivity() {
      if (!self.clientMeta) {
        return false;
      }
      return self.clientMeta.isUntimedActivity;
    },
    // is a timed activity under an untimed activity
    get isChild() {
      if (!self.clientMeta) {
        return false;
      }
      return self.clientMeta.isChild;
    },
    // not even worth displaying (like an untimed activity that is less than the sum of timed activities from the same source)
    get isRedundant() {
      if (!self.clientMeta) {
        return false;
      }
      return self.clientMeta.isRedundant;
    },
    // show it, but don't count it (deduplicted synced records)
    get isCrossedOut() {
      if (!self.clientMeta) {
        return false;
      }
      return self.clientMeta.isCrossedOut;
    },
    // smoothed out source - removes weirdness like "Apple Health Aggregate" vs "Apple Watch"
    get displaySource() {
      if (!self.clientMeta) {
        return '';
      }
      return self.clientMeta.displaySource;
    },
    get trackerId() {
      return getParentOfType(self, Tracker).id;
    },
    get trackerType() {
      return getParentOfType(self, Tracker).type;
    },
    get trackerClientMeta() {
      return getParentOfType(self, Tracker).clientMeta;
    },
    get endTime() {
      return userTimeToJsDate(self.userTime);
    },
    get startTime() {
      const endDateTime = toDateTime(self.userTime);

      const startDateTime = endDateTime.minus(
        Duration.fromObject({ seconds: self.hiDuration ? self.hiDuration : self.duration })
      );

      return startDateTime.toJSDate();
    },
    get isRoutine() {
      return ['passive', 'pedometer'].includes(self.type);
    },

    // *** exerbotics-specific convenience props for use with tracker display properties ***
    // For future customizations, let's break these out into a separate file and use types.compose()
    get protocolName() {
      return idx(self, _ => _.meta.protocol.name);
    },
    get protocolDescription() {
      return idx(self, _ => _.meta.protocol.description);
    },
    get locationName() {
      return idx(self, _ => _.meta.locationName);
    },
  }))
  .actions(self => {
    const setField = ({ value, fieldName }) => {
      self[fieldName] = value;
      self.isDirty = true;
    };
    const setPrimaryValue = value => {
      // TODO: provide support for entering metric values and translating them to imperial
      const primaryField = self.trackerClientMeta.primaryField;
      // if multiple primary fields, set each value in value obj
      if (Array.isArray(primaryField)) {
        primaryField.forEach(field => {
          self[field] = value[field];
        });
      } else {
        self[primaryField] = value;
      }
      self.isDirty = true;
    };

    /**
     * update userTime
     * @param {*} time - string or Date
     */
    const setUserTime = time => {
      if (typeof time === 'string') {
        self.userTime = time;
      } else {
        self.userTime = jsDateToUserTime(time);
      }
      self.isDirty = true;
    };

    const setDate = date => {
      let givenDateTime = DateTime.fromSQL(date);
      const now = DateTime.local();
      // 2) add local time
      givenDateTime = givenDateTime.plus({
        hours: now.hour,
        minutes: now.minute,
        seconds: now.second,
      });
      const newLogTime = givenDateTime.toJSDate();
      self.userTime = jsDateToUserTime(newLogTime);
    };

    const setFieldValue = ({ field, value }) => {
      self[field] = value;
      self.isDirty = true;
    };

    const setNotes = notes => {
      // TODO: doesn't adapt based on language
      const definition = self.trackerClientMeta;
      if (definition.isActivityTypeRequired && definition.changeActivityTypeBasedOnNotes) {
        if (notes) {
          const activity = definition.activityOptions.find(
            a => a.name.toUpperCase() === notes.toUpperCase()
          );
          if (activity) {
            self.activityId = activity.id;
          } else {
            self.activityId = definition.defaultActivityType;
          }
        } else {
          self.activityId = definition.defaultActivityType;
        }
      }

      self.notes = notes;
      self.isDirty = true;
    };

    const setActivity = id => {
      self.activityId = id;
      self.isDirty = true;
    };

    const updateServerId = id => {
      self.serverId = id;
    };

    const toggleNotesVisibilityOnNewEntry = enabled => {
      if (self.isNew) {
        self.showNotesForNewEntry = enabled;
        if (!enabled) {
          self.notes = '';
        }
      }
    };

    // after saving an updated record, make sure it's not dirty
    function clearDirtyBit() {
      self.isDirty = false;
    }

    // trim leading and trailing spaces, usually just before save
    function cleanStrings() {
      if (self.notes) {
        self.notes = self.notes.trim();
      }
      if (self.response) {
        self.response = self.response.trim();
      }
    }

    function setUndoPointForEditing() {
      self.undoSnapshot = null;
      if (!self.isNew) {
        self.undoSnapshot = getSnapshot(self);
      }
    }

    function revertEdits() {
      if (self.undoSnapshot) {
        self.isDirty = false;
        applySnapshot(self, self.undoSnapshot);
      }
      self.undoSnapshot = null;
    }

    return {
      setField,
      setUserTime,
      setNotes,
      setDate,
      setPrimaryValue,
      setFieldValue,
      updateServerId,
      setActivity,
      toggleNotesVisibilityOnNewEntry,
      clearDirtyBit,
      cleanStrings,
      setUndoPointForEditing,
      revertEdits,
    };
  });

// sub-object of Tracker
const UserData = types.model('UserData', {
  logs: types.optional(types.array(Log), []),
  settings: types.frozen(),
});

/**
 * The Tracker! Contains logs across all loaded dates, and all display/ validation info for trackers and logs.
 * Includes views for displaying stats based on context, and graphs for any logs loaded.
 * This is constructed by loading a standard tracker from the API and augmenting it with tracker metadata from
 * the TrackerMetadataProvider.
 */
const Tracker = types
  .model('Tracker', {
    id: types.optional(types.identifier, () => uuid()),
    serverId: types.maybeNull(types.number),
    palettesId: types.frozen(),
    name: types.optional(types.string, ''),
    // metadata from the server
    meta: types.frozen(),
    user: types.optional(UserData, {}),

    // *** custom fields added to API object

    // tracker metadata, used to determine validation, various options
    // the same as log.trackerClientMeta
    clientMeta: types.frozen(),
    isGraphable: types.optional(types.boolean, true),
  })
  .views(self => ({
    // *** display and sorting of individual log entries ***

    get sortKey() {
      return self.rank;
    },
    logFor(id) {
      return self.logs.find(l => l.id === id);
    },
    // Logs that have a clear child relationship with a log directly above them
    // These are timed cardio entries that are superceded by an untimed entry from the same source
    childLogsForDate(date) {
      return self.logsForDateSorted(date).filter(l => l.isChild);
    },
    logsForDateSorted(date) {
      const logs = sortBy(
        logsForDate(
          {
            logs: self.logs,
            date,
          } /* ultimately uses isLoggedOnDate(), so don't need to incorporate day window shift here */
        ),
        d => d.sortKey
      );

      // Remove meaningless logs - untimed synced logs that are crossed out and are less than timed logs from the same source
      return logs.filter(l => !l.isRedundant);
    },
    logsForDateRangeSorted(startDate, endDate) {
      return sortBy(
        filter(self.logs, l => l.date >= startDate && l.date <= endDate && !l.isRedundant),
        d => d.sortKey
      );
    },

    // *** primary stats/ graphs dislay ***

    get logsCountableForStats() {
      return self.logs.filter(l => !l.isCrossedOut && !l.isNew);
    },
    /**
     * returns an object with a total for a day or week interal, the type of aggregation used,
     * and a latestLog ONLY IF the value isn't totalled.
     * We may include the latest log in the future; was trying to avoid it because it's cycles
     * wasted for (largely) nothing
     */
    statsFor({
      date,
      interval = 'day' /* or week */,
      aggregateType = 'sum' /* or count or latestForInterval or latestHistorical*/,
    }) {
      return computedStatsFor(date, interval, aggregateType, getEnv(self).getToday(), self);
    },
    /**
     * Returns object with all the information required for our graphs. Includes:
        points - array of objects with { date, value } representing each day worth of data
        head - { date, value } object representing head (first record after range) record
        tail - { date, value } object representing tail (last record before range) record,
        latestLogDate - date string for latest date where there is data,
        hasAnyData - if true, there's at least one record for this graph anywhere (even if not in slice),
        hasDataInMainFrame - if true, there's at least one record in this graph's slice range,
        startDate - echo back start date,
        endDate - echo back end date,
        timeframe - week or month (TODO: support for 90 days or year?),
        sources - array of effective source names,
        ...deltaProps - TODO: document this - in general, these are props to show weight/ body fat net change over time
     */
    summaryGraphSlice({ startDate, endDate, timeframe }) {
      return computedGraphSlice(startDate, endDate, timeframe, self);
    },
    // *** helpers mostly for log entry

    // returns UOM or metric UOM if one exists and useMetricUnits is true
    // TODO: might be able to move plural derivation here
    primaryFieldUnits(options) {
      // custom units override all
      if (self.units) {
        return self.units;
      }
      const useMetricUnits = options ? options.useMetricUnits : false;
      let desiredUom = self.clientMeta.primaryFieldUom;
      if (useMetricUnits && self.clientMeta.primaryFieldMetricUom) {
        desiredUom = self.clientMeta.primaryFieldMetricUom;
      }
      return desiredUom;
    },
    get primaryField() {
      return self.clientMeta.primaryField;
    },
    get activityOptions() {
      const definition = self.clientMeta;
      if (definition.activityOptions) {
        return definition.activityOptions;
      }
      return [];
    },
    isUserValueEnteredForDate(date) {
      return !!self.logsForDateSorted(date).find(l => !l.isSynced);
    },
    isValueEnteredForDate(date) {
      return !!self.logsForDateSorted(date).length;
    },
    // TO BE REMOVED ... gross entry stuff
    get newLog() {
      return self.logs.find(l => l.isNew);
    },

    // *** convenience props - used to be on main object, still used by client app ***

    get canBeTotaled() {
      return self.clientMeta.aggregateType === 'total';
    },
    // If true, this tracker is type is intended to show a historical value
    // This is used for weight and body fat - thinks where the previous value "carries forward"
    get shouldShowHistorical() {
      return self.clientMeta.prefillWithHistorical;
    },
    get type() {
      return self.clientMeta.type;
    },
    get isStandard() {
      return self.meta.tags.includes('Standard');
    },
    get isCustom() {
      return self.meta.tags.includes('Custom');
    },
    get isServings() {
      return self.meta.tags.includes('Servings');
    },
    get logs() {
      return self.user.logs;
    },
    get options() {
      return self.meta.config ? self.meta.config.options : [];
    },
    get units() {
      return self.meta.config ? self.meta.config.units : null;
    },
    get rank() {
      return self.user.settings.rank;
    },
    get isNew() {
      return self.serverId === null;
    },
    get supportsGoals() {
      return (
        [
          'blood-pressure',
          'blood-glucose',
          'body-fat',
          'weight',
          'sleep',
          'heart-rate',
          'custom-question-freeform',
          'custom-question-multiple-choice',
        ].indexOf(self.clientMeta.type) === -1
      );
    },
  }))
  .actions(self => {
    const addLog = log => {
      self.user.logs.push(log);
    };

    const removeLog = log => {
      const logArray = self.logs.slice();
      remove(logArray, l => l.id === log.id || l.serverId === log.serverId);
      self.user.logs = logArray;
    };

    /**
     * Hack just for single-value trackers:
     * Remove any records other than the record that was just saved
     */
    const removeOrphanedServerRecords = canonicalLog => {
      if (self.clientMeta.multipleValuesDisabled) {
        const logArray = self.logs.slice();
        remove(logArray, l => l.id !== canonicalLog.id && l.date === canonicalLog.date);
        self.user.logs = logArray;
      }
    };

    /**
     * Remove non-new logs from the previous date range and swap in updated copies.
     * This should handle any add/ deletes/ updates that have happened since last load
     * without disturbing any other data.
     * Also does not remove any logs that were saved recently, as the server data may be cached and may not include them.
     */
    const swapLogPage = ({ startDate, endDate, logs, updatedTracker }) => {
      // update tracker fields if available
      if (updatedTracker) {
        self.palettesId = updatedTracker.palettesId;
        self.name = updatedTracker.name;
        self.meta = updatedTracker.meta;
        self.clientMeta = updatedTracker.clientMeta;
        self.isGraphable = updatedTracker.isGraphable;
      }
      let existingLogs = self.logs.slice();
      remove(
        existingLogs,
        // removes logs in the same date range as the query so, any deletes from the server will be reflected
        // in the union
        l =>
          l.date >= startDate &&
          l.date <= endDate &&
          !l.isNew &&
          l.id.toString() === l.serverId.toString()
      );
      // this should prevent duplicating of head/ tail logs
      // also favors logs from the server that were recently saved on the device, meaning that the server copies are swapped in
      self.user.logs = unionBy(
        logs,
        existingLogs,
        // protect against a theoretical possible multiple new pending log records
        l => (l.serverId ? l.serverId.toString() : l.id.toString())
      );
    };

    const setName = function(name) {
      self.name = name;
    };

    const setPalettesId = function(palettesId) {
      self.palettesId = palettesId;
    };

    const setType = function(type) {
      self.clientMeta = self.clientMeta ? { ...self.clientMeta, type } : { type };
    };

    const setSubType = subType => {
      self.clientMeta = self.clientMeta ? { ...self.clientMeta, subType } : { subType };
    };

    const setQuestionOptions = questionOptions => {
      self.clientMeta = self.clientMeta
        ? { ...self.clientMeta, questionOptions }
        : { questionOptions };
    };

    const setServerId = id => {
      self.serverId = id;
    };

    const setMeta = meta => {
      self.meta = meta;
    };

    const setClientMeta = meta => {
      self.clientMeta = meta;
    };

    const setUnits = units => {
      setMeta({ ...self.meta, config: { ...self.meta.config, units } });
      setClientMeta({ ...self.clientMeta, uom: units });
    };

    const setIsGraphable = isGraphable => {
      self.isGraphable = isGraphable;
    };

    return {
      addLog,
      removeLog,
      removeOrphanedServerRecords,
      swapLogPage,
      setName,
      setPalettesId,
      setType,
      setSubType,
      setQuestionOptions,
      setServerId,
      setMeta,
      setUnits,
      setIsGraphable,
    };
  });

export { Log }; // for unit tests only

export default Tracker;
