import { find, padStart, sum } from 'lodash';
import { getParent, isAlive, isValidReference, types } from 'mobx-state-tree';
import { DateTime } from 'luxon';
//import { dateRangeForInterval } from '../../lib/dates'; will need this later for week interval stuff
import FeedCard from './FeedCard';

// A custom reference for Program Cards that will always resolve to the "nearest" card.
// what happens with the default reference is if we have multiple ReadOnlyLogStores (for, say, multiple clients),
// it'll detect an ambiguious reference if they both have the same card.
// An alternate solution to this would be to use a different identifier for cards on a log store,
// since those are really "UserCards" and not just trackers in general
export const ProgramCardForLogStoreReference = types.maybeNull(
  types.reference(FeedCard, {
    // given an identifier, find the user
    get(identifier /* string */, parent /*Store*/) {
      const logStore = getParent(parent, 3); // I hate this version of getParent because it depends on depth
      // however, we're pretty tightly-bound to this structure, so it's unlikely the parent with the relevant trackers would move
      if (logStore) {
        const card = find(logStore.programCards, t => t.id == identifier);
        return card || null;
      }
    },
    // given a card, produce the identifier that should be stored
    set(value /* Card */) {
      return value.id;
    },
  })
);

const StackShare = types.model({
  entityType: types.maybeNull(types.string),
  entityId: types.maybeNull(types.number),
  companyId: types.maybeNull(types.number),
  autoAssign: types.maybeNull(types.boolean),
  messageBody: types.maybeNull(types.string),
  notificationBody: types.maybeNull(types.string),
  reminderAt: types.maybeNull(types.string),
  repeatingType: types.maybeNull(types.enumeration(['DAILY', 'WEEKLY'])),
  startAt: types.maybeNull(types.string),
  endAt: types.maybeNull(types.string),
  stackId: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});

const ProgramShare = types.model({
  id: types.maybe(types.identifierNumber),
  cardId: ProgramCardForLogStoreReference,
  startAt: types.maybeNull(types.string),
  endAt: types.maybeNull(types.string),
  entityId: types.maybeNull(types.number),
  entityType: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  stackShareId: types.maybeNull(types.number),
  stackShare: types.maybeNull(StackShare, {}),
  repeatingType: types.maybeNull(types.enumeration(['DAILY', 'WEEKLY'])),
  rank: types.maybeNull(types.number),
  userProgramShareId: types.maybeNull(types.number),
});

const ProgramEvent = types
  .model('ProgramEvent', {
    id: types.identifier,
    // range of dates this item is focused on.
    // usually a single day, but may be an entire week for a weekly goal
    date: types.string,
    share: types.optional(ProgramShare, {}),
    wasOpened: types.optional(types.boolean, false),
    showCardOpenedStatus: types.optional(types.boolean, false),
    firstOpened: types.optional(types.string, ''),
    lastOpened: types.optional(types.string, ''),
    isUpcoming: types.optional(types.boolean, false),
  })
  .views(self => ({
    get daysSinceLastOpened() {
      return self.wasOpened
        ? DateTime.local().diff(DateTime.fromSQL(self.lastOpened), 'days').days
        : 0;
    },
    // convenience props - object flattening
    get programCard() {
      if (!isAlive(self)) {
        // not sure how this happens, but technically an unloaded event can hang around and try to ref the card,
        // and then you get a warning.
        // This happened on Coach Mobile when refreshing
        // I think it's a race condition because all we got was the warning, not any actual bugs
        return null;
      }
      if (isValidReference(() => self.share.cardId)) {
        return self.share.cardId;
      }
      return null;
    },
    // shows a card has been read if a repeating card has ever been opened or if a non-repeating card has been opened for that day.
    get isRead() {
      if (!isAlive(self)) return false;
      return (self.share.repeatingType && self.programCard.cardOpened) || self.wasOpened;
    },
    // a key that indicates how an event should be grouped in the feed
    // repeating cards are collapsed, while distinct caprds that may be identical are not
    get clientFeedUniquenessKey() {
      if (!self.programCard) {
        return self.id;
      }
      if (self.share.repeatingType) {
        return self.programCard.stackId;
      }
      return self.id;
    },
    // only good for sorting within header groups
    get sortKey() {
      return `${self.headerSortKey}|${self.itemSortKey}`;
    },
    get itemSortKey() {
      //Within a day (header), sort by share type => program id => rank => date created
      //The intended order for that "share type" segment of the sort key (once .reverse() is applied) is:
      // * non-repeating individual program card(s)
      // * card(s) from a sequence (grouped by sequence)
      // * repeating individual program card(s)
      //We're generally reversing the outcome so we want the inverse of the rank here
      //TODO: come up with a better generalized sortkey that packs in the reversed stuff
      return (
        (self.share.userProgramShareId === null
          ? self.share.repeatingType === null
            ? '999999999'
            : '000000000'
          : padStart(self.share.userProgramShareId, 9, '0')) +
        '|' +
        (self.share.rank === null ? '000000000' : padStart(9999 - self.share.rank, 9, '0')) +
        '|' +
        self.share.updatedAt +
        '|' +
        padStart(self.share.id, 9, '0')
      );
    },
    // can be used to sort each header group
    get headerSortKey() {
      return self.date;
    },
    get headerInfo() {
      return {
        type: 'day',
        date: self.date,
      };
    },
    get hasManuallyEnteredLogs() {
      // return false if no trackers on the card have manually entered logs
      let hasManualEntries = false;
      const trackerCompontents = self.programCard.trackerComponents;
      for (let i = 0; i < trackerCompontents.length; i++) {
        if (trackerCompontents[i].tracker) {
          hasManualEntries = !!find(
            trackerCompontents[i].tracker.logs,
            l => l.date === self.date && l.isManualEntry
          );
          // return true on first tracker on card that has manually entered logs
          if (hasManualEntries) {
            break;
          }
        }
      }
      return hasManualEntries;
    },
    /**
     * Stats based on the context provided by the program card.
     * Program cards can show totals against a goal, totals without a goal,
     * or latest value.
     * Return value is an object that includes a total on trackers that can be totaled or
     * a latest value for trackers that can't.
     * The goal is included for any trackers that have a goal.
     * Totals and latest values are relative to the provided date and the interval on the card/ tracker.
     * So, a latest value is relative to the day itself, or the week in which the day exists.
     */
    statsFor({ componentId }) {
      const date = self.date;
      let statEntry = {
        total: null,
        goal: null,
        interval: null,
        latestLog: null,
        aggregateType: null /* sum, count, or latestForInterval */,
      };
      let tracker;
      const programCard = self.programCard;
      if (!programCard) {
        return null;
      }
      const programCardComponent = programCard.components.find(pcc => pcc.id === componentId);
      if (!programCardComponent) {
        return null;
      }
      if (!programCardComponent.tracker) {
        return null;
      }
      tracker = programCardComponent.tracker;
      // interval defined by card, not goal (I think)
      statEntry.interval =
        programCardComponent.goalInterval === 'WEEKLY'
          ? 'week'
          : programCardComponent.goalInterval === 'MONTHLY'
            ? 'day' /* don't support daily, but technically possible */
            : 'day';
      if (programCardComponent.hasGoal) {
        statEntry.goal = programCardComponent.goalThreshold || 1; // implicit goal of 1 time entry if it's on a card
        statEntry.aggregateType = programCardComponent.goalType === 'TOTAL' ? 'sum' : 'count';
      } else if (programCardComponent.tracker.canBeTotaled /* no goal, but can be totaled */) {
        statEntry.aggregateType = 'sum'; // total for current day
      } else {
        // can't be totaled, not a goal - need to show latest within some sort of window
        statEntry.aggregateType = 'latestForInterval';
      }

      // next: compute stats
      const trackerStats = tracker.statsFor({
        interval: statEntry.interval,
        date,
        aggregateType: statEntry.aggregateType,
      });
      return { ...statEntry, ...trackerStats };
    },
    get goalCompletionStats() {
      const programCard = self.programCard;
      //If no program card data has been loaded (e.g. we're viewing this from the card library) return 0's
      if (!programCard) return { precentComplete: 0, numComplete: 0, total: 0 };
      const trackerComponentsWithGoals = programCard.trackerComponents;
      const completionPercents = trackerComponentsWithGoals.map(tcwg => {
        const stats = self.statsFor({ componentId: tcwg.id });
        if (!stats.goal) {
          // implicit completion goal of 0 or 100% for a single entry when there's no actual goal
          if (stats.latestLog || stats.total || stats.hasValidEntries) {
            return 1;
          } else {
            return 0;
          }
        }
        // shouldn't happen, but...
        if (stats.goal <= 0) {
          return 0;
        }
        return stats.total / stats.goal > 1 ? 1 : stats.total / stats.goal;
      });
      return {
        percentComplete: completionPercents.length
          ? sum(completionPercents) / completionPercents.length
          : 0,
        numComplete: completionPercents.filter(cp => cp >= 1).length,
        total: completionPercents.length,
      };
    },
    get cardOpenedStatus() {
      const status = {
        show: false,
        displayMode: 'unopened', // unopened,notOpenedInLast30Days,openedRecently
        isRepeating: false,
        timeOpened: null,
        wasLastOpenedOnEventDate: false,
      };
      // 1) can we even show opened status on this card, or is it too old?
      status.show = self.showCardOpenedStatus;

      // 2) is it repeating? This changes a lot of visuals
      status.isRepeating = !!self.share.repeatingType;

      // 3) unopened, recently opened, or not recently opened?
      status.displayMode = self.programCard.cardOpened
        ? 'unopened'
        : self.programCard.daysSinceLastOpened > 30
          ? 'notOpenedInLast30Days'
          : 'openedRecently';

      // 4) server time opened
      status.timeOpened = self.programCard.userLastOpenedAtMax;

      // 5) compare to event date
      status.wasLastOpenedOnEventDate = DateTime.fromSQL(self.date).isSame(
        DateTime.fromSQL(self.programCard.userLastOpenedAtMax)
      );

      return status;
    },
  }));

export default ProgramEvent;
