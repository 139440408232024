import { padStart } from 'lodash';
import { DateTime } from 'luxon';
import { types } from 'mobx-state-tree';
import FeedCardComponent from './FeedCardComponent';
import CardBase from './internal/CardBase';

const FeedPart = types
  .model('FeedPart', {
    components: types.optional(types.array(FeedCardComponent), []),
    tagRanks: types.optional(types.array(types.number), []),
    userLastOpenedAtMax: types.optional(types.maybeNull(types.string), null),
  })
  .views(self => ({
    get tagRank() {
      if (self.tagRanks.length) {
        return `${padStart(self.tagRanks[0].toString(), 10, '0')}:${padStart(self.id, 10, '0')}`;
      }
      return `${padStart(Number.MAX_SAFE_INTEGER.toString(), 10, '0')}:${padStart(
        self.id,
        10,
        '0'
      )}`;
    },
    get daysSinceLastOpened() {
      return self.cardOpened && self.userLastOpenedAtMax
        ? DateTime.local().diff(DateTime.fromSQL(self.userLastOpenedAtMax), 'days').days
        : 0;
    },
  }));

const FeedCard = types.compose(
  'FeedCard',
  CardBase,
  FeedPart
);

export default FeedCard;
