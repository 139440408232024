import { sortBy } from 'lodash';

function colorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#';
  for (let i = 0; i < 3; i++) {
    let c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
}

export default function getMultipleChoiceAnswerColorMapper({
  baseColor,
  noAnswerColor,
  possibleAnswers,
}) {
  const segmentCount = possibleAnswers.length;
  let colorMap = [];

  possibleAnswers.forEach((possibleAnswer, index) => {
    colorMap.push({
      value: possibleAnswer,
      color: colorLuminance(baseColor, 0.5 - index / (segmentCount - 1)),
    });
  });
  // the fallback exists when we want to use a different color for a circle border
  // this function doesn't differentiate for no answer or the answer not being among the possible answers
  return (value, altNoAnswerFallback) => {
    const mapItem = colorMap.find(m => m.value === value);
    if (mapItem) {
      return mapItem.color;
    }

    if (value === null || value === undefined) {
      return altNoAnswerFallback || noAnswerColor;
    }

    // TODO: color for answer that is no longer a possible answer
    return altNoAnswerFallback || noAnswerColor;
  };
}
