// Properties of a line
// I:  - pointA (array) {x,y}: coordinates
//     - pointB (array) {x,y}: coordinates
// O:  - (object) { length: l, angle: a }: properties of the line
const line = (pointA, pointB) => {
  const lengthX = pointB.x - pointA.x;
  const lengthY = pointB.y - pointA.y;
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
};

// Position of a control point
// I:  - current (object) {x, y}: current point coordinates
//     - previous (object) {x, y}: previous point coordinates
//     - next (object) {x, y}: next point coordinates
//     - reverse (boolean, optional): sets the direction
// O:  - (object) {x,y}: a tuple of coordinates
const controlPoint = (current, previous, next, reverse, smoothing = 0.2) => {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current;
  const n = next || current;
  // Properties of the opposed-line
  const o = line(p, n);
  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothing;
  // The control point position is relative to the current point
  const x = current.x + Math.cos(angle) * length;
  const y = current.y + Math.sin(angle) * length;
  return { x, y };
};

// Create the bezier curve command
// I:  - point (array) {x,y}: current point coordinates
//     - i (integer): index of 'point' in the array 'a'
//     - a (array): complete array of points coordinates
// O:  - (string) 'C x2,y2 x1,y1 x,y': SVG cubic bezier C command
const bezierCommand = (point, i, a, smoothing) => {
  // start control point
  const cps = controlPoint(a[i - 1], a[i - 2], point, false, smoothing);
  // end control point
  const cpe = controlPoint(point, a[i - 1], a[i + 1], true, smoothing);
  return `C ${cps.x},${cps.y} ${cpe.x},${cpe.y} ${point.x},${point.y}`;
};

// Render the svg <path> element
// I:  - points (array): points coordinates
//     - command (function)
//       I:  - point (object) {x,y}: current point coordinates
//           - i (integer): index of 'point' in the array 'a'
//           - a (array): complete array of points coordinates
//       O:  - (string) a svg path command
// O:  - (string): a Svg <path> element
const svgPath = (points, command, smoothing) => {
  // build the d attributes by looping over the points
  const d = points.reduce(
    (acc, point, i, a) =>
      i === 0
        ? // if first point
          `M ${point.x},${point.y}`
        : // else
          `${acc} ${command(point, i, a, smoothing)}`,
    ''
  );
  return `${d}`;
};

const curvedSvgPath = (points, smoothing) => svgPath(points, bezierCommand, smoothing);

export default curvedSvgPath;
