import { DateTime as LuxonDateTime } from 'luxon';
import { types } from 'mobx-state-tree';
import { v4 as uuid } from 'uuid';
import Distributor from './Distributor';

const dateString = d => `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear() % 1000}`;
const lenFunc = part => {
  let strPart = new String(part);
  return strPart.length < 2 ? `0${strPart}` : strPart;
};

export const DateTime = types.custom({
  name: 'DateTime',
  fromSnapshot: string => {
    if (string === undefined || string === null) {
      return null;
    }

    return LuxonDateTime.fromSQL(string).toJSDate();
  },
  toSnapshot: date => {
    if (date === undefined || date === null) {
      return null;
    }

    let year = new String(date.getFullYear());
    let month = lenFunc(date.getMonth() + 1);
    let day = lenFunc(date.getDate());
    let hours = lenFunc(date.getHours());
    let minutes = lenFunc(date.getMinutes());
    let time = `${year}-${month}-${day} ${hours}:${minutes}:00`;

    return time;
  },
  isTargetType: maybeDate => maybeDate instanceof Date,
  getValidationMessage: snapshot => {
    if (snapshot === undefined) {
      return 'snapshot is undefined';
    }
    const mDate = new Date(snapshot);
    if (!(mDate instanceof Date)) {
      const message = `"${snapshot}" is not valid date object"`;
      console.error(message);
      return message;
    }
    return '';
  },
});

const Weekday = types.model({
  day: types.maybeNull(types.enumeration(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])),
  selected: types.optional(types.boolean, false),
  interval: types.optional(types.number, 1),
  dayOfWeek: types.maybeNull(types.number),
  recurringType: types.maybeNull(
    types.enumeration(['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY'], 'WEEKLY')
  ),
});

const ProgramCardAssignment = types
  .model({
    id: types.optional(types.identifier, uuid()),
    serverId: types.maybeNull(types.number),
    companyId: types.maybeNull(types.number),
    entityId: types.maybeNull(types.union(types.number, types.string)),
    entityType: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    photoUrl: types.maybeNull(types.string),
    startAt: types.optional(DateTime, () => new Date()),
    endAt: types.maybeNull(DateTime, null),
    autoAssign: types.optional(types.boolean, false),
    repeatingType: types.maybeNull(types.enumeration(['DAILY', 'WEEKLY'])),
    repeatReminder: types.optional(types.maybeNull(types.boolean), false),
    reminderAt: types.optional(DateTime, () => new Date().setSeconds(0)),
    selectedWeekdays: types.maybeNull(types.array(Weekday), []),
    message: types.maybeNull(types.string),
    notification: types.maybeNull(types.string),
    distributor: types.maybeNull(Distributor),
  })
  .actions(self => ({
    setAutoAssign: a => {
      self.autoAssign = a;
    },
    setStartAt: s => {
      if (s instanceof Date) {
        s.setHours(0, 0, 0, 0);
        self.startAt = s;
      } else if (typeof s === 'string') {
        self.startAt = LuxonDateTime.fromSQL(s).toJSDate();
      } else {
        self.startAt = null;
      }
    },
    setEndAt: e => {
      if (e instanceof Date) {
        e.setHours(0, 0, 0, 0);
        self.endAt = e;
      } else if (typeof e === 'string') {
        self.endAt = LuxonDateTime.fromSQL(e).toJSDate();
      } else {
        self.endAt = null;
      }
    },
    setRepeatingType: r => (self.repeatingType = r),
    setNotification: n => (self.notification = n),
    setMessage: m => (self.message = m),
    setReminderAt: r => (self.reminderAt = r),
    setRepeatReminder: r => (self.repeatReminder = r),
    setSelectedWeekdays: w => (self.selectedWeekdays = w),
    setEntity: e => {
      self.entityId = e.id;
      self.entityType = e.type;
      self.firstName = e.firstName;
      self.lastName = e.lastName;
      self.title = e.title;
      self.photoUrl = e.photoUrl;

      // This is a hack but the entities auto assign overwrites the assignment's
      // otherwise
      if (e.autoAssign !== undefined) {
        self.autoAssign = e.autoAssign;
      }
    },
  }))
  .views(self => ({
    get isNew() {
      return self.serverId === null;
    },
    get isAutoAssigned() {
      return self.autoAssign === true;
    },
    get isOnce() {
      return self.repeatingType === null;
    },
    get isWeekly() {
      return self.repeatingType === 'WEEKLY';
    },
    get isDaily() {
      return self.repeatingType === 'DAILY';
    },
    get hasNotBegun() {
      const now = new Date().setHours(0, 0, 0);
      const isRepeating = self.repeatingType !== null;
      const hasNoEndDate = self.endAt === null;
      const hasEndDateAndIsCurrent = self.endAt !== null && self.endAt >= now;
      const hasStartDateAndIsInTheFuture = self.startAt !== null && self.startAt > now;

      return (
        isRepeating && (hasNoEndDate || hasEndDateAndIsCurrent) && hasStartDateAndIsInTheFuture
      );
    },
    get hasBegun() {
      const now = new Date().setHours(0, 0, 0);
      const isRepeating = self.repeatingType !== null;
      const hasNoEndDate = self.endAt === null;
      const hasEndDateAndIsCurrent = self.endAt !== null && self.endAt >= now;
      const hasStartDateAndIsCurrent = self.startAt !== null && self.startAt <= now;

      return isRepeating && (hasNoEndDate || hasEndDateAndIsCurrent) && hasStartDateAndIsCurrent;
    },
    get notificationString() {
      return self.notification === null ? '' : self.notification;
    },
    get notificationPlaceholder() {
      return `Type a message to send to ${self && self.entityId ? self.firstName : 'your users'}`;
    },
    get startAtDateString() {
      return dateString(self.startAt);
    },
    get startAtMonthDayString() {
      return LuxonDateTime.fromJSDate(self.startAt).toFormat('MMM d');
    },
    get endAtDateString() {
      return dateString(self.endAt);
    },
    get endAtMonthDayString() {
      return LuxonDateTime.fromJSDate(self.endAt).toFormat('MMM d');
    },
    get selectedWeekdaysString() {
      if (self.selectedWeekdays === null) {
        return '';
      }

      const sortedDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      const displayedDays =
        self.selectedWeekdays.length > 2
          ? ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su']
          : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      const sortedWeekdays = self.selectedWeekdays
        .filter(x => x.selected)
        .map(x => x.day)
        .sort((a, b) => sortedDays.indexOf(a) - sortedDays.indexOf(b))
        .map(x => displayedDays[sortedDays.indexOf(x)]);
      const lastWeekday = sortedWeekdays[sortedWeekdays.length - 1];
      const weekdaysString = sortedWeekdays.join(', ');

      return lastWeekday === undefined
        ? weekdaysString
        : weekdaysString.replace(`, ${lastWeekday}`, ` & ${lastWeekday}`);
    },
    get assignedString() {
      const autoAssignString = self.autoAssign ? ', Auto Assign' : '';
      if (self.startAt.getTime() > new Date() && self.isWeekly) {
        return `Every ${self.selectedWeekdaysString} - Starts on ${
          self.startAtMonthDayString
        }${autoAssignString}`;
      } else if (self.endAt && self.endAt.getTime() !== self.startAt.getTime() && self.isWeekly) {
        return `Every ${self.selectedWeekdaysString} - ${self.startAtMonthDayString} - ${
          self.endAtMonthDayString
        }${autoAssignString}`;
      } else if (!self.endAt && self.isWeekly && self.selectedWeekdays !== null) {
        return `Every ${self.selectedWeekdaysString}${autoAssignString}`;
      } else if (self.endAt && self.endAt.getTime() !== self.startAt.getTime() && self.isDaily) {
        return `Daily - ${self.startAtMonthDayString} - ${
          self.endAtMonthDayString
        }${autoAssignString}`;
      } else if (!self.endAt && self.isDaily) {
        return `Daily${autoAssignString}`;
      }
      return `Assigned on ${self.startAtMonthDayString}${autoAssignString}`;
    },
    get shortAssignedString() {
      const autoAssignString = self.autoAssign ? ', Auto Assign' : '';
      if (self.startAt.getTime() > new Date() && self.isWeekly) {
        return `Every ${self.selectedWeekdaysString}${autoAssignString}`;
      } else if (self.endAt && self.endAt.getTime() !== self.startAt.getTime() && self.isWeekly) {
        return `Every ${self.selectedWeekdaysString}${autoAssignString}`;
      } else if (!self.endAt && self.isWeekly && self.selectedWeekdays !== null) {
        return `Every ${self.selectedWeekdaysString}${autoAssignString}`;
      } else if (self.endAt && self.endAt.getTime() !== self.startAt.getTime() && self.isDaily) {
        return `Daily${autoAssignString}`;
      } else if (!self.endAt && self.isDaily) {
        return `Daily${autoAssignString}`;
      }
      return `Assigned on ${self.startAtMonthDayString}${autoAssignString}`;
    },
    get assignee() {
      if (self.entityType === 'user') {
        return self.firstName + ' ' + self.lastName;
      } else if (self.entityType === 'coach') {
        return 'All Clients';
      } else if (self.entityType === 'company') {
        return 'All ' + self.title + ' Clients';
      } else {
        return self.title;
      }
    },
    // For WEEKLY repeating cards, determine whether a particular date falls one one of the assigned weekdays
    // For DAILY, isAssigned is always true; for null repeating type return false (check card share for when non-repeating cards are actually assigned)
    isAssignedOnDate(date) {
      return self.repeatingType === 'WEEKLY'
        ? self.selectedWeekdays
            .map(d => d.dayOfWeek)
            .includes(LuxonDateTime.fromJSDate(date).weekday)
        : self.repeatingType === 'DAILY';
    },
  }));

export default ProgramCardAssignment;
