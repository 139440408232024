import { maxBy, keys, reduce, uniqBy } from 'lodash';
import {
  logGroupingDate,
  isSynced,
  isTimedActivity,
  isUntimedActivity,
  getEffectiveSource,
  sourceToDisplaySource,
  latestLogReducer,
} from './trackerUtils';

/**
 * Returns a wide variety of information about a group of logs, including:
 * Total for each date
 * Latest for each date
 * Crossed out logs for each date (and a separate list of crossed out logs for all dates)
 *
 * Accepts camelCased or non-camel-cased log DTO's from the API
 *
 * @param {Object} param (destructured params)
 * @param {array} param.logs - log DTO's from API
 * @param {string} param.aggregateType - total or latest
 * @param {string} param.primaryField - field name from which to do totals
 * @param {bool} param.requiresDeduplication - If true, records require deduplication
 * @param {string} param.deduplicationRule - which deduplication algorithm to use -
 * @return {Object} the tracker metadata. Includes everything from getTrackerDisplayProperties
 */
const getLogCatalog = ({
  logs,
  aggregateType,
  primaryField,
  requiresDeduplication = false,
  deduplicationRule,
  shiftDayWindow = false,
}) => {
  const logDateMap = {};
  const crossedOutLogsMap = {};
  const redundantLogsMap = {};
  const childLogsMap = {};
  const basicMetadataMap = {};
  // first, map the logs
  logs.forEach(log => {
    const dateForLog = logGroupingDate({ log, shiftDayWindow });
    // first log - add new blank entry
    if (logDateMap[dateForLog] === undefined) {
      logDateMap[dateForLog] = {
        date: dateForLog,
        logs: [],
        latest: null,
        total: 0,
        // untimed activity only
        syncedPedometerTotal: 0,
        nudgeTotal: 0,
        syncedActiveTotal: 0,
        syncedLogs: [],
        crossedOutLogs: [],
        childLogs: [],
        redundantLogs: [],
        hiddenLogs: [],
        hasAnyData: false,
      };
    }
    // append data
    const logDataForDate = logDateMap[dateForLog];
    logDataForDate.logs.push(log);
    if (aggregateType === 'latest') {
      // replace the latest record
      logDataForDate.latest = latestLogReducer(logDataForDate.latest, log);
    } else if (aggregateType === 'total') {
      logDataForDate.total += log[primaryField];
      if (isSynced(log)) {
        if (isUntimedActivity(log)) {
          // we can only use one untimed log - multiples from different sources would basically always be duplicates
          // this is critical for crossing out below. We want to compare the single largest source of untimed records against timed records
          // to see if we should favor timed or untimed record
          logDataForDate.syncedPedometerTotal = Math.max(
            log[primaryField],
            logDataForDate.syncedPedometerTotal
          );
        }
        if (isTimedActivity(log)) {
          logDataForDate.syncedActiveTotal += log[primaryField];
        }
      } else {
        logDataForDate.nudgeTotal = log[primaryField];
      }
    }

    // separate synced logs
    if (isSynced(log)) {
      logDataForDate.syncedLogs.push(log);
    }

    basicMetadataMap[log.id] = {
      isTimedActivity: isTimedActivity(log),
      isUntimedActivity: isUntimedActivity(log),
      isSynced: isSynced(log),
      displaySource: sourceToDisplaySource(log.source),
    };
  });

  // deduplicate
  if (requiresDeduplication) {
    keys(logDateMap).forEach(date => {
      const logDataForDate = logDateMap[date];
      if (deduplicationRule === 'activitiesAndSteps') {
        // if `manualTotal > automaticTotal`, cross out all (non-nudge) automatic records
        if (logDataForDate.syncedActiveTotal > logDataForDate.syncedPedometerTotal) {
          logDataForDate.crossedOutLogs = logDataForDate.syncedLogs.filter(l =>
            isUntimedActivity(l)
          );
        } else {
          // if `manualTotal < max(automatic)`, cross out all (non-nudge) manual and automatic
          // records except for the *first* automatic record that matches the max(automatic).
          const maxAutomaticLog = maxBy(
            logDataForDate.syncedLogs.filter(l => isUntimedActivity(l)),
            l => l[primaryField]
          );
          if (
            maxAutomaticLog &&
            maxAutomaticLog[primaryField] >= logDataForDate.syncedActiveTotal
          ) {
            logDataForDate.crossedOutLogs = logDataForDate.syncedLogs.filter(
              l => l.id !== maxAutomaticLog.id
            );
          }
        }
      } else if (deduplicationRule === 'heartRate') {
        // heart rate = cross out timed logs with a corresponding untimed log
        const untimedSourced = logDataForDate.syncedLogs.filter(l => isUntimedActivity(l));
        const sourcesWithDailyAggregates = uniqBy(untimedSourced, l => getEffectiveSource(l));
        sourcesWithDailyAggregates.forEach(sourceLog => {
          logDataForDate.crossedOutLogs = logDataForDate.crossedOutLogs.concat(
            logDataForDate.syncedLogs.filter(
              l => isTimedActivity(l) && getEffectiveSource(l) === getEffectiveSource(sourceLog)
            )
          );
        });
      } else {
        // sleep

        // If there's any data with source `nudge`, cross out all non-nudge sources. Nudge sleep records override any synced data
        if (logDataForDate.nudgeTotal) {
          logDataForDate.crossedOutLogs = logDataForDate.syncedLogs;
        } else {
          //If there are no `nudge` records, find the maximum non-nudge sleep record. Cross out all non-maximum records
          const maxSyncedLog = maxBy(logDataForDate.syncedLogs, l => l[primaryField]);
          if (maxSyncedLog) {
            logDataForDate.crossedOutLogs = logDataForDate.logs.filter(
              l => l.id !== maxSyncedLog.id
            );
          }
        }
      }

      // identify child/ redundant heart records (only part of a day, not daily aggregate)
      if (deduplicationRule === 'heartRate') {
        logDataForDate.childLogs = logDataForDate.crossedOutLogs;
        // we don't want to show these timed heart rate records generally
        // they're just for part of the day, so not good for a daily aggregate
        logDataForDate.redundantLogs = logDataForDate.crossedOutLogs;
      } else {
        // These are timed cardio that are less than an untimed cardio total
        logDataForDate.crossedOutLogs.forEach(crossedOutLog => {
          if (!isUntimedActivity(crossedOutLog)) {
            const hasLargerUntimedActivity = !!logDataForDate.syncedLogs.find(
              syncedLog =>
                getEffectiveSource(syncedLog) === getEffectiveSource(crossedOutLog) &&
                isUntimedActivity(syncedLog) &&
                syncedLog[primaryField] >= crossedOutLog[primaryField]
            );
            if (hasLargerUntimedActivity) {
              logDataForDate.childLogs.push(crossedOutLog);
            }
          }
        });
      }

      if (aggregateType === 'total') {
        // identify redundant records - these are crossed out untimed cardio logs that are less than a timed record from the same source
        // Usually a temporary thing due to missing synced data
        // do not need to be removed from the total because they're already removed during the crossing out
        logDataForDate.crossedOutLogs.forEach(crossedOutLog => {
          if (isUntimedActivity(crossedOutLog)) {
            const sumOfTimedActivities = reduce(
              logs.filter(
                l =>
                  getEffectiveSource(l) === getEffectiveSource(crossedOutLog) &&
                  !isUntimedActivity(l)
              ),
              (sum, n) => n[primaryField] + sum,
              0
            );
            if (crossedOutLog[primaryField] < sumOfTimedActivities) {
              logDataForDate.redundantLogs.push(crossedOutLog);
            }
          }
        });
      }

      // check for logs that should be excluded because they're covered in different tracker types
      // Right now, this happens for cardio and pedometer - they show up in both but we only want to show
      // the relevant data once.
      if (aggregateType === 'total') {
        const zeroDataRedundantLogs = logDataForDate.logs.filter(l => l[primaryField] === 0);
        logDataForDate.redundantLogs = logDataForDate.redundantLogs.concat(zeroDataRedundantLogs);
        // redundant logs should always be crossed out - crossed out status is what we use for excluding logs from totals/ other aggregates
        // redundant is just for hiding logs entirely
        logDataForDate.crossedOutLogs = logDataForDate.crossedOutLogs.concat(zeroDataRedundantLogs);
      }

      // map all crossed out logs for easy access later and recalculate totals
      logDataForDate.crossedOutLogs.forEach(log => {
        crossedOutLogsMap[log.id] = true;
        if (aggregateType === 'total') {
          logDataForDate.total -= log[primaryField];
        }
      });

      // Map for quick retrieval
      logDataForDate.redundantLogs.forEach(log => {
        redundantLogsMap[log.id] = true;
      });
      logDataForDate.childLogs.forEach(log => {
        childLogsMap[log.id] = true;
      });
    });
  }

  return {
    dates: logDateMap,
    crossedOutLogs: crossedOutLogsMap,
    redundantLogs: redundantLogsMap,
    childLogs: childLogsMap,
    basicMetadata: basicMetadataMap,
  };
};

export { getLogCatalog };
