import numeral from 'numeral';

// This is really just used for formatting on the entry screen. Should probably renamed, possibly moved
// OH WAIT, also used under graphs and I guess anywhere else there's tracker values

function formatLogValueForDisplay({
  value,
  fieldName,
  editor = null, // OPTIONAL: specify an editor to provide more specific advise about how to handle value.
  /* These editors are based on the field editor in getTrackerDisplayProperties(). 
  If none is provided, will assume based on value type. We did this as an alternative to refactoring, like, everything.
  Eventually, we should have some way to for it to understand how to format the value without:
  a) knowing the exact field name,
  b) by applying properties from getTrackerDisplayProperties()...
  c) regardless of whether its a primary value or an editor field */
  useMetricUnits = false,
  decimalPlaces = 2,
}) {
  const myEditor =
    editor ||
    (!isNaN(value) ? 'number' : 'unknown') /* just bypass formatting and return the raw value */;
  if (['fatRatio', 'grossProfitMargin'].includes(fieldName)) {
    return numeral(value * 100).format('0[.]0[0]');
  }
  // NOTE: duration isn't in here because the only trackers with duration use a start and end time picker
  // If we changed that, it would break!
  if (fieldName === 'hiDuration') {
    return numeral(value / 60).format('0[.]0[0]');
  }
  if (fieldName === 'weight') {
    return numeral(value * (useMetricUnits ? 0.453592 : 1)).format('0[.]0');
  }
  if (fieldName === 'distance') {
    return numeral(value * (useMetricUnits ? 1.60934 : 1)).format('0[.]0');
  }
  // exerbotics fields
  if (fieldName === 'strIdx' || fieldName === 'baseline') {
    if (useMetricUnits) {
      return numeral(value * 0.453592).format('0[.]0');
    } else {
      return numeral(value).format('0[.]0');
    }
  }

  // finance fields
  if (['revenue', 'grossProfit', 'netIncome', 'cogs', 'netCashIncrease'].includes(fieldName)) {
    return Math.abs(value) >= 10000
      ? `${numeral(value / 1000).format('$0,0')}k`
      : Math.abs(value) >= 100
        ? numeral(value).format('$0,0')
        : numeral(value).format('$0,0.00');
  }

  // don't process any time fields
  if (['startTime', 'endTime', 'jsUserTime'].includes(fieldName)) {
    return value;
  }
  if (
    myEditor === 'number' &&
    value !== null &&
    value !== undefined /* don't format null/ empty values */
  ) {
    return numeral(value).format(
      '0,0' +
        (decimalPlaces /* BUG: doesn't really hold the value to a particular number of decimal places other than 0 or more than 0 */
          ? '[.]0[0]'
          : '')
    );
  }
  return value;
}

export default formatLogValueForDisplay;
