import { types } from 'mobx-state-tree';

const Distributor = types
  .model({
    id: types.identifierNumber,
    firstName: types.string,
    lastName: types.string,
  })
  .views(self => ({
    get name() {
      return self.firstName + ' ' + self.lastName;
    },
  }));

export default Distributor;
