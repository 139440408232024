/**
 * Used by stores to remove records from the range we're about to refresh.
 * That way, we can say "refresh everything in January", and then union the refreshed data with everything, minus what's in January.
 * This will cause any records deleted since last refresh to be removed, without removing any other records where we don't know
 * if they were deleted, because we're not refreshing that range.
 *
 * @param data - the array of records to perform the filter over;
 * @param startDate - the start of range for which we need to remove records
 * @param endDate - the end of range for which we need to remove records
 * @param getDataFromRecord - function accepting a record and returning the date prop we'll use for comparison
 *
 * @returns all records outside of the date range
 */
function getDatesOutsideOfRange({ data, startDate, endDate, getDateFromRecord }) {
  return data.filter(
    record => getDateFromRecord(record) < startDate || getDateFromRecord(record) > endDate
  );
}

export { getDatesOutsideOfRange };
