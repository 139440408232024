import { find } from 'lodash';
import { getRoot, types } from 'mobx-state-tree';
import ProgramCardComponentBase from './internal/ProgramCardComponentBase';
import Tracker from './Tracker';

// Custom reference for card editor mode
const TrackerForTrackersStoreReference = types.maybeNull(
  types.reference(Tracker, {
    get(identifier /* string */, parent /*Store*/) {
      const trackersStore = getRoot(parent).trackersStore;
      if (trackersStore) {
        // Use server id to find tracker to avoid out-of-sync references to newly created trackers
        // We also need the ability to use the identifier of the tracker that's created
        // by mobx as a uuid for template trackers
        const tracker = find(
          trackersStore.trackers,
          t => t.serverId === identifier || t.id === identifier
        );
        return tracker || null;
      }
    },
    // given a tracker, produce the identifier that should be stored. again, we should always use the server id here
    set(value /* Tracker */) {
      return value.serverId;
    },
  })
);

const EditorPart = types
  .model('EditorPart', {
    trackerId: TrackerForTrackersStoreReference,
  })
  .actions(self => ({
    setMeta(meta) {
      self.meta = meta;
    },
    setBody(text) {
      self.body = text;
    },
    setPlaceholder(placeholder) {
      self.placeholder = placeholder;
    },
    setUrl(url) {
      self.url = url;
    },
    setGoalThreshold(val) {
      self.goalThreshold = val;
    },
    setGoalType(val) {
      self.goalType = val;
    },
    setGoalInterval(val) {
      self.goalInterval = val;
    },
    setTrackerId(val) {
      self.trackerId = val;
    },
    setPdf(pdf) {
      self.upload = pdf;
    },
    setImage(image, type /*, source*/) {
      if (type === 'upload') {
        self.upload = image;
        self.meta = null;
      } else {
        self.meta = image;
        self.upload = null;
      }
    },
    setRank(rank) {
      self.rank = rank;
    },
    setType(type) {
      self.type = type;
    },
    setClubId(clubId) {
      self.clubId = clubId;
    },
  }));

const EditorCardComponent = types.compose(
  'EditorCardComponent',
  ProgramCardComponentBase,
  EditorPart
);

export default EditorCardComponent;
