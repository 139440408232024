import { types } from 'mobx-state-tree';
import CardBase from './internal/CardBase';
import EditorCardComponent from './EditorCardComponent';

const EditorPart = types
  .model('EditorPart', {
    components: types.optional(types.array(EditorCardComponent), []),
    rank: types.optional(types.number, 0),
  })
  .actions(self => {
    const rerankComponents = () => self.components.map((c, k) => c.setRank(k + 1));

    const insertComponent = (index, component) => {
      component.setRank(index + 1);
      self.components.splice(index, 0, component);
      rerankComponents();
    };

    const removeComponent = component => {
      self.components.splice(self.components.indexOf(component), 1);
      rerankComponents();
    };

    const updateServerId = id => {
      self.serverId = id;
    };

    //Used by react-sortable-hoc
    const onSortEnd = ({ oldIndex, newIndex }) => {
      const components = [...self.components];
      const [item] = components.splice(oldIndex, 1);
      components.splice(newIndex, 0, item);
      self.components = components;
      rerankComponents();
    };

    //Used by react-dnd
    const swapComponents = (oldId, newId) => {
      const components = [...self.components];
      const newIndex = components.findIndex(c => c.id === newId);
      const oldIndex = components.findIndex(c => c.id === oldId);
      const [item] = components.splice(oldIndex, 1);
      components.splice(newIndex, 0, item);
      self.components = components;
      rerankComponents();
    };

    const setTitle = title => {
      self.title = title;
    };

    const setArchived = archived => {
      self.archived = archived;
    };

    const setName = name => {
      self.name = name;
    };

    const setShowInLibrary = showInLibrary => {
      if (!showInLibrary) self.permissionLevel = 0;
      else self.permissionLevel = Math.max(self.permissionLevel, 1);
    };

    const setIsCompanyCard = isCompanyCard => {
      if (isCompanyCard) self.permissionLevel = 2;
      else self.permissionLevel = Math.min(self.permissionLevel, 1);
    };

    const setCompanyId = companyId => {
      self.companyId = companyId;
    };

    const setVersion = version => {
      self.version = version;
    };

    const setImage = (image, type /*, source*/) => {
      if (type === 'upload') {
        self.upload = image;
        self.background = null;
      } else if (type === 'catalog') {
        self.background = image;
        self.upload = null;
      } else if (image === null) {
        self.upload = null;
        self.background = null;
      }
    };

    const setStackId = stackId => {
      self.stackId = stackId;
    };

    const setRank = rank => (self.rank = rank);

    return {
      insertComponent,
      removeComponent,
      updateServerId,
      setTitle,
      setArchived,
      setShowInLibrary,
      setName,
      setVersion,
      setIsCompanyCard,
      setCompanyId,
      setImage,
      setStackId,
      setRank,
      onSortEnd,
      swapComponents,
    };
  });

const EditorCard = types.compose(
  'EditorCard',
  CardBase,
  EditorPart
);

export default EditorCard;
