import buildTrackerComponents from './buildTrackerComponents';

/**
 * show a tracker based on stats collected about the tracker OR an individual log
 */
function getLogDisplayComponents({
  stats,
  log,
  tracker,
  useMetricUnits,
  fieldAbbreviations,
  usePreferredTimeUnit = false /* use hours for sleep, minutes for cardio */,
  useLongFormForUnits = false,
  useGoalOnlyFormatForZeroTotal = false,
}) {
  let valueComponents;

  if (stats) {
    valueComponents = buildTrackerComponents({
      value: stats.total || stats.total === 0 ? stats.total : stats.latestLog, // favor totals because they're usually for the whole day
      tracker,
      fieldAbbreviations,
      usePreferredTimeUnit,
      useMetricUnits,
      useLongFormForUnits,
    });
  } else {
    valueComponents = buildTrackerComponents({
      value: log, // favor totals because they're usually for the whole day
      tracker,
      fieldAbbreviations,
      usePreferredTimeUnit,
      useMetricUnits,
      useLongFormForUnits,
    });
  }

  if (
    stats &&
    stats.goal &&
    !(
      stats.aggregateType === 'count' && stats.goal <= 1
    ) /* this keeps a goal from showing for an assessment, which is always "enter this one time" */
  ) {
    const goalComponents = buildTrackerComponents({
      value: stats.goal, // favor totals because they're usually for the whole day
      tracker,
      fieldAbbreviations,
      usePreferredTimeUnit,
      useLongFormForUnits,
    });
    if (stats.aggregateType === 'sum') {
      if (useGoalOnlyFormatForZeroTotal && !stats.latestLog && !stats.total) {
        return {
          goal: goalComponents,
          value: null,
        };
      }
      return {
        goal: goalComponents,
        value: valueComponents,
      };
    } else {
      // times - which doesn't exist anymore
      return {
        goal: null,
        value: valueComponents,
      };
    }
  }

  // TODO: separate "times" format for number of logs goal

  return {
    goal: null,
    value: valueComponents,
  };
}

export default getLogDisplayComponents;
