import { getLogCatalog } from './getLogCatalog';
import getTrackerDisplayProperties, { getTrackerType } from './getTrackerDisplayProperties';

const resolveTrackerType = ({ tracker, trackerType }) => {
  let actualTrackerType = trackerType;
  if (tracker && !trackerType) {
    actualTrackerType = getTrackerType(tracker);
  }
  return actualTrackerType;
};

/**
 * Process a series of logs from the log or tracker endpoint and derive metadata from them for later retrieval.
 * This assumes the input of standard tracker/ log DTO's from the API that have been translated to JavaScript camelCase
 * by something like camelcase-keys... unless options.favorSnakeCaseFields = true.
 *
 * @export
 * @class TrackerMetadataProvider
 */
export default class TrackerMetadataProvider {
  _favorSnakeCaseFields = false;

  // Available options:
  // favorSnakeCaseFields: if true, default to snake_case field if available in tracker display properties
  constructor(options) {
    if (options) {
      this._favorSnakeCaseFields = !!options.favorSnakeCaseFields;
    }
  }

  logsByTrackerId = {};

  /**
   * Process a list of logs so their metadata can be retreived.
   *
   * @param {Object} param (destructured params)
   * @param {array} param.logs - array of log DTO's from API. May contain multiple dates.
   * @param {string} param.trackerType - The client-side type from the TrackerDisplayProperties
   */
  processLogs = ({ logs, trackerType, trackerId }) => {
    const trackerDisplayProperties = getTrackerDisplayProperties(trackerType);
    // we're only processing trackers with deduplication right now, because that's the only metadata we
    // present
    this.logsByTrackerId[trackerId] = getLogCatalog({
      logs,
      aggregateType: trackerDisplayProperties.aggregateType,
      requiresDeduplication: !!trackerDisplayProperties.deduplicationRule,
      deduplicationRule: trackerDisplayProperties.deduplicationRule,
      primaryField: this._favorSnakeCaseFields
        ? trackerDisplayProperties.primaryField_snakeCase || trackerDisplayProperties.primaryField
        : trackerDisplayProperties.primaryField,
      shiftDayWindow: trackerDisplayProperties.shiftDayWindow,
    });
  };

  /**
   * Process a list of trackers so their metadata can be retreived.
   *
   * @param {array} trackers array of tracker DTO's from API
   */
  processTrackers = trackers => {
    trackers.forEach(tracker => {
      this.processLogs({
        logs: tracker.user.logs ? tracker.user.logs : [],
        trackerType: getTrackerType(tracker),
        trackerId: tracker.id,
      });
    });
  };

  /**
   * Get an object of client-side metadata for a log record
   *
   * @param {Object} param (destructured params)
   * @param {Object} param.tracker - tracker DTO from API. Specify this or the trackerType
   * @param {string} param.trackerType - The client-side type from the TrackerDisplayProperties
   * @param {Object} param.log = log DTO from API
   * @return {OBject} the log metadata. Only includes isCrossedOut property right now.
   */
  getLogClientMetadata = ({ trackerId, log }) => {
    if (!this.logsByTrackerId[trackerId]) {
      return {};
    }
    return {
      // should the record be indicated as redundant and excluded from totals
      isCrossedOut: !!this.logsByTrackerId[trackerId].crossedOutLogs[log.id],
      isRedundant: !!this.logsByTrackerId[trackerId].redundantLogs[log.id],
      isChild: !!this.logsByTrackerId[trackerId].childLogs[log.id],
      ...this.logsByTrackerId[trackerId].basicMetadata[log.id],
    };
  };

  /**
   * Get an object of client side metadata for a tracker record
   *
   * @param {Object} param (destructured params)
   * @param {Object} param.tracker - tracker DTO from API. Specify this or the trackerType
   * @param {string} param.logs - array of log DTO's from API. May contain multiple dates.
   * @param {string} param.trackerType - The client-side type from the TrackerDisplayProperties
   * @return {Object} the tracker metadata. Includes everything from getTrackerDisplayProperties
   */
  getTrackerClientMetadata = ({ tracker, trackerType }) => {
    const actualTrackerType = resolveTrackerType({ tracker, trackerType });
    //if (this.logsByTrackerType[actualTrackerType]) {
    // doesn't matter if we check for the existence of logs
    return getTrackerDisplayProperties(actualTrackerType);
    //}
    //return {};
  };
}
