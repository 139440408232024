import React from 'react';

const Svg = props => <svg {...props} />;
const Circle = props => <circle {...props} />;
const Line = props => {
  //const styleArray = [];
  const styleObj = {};
  // not entirely sure if this is actually necessary
  if (props.stroke) {
    styleObj.stroke = props.stroke;
  }
  if (props.strokeWidth) {
    styleObj.strokeWidth = props.strokeWidth >= 1 ? props.strokeWidth : 1;
  }
  if (props.opacity) {
    styleObj.strokeOpacity = props.strokeOpacity;
  }
  //const style = styleArray.join(';');
  return <line style={styleObj} {...props} />;
};
const Rect = props => {
  const { onPressIn, onResponderMove, onMouseMove, onMouseLeave, ...standardProps } = props;
  const additionalProps = {};
  if (onPressIn) {
    additionalProps.onClick = onPressIn;
  }
  if (onMouseMove) {
    additionalProps.onMouseMove = onMouseMove;
  }
  if (onMouseLeave) {
    additionalProps.onMouseLeave = onMouseLeave;
  }
  return <rect {...standardProps} {...additionalProps} />;
};
const G = props => {
  if (props.x || props.y) {
    const xTransform = props.x || 0;
    const yTransform = props.y || 0;
    return <g transform={`translate(${xTransform},${yTransform})`} {...props} />;
  }
  return <g {...props} />;
};
const Text = props => <text {...props} />;

const TSpan = props => <tspan {...props} />;

const Path = props => <path {...props} />;

const Mask = props => <mask {...props} />;

const Defs = props => <defs {...props} />;

const Ellipse = props => <ellipse {...props} />;

const ClipPath = props => <clipPath {...props} />;

const Polygon = props => <polygon {...props} />;

export { Svg, Circle, Line, Rect, G, Text, Path, Mask, Defs, ClipPath, Ellipse, TSpan, Polygon };
