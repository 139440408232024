import React from 'react';
import PropTypes from 'prop-types';
import { last } from 'lodash';
import graphStyleDefaults from './graphStyleDefaults';
import MultiLineGraph from './MultiLineGraph';
import LiftGainGraphedLine from './util/LiftGainGraphedLine';
import YAxisLine from './util/YAxisLine';
import CommonGraphSpacingProvider from './util/NewCommonGraphSpacingProvider';
import getLineGraphBounds from './util/getLineGraphBounds';

const ExerboticsGraph = ({
  head,
  tail,
  baseline,
  data,
  height,
  width,
  color,
  baselineColor,
  overBaselineColor,
  svg,
  formatYAxisValue,
  axisMarkerFontSize,
  axisMarkerDescenderHeight,
  axisMarkerTextColor,
  yAxisLineProps,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  minDelta,
  pointRadius,
  ...rest
}) => {
  const { G } = svg;

  const { maxBarHeight } = CommonGraphSpacingProvider.getCommonSpacingProps({
    numDates: data.length,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    graphWidth: width,
    graphHeight: height,
    axisMarkerFontSize,
    axisMarkerDescenderHeight,
  });

  const topAndBottomBuffer = 10;

  // get values to calculate total range
  const allValues = data.slice();
  if (tail) {
    allValues.push(tail);
  }

  if (head) {
    allValues.push(head);
  }

  if (allValues.length === 0) {
    return null;
  }

  // in an ideal world, we would set the baseline as head/ tail if there were no records, but baseline
  // only comes from a record, so [shrug]

  const valuesToCalculateMinMax = allValues.map(d => (d.value ? d.value : null));
  valuesToCalculateMinMax.push(
    baseline - topAndBottomBuffer > 0 ? baseline - topAndBottomBuffer : 0
  );

  const { minValue, maxValue } = getLineGraphBounds({
    values: valuesToCalculateMinMax,
    minDelta,
    minTopBottomBuffer: topAndBottomBuffer,
    roundToTens: true,
  });

  const commonTargetLineProps = {
    formatYAxisValue,
    width,
    axisMarkerFontSize,
    axisMarkerTextColor,
    svg,
    drawHeight: maxBarHeight,
    minValue,
    maxValue,
    fixedLeftOffset: paddingLeft,
    fixedRightOffset: paddingRight,
    tipHeight: 0,
    ...yAxisLineProps,
  };

  let yAxisLines;
  const lowPoint = minValue;
  const highPoint = maxValue;
  //const midPoint = (lowPoint + highPoint) / 2;
  yAxisLines = (
    <G>
      <YAxisLine
        key={`YAxisLineLow`}
        target={lowPoint}
        highestTarget={highPoint}
        {...commonTargetLineProps}
      />
      <YAxisLine
        key={`YAxisLineHigh`}
        target={highPoint}
        highestTarget={highPoint}
        {...commonTargetLineProps}
      />
      <YAxisLine
        key={`YAxisLineBaseline`}
        target={baseline}
        highestTarget={highPoint}
        {...commonTargetLineProps}
        axisMarkerTextColor={color}
        axisMarkerFontWeight={600}
        isDashedLine
      />
    </G>
  );

  return (
    <MultiLineGraph
      {...{
        height,
        width,
        axisMarkerFontSize,
        axisMarkerDescenderHeight,
        axisMarkerTextColor,
        paddingLeft,
        paddingRight,
        paddingTop,
        paddingBottom,
        ...rest,
      }}
      color={color}
      data={data.map(d => ({ ...d, value: { value: d.value } }))}
      svg={svg}
      head={head ? { ...head, value: head.value ? { value: head.value } : { value: null } } : null}
      tail={tail ? { ...tail, value: tail.value ? { value: tail.value } : { value: null } } : null}
      toolTipValueTextColor="red"
      lineDefinitions={[
        {
          valuePropName: 'value',
          color,
          yAxisLabelSuffix: '',
          baseline,
          overBaselineColor,
          baselineColor,
        },
      ]}
      yAxisLineProps={{ reverseLabels: false }}
      yAxisLineMode="default"
      GraphedLineComponent={LiftGainGraphedLine}
      alternativeYAxisLines={yAxisLines}
      overrideMinValue={minValue}
      overrideMaxValue={maxValue}
      useSkinnyLine
      touchTrackerOverlayProps={{
        selectionMode: 'withData',
        data,
      }}
      columnHighlightProps={{
        dataPointRadius: pointRadius,
        showDataPoint: true,
        getYPosForIndex: index =>
          maxBarHeight - maxBarHeight * ((data[index].value - minValue) / (maxValue - minValue)),
      }}
    />
  );
};

ExerboticsGraph.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired, // line color
  overBaselineColor: PropTypes.string.isRequired, // between line and baseline
  baselineColor: PropTypes.string.isRequired, // under baseline
  // when generating automatic midpoints, how many lines to draw
  numYAxisLines: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ).isRequired,
  //Optionally include "head" and "tail" points so we can draw where the graph is "going"
  head: PropTypes.shape({
    date: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  tail: PropTypes.shape({
    date: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  // SVG wants absolute dimensions
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  xAxisLabelType: PropTypes.oneOf(['weekdays', 'everyXDays', 'none', 'everyMonth']),
  // override how to display any numerical values we show
  // Takes the value and returns the value as a number or string
  // This is applied to values and targets, so if you have values that
  // are seconds but you're formatting them as minutes, your values and targets should be seconds
  formatToolTipValue: PropTypes.func,
  formatToolTipUnits: PropTypes.func,
  formatYAxisValue: PropTypes.func,
  // override date values
  formatDateAsDayOfMonth: PropTypes.func,
  formatDateAsMonth: PropTypes.func,
  axisMarkerFontSize: PropTypes.number,
  axisMarkerDescenderHeight: PropTypes.number,
  axisMarkerTextColor: PropTypes.string,
  bottomCaptionText: PropTypes.string,
  yAxisLineMode: PropTypes.oneOf(['lowMidHigh', 'average', 'none']),
  // spacing props
  minTooltipPlusStemHeight: PropTypes.number,
  // this function will be called whenever the user clicks
  // or presses in on a data point on the graph
  onPressInDate: PropTypes.func,
  // line visuals
  lineWidth: PropTypes.number,
  pointRadius: PropTypes.number,
  // optionally fix the min/ max values of the graph
  // must set both of these for any to be used
  // useful for things with very set ranges, like Nudge Score
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  // alternative way to define range - suggest a minimum delta between min and max,
  // we'll center data around that
  minDelta: PropTypes.number,
  // snap tooltip to the nearest data point
  // useful if there are a lot of null values in the data
  snapToNearestData: PropTypes.bool,
  // passthrough props to Y Axis lines
  yAxisLineProps: PropTypes.any,
  // new tooltip stuff
  // *** toggle display modes ***
  // called with date when user drags/ hovers over date
  onHoverOverDate: PropTypes.func,
  // called with date when user presses date
  onPressDate: PropTypes.func,
  // reduce opacity for unselected dates
  unselectedTooltipDateOpacity: PropTypes.number,
  // date for which to show tooltip
  selectedDate: PropTypes.string,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

ExerboticsGraph.defaultProps = {
  numYAxisLines: 0,
  formatToolTipValue: val => val.toString(),
  formatYAxisValue: graphStyleDefaults.formatYAxisValue,
  formatToolTipUnits: () => 'units',
  formatDateAsDayOfMonth: graphStyleDefaults.formatDateAsDayOfMonth,
  formatDateAsMonth: graphStyleDefaults.formatDateAsMonth,
  axisMarkerFontSize: graphStyleDefaults.axisMarkerFontSize,
  axisMarkerDescenderHeight: graphStyleDefaults.axisMarkerDescenderHeight,
  axisMarkerTextColor: graphStyleDefaults.axisMarkerTextColor,
  yAxisLineMode: 'lowMidHigh',
  xAxisLabelType: 'none',
  bottomCaptionText: null,
  minTooltipPlusStemHeight: graphStyleDefaults.minTooltipPlusStemHeight,
  onPressInDate: () => {},
  lineWidth: 2,
  pointRadius: 3,
  minValue: null,
  maxValue: null,
  minDelta: null,
  snapToNearestData: false,
  yAxisLineProps: null,
  // called with date when user drags/ hovers over date
  onHoverOverDate: () => {},
  // called with date when user presses date
  onPressDate: null,
  // reduce opacity for unselected dates
  unselectedTooltipDateOpacity: 1,
  // date for which to show tooltip
  selectedDate: null,
  paddingLeft: 24,
  paddingRight: 24,
  paddingBottom: 16,
  paddingTop: 16,
};

export default ExerboticsGraph;
