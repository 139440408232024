import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Colors } from '../lib/constants';

class CompletionGraph extends Component {
  render() {
    const {
      diameter,
      svg,
      completedColor,
      percentComplete,
      strokeWidth,
      fontSize,
      totalGoals,
      numGoalsComplete,
      bgColor,
      check,
      showText,
      contentsColor,
      LabelComponent,
      isCoachMobile,
      TextComponent,
      ViewComponent
    } = this.props;

    const { Svg, G, Circle, Path, } = svg;

    const polarToCartesian = function(centerX, centerY, radius, angleInDegrees) {
      const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    };

    const describeArc = function(x, y, radius, startAngle, endAngle) {
      const start = polarToCartesian(x, y, radius, endAngle);
      const end = polarToCartesian(x, y, radius, startAngle);

      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

      const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
        ' '
      );

      return d;
    };

    const pathDiameter = diameter;

    return (
      <ViewComponent
        style={{
          width: diameter,
          height: diameter,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {showText && !check ? (
          LabelComponent ? (
            <LabelComponent />
          ) : (
            <ViewComponent style={{ position: 'absolute', zIndex: 2, width: '100%' }}>
              <TextComponent
                style={{
                  fontSize,
                  color: contentsColor,
                  fontWeight: '600',
                  textAlign: 'center',
                  marginTop: diameter > 70 ? -12 : 0,
                }}>
                {`${numGoalsComplete}/${totalGoals}`}
              </TextComponent>
              {diameter > 70 && (
                <TextComponent
                  style={{
                    fontSize,
                    color: contentsColor,
                    position: 'absolute',
                    zIndex: 2,
                    textAlign: 'center',
                    fontWeight: '400',
                    marginTop: 12,
                    width: '100%',
                  }}>
                  Completed
                </TextComponent>
              )}
            </ViewComponent>
          )
        ) : null}
        <Svg
          version="1.1"
          width={diameter}
          height={diameter}
          viewBox={'0 0 ' + diameter + ' ' + diameter}>
          <G>
            <G transform="rotate(0.47)">
              <Circle
                stroke={percentComplete === 1 ? completedColor : 'transparent'}
                strokeWidth={strokeWidth}
                fill={bgColor}
                cx={diameter / 2}
                cy={diameter / 2}
                r={diameter / (isCoachMobile? 2.2 : 2)}
              />
              <Path
                stroke={completedColor}
                strokeWidth={strokeWidth}
                fill="none"
                x={0}
                y={0}
                d={describeArc(
                  pathDiameter / 2,
                  pathDiameter / 2,
                  pathDiameter / 2 - strokeWidth / 2,
                  0,
                  percentComplete * 360
                )}
              />
              {check && (
                <Path
                  x={diameter > 30 ? diameter / 2 - 20 : diameter / 2 - 15}
                  y={diameter > 30 ? diameter / 2 - 22 : diameter / 2 - 16.5}
                  d="M 13.088 22.024 L 17.703 26.913 L 28.088 15.913"
                  fill="transparent"
                  strokeWidth={strokeWidth}
                  stroke={contentsColor}
                  transform={diameter > 30 ? 'scale(1)' : 'scale(.75)'}
                />
              )}
            </G>
          </G>
        </Svg>
      </ViewComponent>
    );
  }
}

CompletionGraph.propTypes = {
  svg: PropTypes.object.isRequired,
  completedColor: PropTypes.string,
  strokeWidth: PropTypes.number,
  fontSize: PropTypes.number,
  percentComplete: PropTypes.number.isRequired,
  bgColor: PropTypes.string,
  contentsColor: PropTypes.string,
  check: PropTypes.bool,
  showText: PropTypes.bool,
  LabelComponent: PropTypes.func,
};

CompletionGraph.defaultProps = {
  completedColor: '#807F80',
  bgColor: '#FFFFFF',
  strokeWidth: 2,
  fontSize: 12,
  check: false,
  showText: true,
  contentsColor: Colors.DARK_TEXT,
};

export default CompletionGraph;
