import { sortBy, first, last, takeRight, uniq, reduce } from 'lodash';
import {
  getDatesForRange,
  getDatesForLogs,
  jsDateToDateString,
  dateStringToJsDate,
} from '../../lib/dates';
import {
  getTimeForLog,
  logsForDate,
  totalsForDate,
  getLogCatalog,
  getPrimaryFieldValue,
  latestHistoricalForDate,
  sourceToDisplaySource,
} from '../../data/trackers';

/**
 * Used to determine if there is any data for a graph, by looking beyond the time range
 * to see if there are any records just before that. Works with the tail property.
 */
const mostRecentLogOn = logs => {
  if (logs.length === 0) {
    return null;
  }
  const logsSorted = sortBy(logs, l => getTimeForLog(l));
  const lastLog = last(logsSorted);
  if (lastLog) {
    return getTimeForLog(lastLog);
  }
  return null;
};

/**
 * @typedef {Object} GraphSliceData
 * @property {string} date the date for the data point
 * @property {number|Object} value will either be a number for simple numerical graphs, or an object with properties
 * required by the graph.
 * Examples:
 * minMax - will have min and max properties, which are the min and max numerical value for the day
 * bloodPressure - will have systolic and diastolic properties
 * calories - will have calories, fats, proteins, and carbs
 */

/**
 * Takes log data for a range and outputs the corresponding graph slice.
 *
 * @param {Object} param (destructured params)
 * @param {string} param.startDate - Start date for slice in YYYY-MM-DD format
 * @param {string} param.endDate - End date for slice in YYYY-MM-DD format
 * @param {Array} param.logs - Array of logs from API. May be translated to camelCase. Include all logs, even
 * outside the range (they're used for "recent/ any" data props). Should be for a single tracker.
 * @param {string} param.graphType - One of bar, line, minMax, bloodPressure, calories
 * @param {string} param.primaryField - Name of the field of the log that is to be graphed. Note that the
 * bloodPressure and calories graphs type ignore this.
 * @param {string} param.requiresDeduplication - if true, records must be deduplicated
 * @param {string} param.deduplicationRule - activitiesAndSteps or sleep
 * @return {[GraphSliceData]} the resulting graph slice, containing data for the range
 */
const getGraphSlice = ({
  startDate,
  endDate,
  // if timeframe is provided, we'll restrict the actual dates to the most recent number of days representing that timeframe
  // e.g., (latest week, month)
  timeframe,
  logs,
  graphType,
  calculateAverage,
  primaryField,
  requiresDeduplication = false,
  deduplicationRule,
  // default off for compatiblity with Nudge v4, which expects just data list back
  includeExtraData = false,
  interpolateDates = true,
}) => {
  // TODO: should this be just from effectiveStartDate when specified?
  let dates = interpolateDates
    ? getDatesForRange({ startDate, endDate })
    : getDatesForLogs({ logs, startDate, endDate });
  let effectiveStartDate = startDate;
  if (timeframe === 'week') {
    dates = takeRight(dates, 7);
    effectiveStartDate = dates[0];
  }
  if (timeframe === 'month') {
    dates = takeRight(dates, 30);
    effectiveStartDate = dates[0];
  }

  let graphSlice = [];
  let sources = [];

  // apply data to bar graphs.
  if (graphType === 'none') {
    graphSlice = [];
  }

  // apply data to bar graphs.
  if (graphType === 'bar') {
    const catalog = getLogCatalog({
      logs,
      primaryField,
      aggregateType: 'total',
      requiresDeduplication,
      deduplicationRule,
    });

    graphSlice = dates.map(date => {
      return {
        hasEntry: !!catalog.dates[date],
        date,
        value: catalog.dates[date] ? catalog.dates[date].total : 0,
      };
    });
  }

  if (graphType === 'duration') {
    const catalog = getLogCatalog({
      logs,
      primaryField,
      aggregateType: 'total',
      requiresDeduplication,
      deduplicationRule,
    });
    graphSlice = dates.map(date => {
      const logDateMap = catalog.dates[date] || { logs: [] };
      // filter out crossed-out logs
      const countableLogs = logDateMap.logs.filter(l => !catalog.crossedOutLogs[l.id]);
      const intervals = [];
      countableLogs.forEach(log => {
        intervals.push({
          endUserTime: log.userTime,
          duration: log[primaryField],
        });
      });

      return {
        hasEntry: !!catalog.dates[date],
        date,
        value: intervals,
      };
    });
  }

  // used for weight/ body fat to show change over the month
  const deltaProps = {};
  if (graphType === 'line') {
    // used for weight/ body fat graphs
    const latestHistoricalForStartDate = latestHistoricalForDate({ logs, date: startDate });
    const latestHistoricalForEndDate = latestHistoricalForDate({ logs, date: endDate });

    deltaProps.latestHistoricalForStartDate = {
      value: latestHistoricalForStartDate ? latestHistoricalForStartDate[primaryField] : 0,
      date: startDate,
    };

    deltaProps.latestHistoricalForEndDate = {
      value: latestHistoricalForEndDate ? latestHistoricalForEndDate[primaryField] : 0,
      date: endDate,
    };

    // if true, not a real delta, shouldn't be displayed, because there's no value as of the start date
    deltaProps.hasNoDelta = !latestHistoricalForStartDate;

    deltaProps.delta =
      deltaProps.latestHistoricalForEndDate.value - deltaProps.latestHistoricalForStartDate.value;
  }

  // line always uses latest value. Blood pressure is just two lines
  if (graphType === 'line' || graphType === 'bloodPressure' || graphType === 'exerbotics') {
    const catalog = getLogCatalog({
      logs,
      primaryField,
      aggregateType: 'latest',
    });

    // We always continue with the same value for the next day if there is no new value
    // So, we start with whatever value should be in place for the first day if none is already on the
    // first day
    graphSlice = dates.map(date => {
      // use null if there is no data for that day so we don't actually graph a point,
      // just have a slope between the points
      let latest = catalog.dates[date] ? catalog.dates[date].latest : null;
      if (graphType !== 'bloodPressure') {
        return {
          hasEntry: !!catalog.dates[date],
          date,
          value: !latest ? null : latest[primaryField],
        };
      } else {
        return {
          hasEntry: !!catalog.dates[date],
          date,
          value: latest ? { systolic: latest.systolic, diastolic: latest.diastolic } : null,
        };
      }
    });
  }

  // minMax always graphs a min and a max for the date, and graphs nothing on dates that do not have min/ max
  // BUG: minmax probably breaks if there is a null value on a date right now
  if (graphType === 'minMax') {
    graphSlice = dates.map(date => {
      const logsForThisDate = logsForDate({ logs, date });
      let min;
      let max;
      if (logsForThisDate.length) {
        const logValues = logsForThisDate.map(l => l[primaryField]);
        min = Math.min(...logValues);
        max = Math.max(...logValues);
      }
      return {
        date,
        value: min && max ? { min, max } : null,
      };
    });
  }

  // calories is just a bar graph with many totals
  if (graphType === 'calories') {
    graphSlice = dates.map(date => {
      const totals = totalsForDate({
        logs,
        date,
        fieldsToTotal: ['calories', 'fat', 'protein', 'carbohydrates'],
      });
      return {
        date,
        value: {
          calories: totals.calories,
          fats: totals.fat,
          proteins: totals.protein,
          carbs: totals.carbohydrates,
        },
      };
    });
  }

  if (graphType === 'freeformQuestion' || graphType === 'multipleChoiceQuestion') {
    const catalog = getLogCatalog({
      logs,
      primaryField,
      aggregateType: 'latest',
      requiresDeduplication: false,
    });

    graphSlice = dates.map(date => {
      let latest = catalog.dates[date] ? catalog.dates[date].latest : null;
      return {
        hasEntry: !!catalog.dates[date],
        date,
        value: !latest ? null : latest[primaryField],
      };
    });
  }

  if (graphType === 'multiLine') {
    const catalog = getLogCatalog({
      logs,
      primaryField,
      requiresDeduplication,
      deduplicationRule,
      aggregateType: 'latest',
    });

    // We always continue with the same value for the next day if there is no new value
    // So, we start with whatever value should be in place for the first day if none is already on the
    // first day
    graphSlice = dates.map(date => {
      // use null if there is no data for that day so we don't actually graph a point,
      // just have a slope between the points
      let latest = catalog.dates[date] ? catalog.dates[date].latest : null;
      return {
        hasEntry: !!catalog.dates[date],
        date,
        value: latest,
      };
    });

    // used just for heart rate right now, since we don't show it on daily
    // could extend to other trackers in the future; easiest with latest aggregate types right now
    sources = uniq(
      graphSlice
        .filter(dp => dp.value)
        .map(dataPoint => sourceToDisplaySource(dataPoint.value.source))
    );
  }

  if (!includeExtraData) {
    return graphSlice;
  }

  // new format: data points and head and tail, and whatever else helps us construct a slice:

  // head and tail
  // First record after the range
  const headRecord = first(
    sortBy(logs.filter(l => getTimeForLog(l) > dateStringToJsDate(endDate)), l => getTimeForLog(l))
  );

  // Last record before the range
  const tailRecord = last(
    sortBy(logs.filter(l => getTimeForLog(l) < dateStringToJsDate(effectiveStartDate)), l =>
      getTimeForLog(l)
    )
  );

  const head = headRecord
    ? {
        date: jsDateToDateString(getTimeForLog(headRecord)),
        value: getPrimaryFieldValue({ log: headRecord, primaryField }),
      }
    : null;
  const tail = tailRecord
    ? {
        date: jsDateToDateString(getTimeForLog(tailRecord)),
        value: getPrimaryFieldValue({ log: tailRecord, primaryField }),
      }
    : null;

  // latest log date
  const latestLogDate = getDateForMostRecentLog(logs);

  // recent data/ any data
  const hasAnyData = !!logs.length;

  // used to determine if we should show "no recent data"
  const hasDataInMainFrame = latestLogDate >= effectiveStartDate;

  // used to calculate average for aggregate type = sum
  let average = null;
  let averageForDaysLogged = null;
  const hasEntry = obj => {
    if (obj.hasOwnProperty('hasEntry')) {
      return obj.hasEntry;
    }
    return true;
  };
  if (calculateAverage && graphSlice.length) {
    if (graphType === 'duration') {
      function reduceSleepIntervals(intervals) {
        return reduce(intervals, (sum, interval) => sum + interval.duration, 0);
      }
      const sum = reduce(graphSlice, (sum, point) => sum + reduceSleepIntervals(point.value), 0);
      const daysLogged = reduce(graphSlice, (sum, x) => (hasEntry(x) ? sum + 1 : sum), 0);
      average = sum / graphSlice.length;
      averageForDaysLogged = daysLogged > 0 ? sum / daysLogged : 0;
    } else {
      const sum = reduce(graphSlice, (sum, point) => sum + point.value, 0);
      const daysLogged = reduce(graphSlice, (sum, point) => (hasEntry(point) ? sum + 1 : sum), 0);
      average = sum / graphSlice.length;
      averageForDaysLogged = daysLogged > 0 ? sum / daysLogged : 0;
    }
  }

  return {
    points: graphSlice,
    head,
    tail,
    latestLogDate,
    hasAnyData,
    hasDataInMainFrame,
    startDate,
    endDate,
    timeframe,
    sources,
    average,
    averageForDaysLogged,
    ...deltaProps,
  };
};

/**
 * Determines the date of the most recent log in the list of logs.
 * Used for determining whether a graph has "recent" data (we show graphs without recent data differently)
 *
 * @param {Object} logs list of logs from the Nudge API
 * @return {String} date in YYYY-MM-DD format for the most recent log in the list of logs, null if there is none
 */
const getDateForMostRecentLog = logs => {
  const mostRecentLogDate = mostRecentLogOn(logs);
  if (mostRecentLogDate) {
    return jsDateToDateString(mostRecentLogDate);
  }
  return null;
};

/**
 * Determine what trackers have any data for a given range of dates
 */
/*const getComplianceSlice = ({ trackers, startDate, endDate }) => {
  const dates = getDatesForRange({ startDate, endDate });
  const complianceDates = dates.map(d => ({
    date: d,
    trackers: trackers.map(t => ({
      rank:  t.user.settings.rank,
      hasData: 
    }))
  }))
}*/

export { getGraphSlice, getDateForMostRecentLog };
