import { filter, first, groupBy, isEqual, keys, last, sortBy, unionBy, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { destroy, flow, getEnv, types } from 'mobx-state-tree';
import {
  dateRangeForFullWeeksCoveringRange,
  getDateMinusXDays,
  getLastXDaysFrom,
  jsDateToDateTimeString,
} from '../../lib/dates';
import { getDatesOutsideOfRange, LoadingState } from '../common';
import FeedCard from './FeedCard';
import ProgramEvent from './ProgramEvent';
import ProgramEventV2 from './ProgramEventV2';
import Tracker from './Tracker';

const apiDateFormat = 'yyyy-MM-dd'; // TODO: refactor this out

/**
 * Make sure we use consistent sizes for the different timeframes, and not just whatever luxon uses
 * (e.g., quarter being 90 days vs 3 months)
 */
function daysForTimeframe(timeframe) {
  let sliceSize;
  switch (timeframe) {
    case 'week':
      sliceSize = 7;
      break;
    case 'month':
      sliceSize = 30;
      break;
    case 'quarter':
      sliceSize = 90;
      break;
    case 'year':
      sliceSize = 365;
      break;
    default:
      sliceSize = 30;
  }
  return sliceSize;
}

// Keep this private function outside of the store so composed stores can use it
export const updateTrackers = ({ store, trackers, startDate, endDate }) => {
  // page out old versions of this range, page in new ones
  trackers.forEach(updatedTracker => {
    const currentTracker = store.trackerFor(updatedTracker.id);
    if (currentTracker) {
      currentTracker.swapLogPage({
        startDate,
        endDate,
        logs: updatedTracker.user.logs,
        updatedTracker,
      });
    } else {
      // new tracker
      store.trackers.push(updatedTracker);
    }
  });
};

/** ProgramEvent is DEPRECATED. ProgramEventV2 is updated to use the same model for assignments
 * (including program and stack assignments) that we use for assignment creation & editing.
 * To adopt the new model ids for stack_share and user_program_share.share should be cast as follows:
 *   {
 *     ...camelcaseKeys(dto.stack_share, { deep: true }),
 *     id: dto.stack_share.id.toString(),
 *     serverId: dto.stack_share.id,
 *   }
 * at which point the dispatcher function will recognize and use ProgramEventV2.
 * Once we're doing this everywhere we can phase out ProgramEvent entirely.
 */
const EventType = types.union(
  {
    dispatcher: snapshot =>
      snapshot.share &&
      ((snapshot.share.stackShare && typeof snapshot.share.stackShare.id === 'string') ||
        (snapshot.share.userProgramShare &&
          snapshot.share.userProgramShare.share &&
          typeof snapshot.share.userProgramShare.share.id === 'string'))
        ? ProgramEventV2
        : ProgramEvent,
  },
  ProgramEvent,
  ProgramEventV2
);

/**
 * A miserable do-everything store that contains everything on the Program feed/ tracker list for a single client.
 * Will contain ALL cards and ALL trackers that are paged in for use, so they don't have to be reloaded.
 * This includes the main card feed, My Data tab, and cards loaded from a tag (or, the card detail, that is).
 * This looks horrible but we hope to refactor it some day as the API's are updated to load data in a manner that scales to all
 * these crazy use cases.
 */
const ReadOnlyLogStore = types
  .model('ReadOnlyLogStore', {
    // populate this with the client user ID
    // this store should be destroyed or paged out for another whenever the client context changes
    // this includes in the client app - some of the API endpoints actually require the client ID!
    id: types.maybeNull(types.identifierNumber),
    programCards: types.optional(types.array(FeedCard), []),
    programEvents: types.optional(types.array(EventType), []),
    nextProgramEventDate: types.maybeNull(types.string),
    trackers: types.optional(types.array(Tracker), []),
    loadProgramDataState: types.optional(LoadingState, {}),
    saveProgramDataState: types.optional(LoadingState, {}),
    loadStackHistoryState: types.optional(LoadingState, {}),
  })
  .views(self => ({
    trackerFor(id) {
      return self.trackers.find(t => t.id === id); //|| t.serverId === t.serverId); - seems like a good idea, but I would want to test more
      // nonetheless, I doubt this is compatible with creating trackers on coach
    },
    trackersFor(ids) {
      return self.trackers.filter(t => ids.includes(t.id));
    },
    get trackersSorted() {
      // read-only trackers stay out of the daily view
      return sortBy(self.trackers.slice(), t => t.sortKey);
    },
    // optimization for auto-sorted list of trackers in app and coach
    trackersSortedByMostRecentEntry(filter) {
      return sortBy(
        filter
          ? self.trackers.filter(
              t =>
                t.logs.length /* don't show any we haven't logged data for yet */ &&
                t.isGraphable /* don't show any that don't have graphs enabled */
            )
          : self.trackers /* show all trackers by default */,
        t => {
          const stats = t.statsFor({
            date: getEnv(self).getToday(),
            aggregateType: 'latestHistorical',
          });
          if (stats.latestLog) {
            // question trackers only log days, not times
            if (t.clientMeta.doesNotLogExactTime) {
              // add ID to create a more recent "time" (since later logs will have higher ID's)

              // have to consider just the day of and not the time, because of the weird thing
              // where updating a question tracker temporarily adds an additional record with the exact time.
              return (
                jsDateToDateTimeString(DateTime.fromSQL(stats.latestLog.date).toJSDate()) +
                '|' +
                stats.latestLog.serverId
              );
            }

            return stats.latestLog.userTime;
          }
          return jsDateToDateTimeString(new Date(1970, 1, 1));
        }
      ).reverse();
    },
    get programCardsSorted() {
      return sortBy(self.programCards.slice(), a => a.sortKey);
    },

    // *** program card feed stuff ***

    // this sorts AND filters out events that were not loaded as part of the main feed paging
    get mainFeedProgramEventsSorted() {
      let events = sortBy(self.programEvents.slice(), a => a.sortKey).reverse();
      if (self.canLoadAdditionalProgramEvents) {
        // the >= here is a little bit loaded - like, probably should be > to strickly keep non-main-feed stuff
        // out, but as long as the unloaded bit is contiguous, it's ok
        events = filter(events, e => e.date >= self.nextProgramEventDate);
      }
      return events;
    },
    // DEPRECATED: this is ALL events, but no feed should necessarily show all events.
    // Use mainFeedProgramEventsSorted for events that are relevant to the main feed, because it will only include events that
    // were loaded in the course of loading the main feed, and not events loaded for tag searches, etc.
    get programEventsSorted() {
      return sortBy(self.programEvents.slice(), a => a.sortKey).reverse();
    },
    programEventFor({ programCardId, date }) {
      return self.programEvents.find(
        e => e.date === date && e.programCard && e.programCard.id === programCardId.toString()
      );
    },
    // DEPRECATED: this is no longer used after new-client-profile
    // events grouped by date
    // used by Coach
    get programEventGroupedByDate() {
      const groupsByKey = groupBy(self.programEventsSorted, a => a.headerSortKey);
      const finalGroups = [];
      keys(groupsByKey).forEach(groupKey => {
        const groupItems = groupsByKey[groupKey];
        if (groupItems.length) {
          finalGroups.push({
            headerInfo: groupItems[0].headerInfo,
            items: sortBy(groupItems, g => g.itemSortKey).reverse(), // show latest shared card first
          });
        }
      });
      return finalGroups;
    },
    get stackCount() {
      const eventsFiltered = uniqBy(self.programEventsSorted, p => p.clientFeedUniquenessKey);
      return eventsFiltered.length;
    },
    // events grouped by date, with repeating events consolidated as stacks showing the most recent date
    get programFeedUniqueItemsGroupedByDate() {
      const eventsFiltered = uniqBy(
        self.mainFeedProgramEventsSorted,
        p => p.clientFeedUniquenessKey
      );
      const groupsByKey = groupBy(eventsFiltered, a => a.headerSortKey);
      const finalGroups = [];
      keys(groupsByKey).forEach(groupKey => {
        const groupItems = groupsByKey[groupKey];
        if (groupItems.length) {
          finalGroups.push({
            headerInfo: groupItems[0].headerInfo,
            items: sortBy(groupItems, g => g.itemSortKey).reverse(), // show latest shared card first
          });
        }
      });
      return finalGroups;
    },
    get canLoadAdditionalProgramEvents() {
      return self.nextProgramEventDate !== null;
    },

    // indicates if data is currently loading for a particular date
    // IMPORTANT: client app isn't using this right now
    // It doesn't work well. It didn't work for a long time
    isLoadingDate(date) {
      return (
        self.loadProgramDataState.isPending &&
        self.loadProgramDataState.context &&
        date >= self.loadProgramDataState.context.startDate &&
        date <= self.loadProgramDataState.context.endDate
      );
    },
    // *** for paging back and forth with graphs, use these to determine the next date when a paging button is pressed ***

    // gives next date for focus if graph is paged forward
    // returns null if can't page forward to that date
    pageForwardDateFor({ date, timeframe }) {
      const sliceSize = daysForTimeframe(timeframe);
      const maxDateForPaging = getEnv(self).getToday();
      const nextDate = DateTime.fromSQL(date)
        .plus({ days: sliceSize })
        .toFormat(apiDateFormat);
      if (nextDate > maxDateForPaging) {
        return maxDateForPaging;
      }

      return nextDate;
    },
    // gives next date for focus if graph is paged backward
    // returns null if can't page backward to that date (actually this is impossible)
    pageBackwardDateFor({ date, timeframe }) {
      const sliceSize = daysForTimeframe(timeframe);
      return DateTime.fromSQL(date)
        .minus({ days: sliceSize })
        .toFormat(apiDateFormat);
    },
    // determine min/ max date that should be shown on a graph based on the date and how large of a timeframe to show
    sliceDateRangeFor({ date, timeframe }) {
      const sliceSize = daysForTimeframe(timeframe);

      const today = getEnv(self).getToday();
      const diff = DateTime.fromSQL(today).diff(DateTime.fromSQL(date), ['days']);
      const lastDayInRange = getDateMinusXDays({
        date: today,
        x: sliceSize * Math.floor(diff.days / sliceSize),
      });
      const queryDates = getLastXDaysFrom({ date: lastDayInRange, x: sliceSize });
      return {
        startDate: first(queryDates),
        endDate: last(queryDates),
      };
      //const prospectiveDateRange = dateRangeForInterval({ date, interval: timeframe });
      //return { startDate: prospectiveDateRange.startDate, endDate: prospectiveDateRange.endDate };
    },
  }))
  .actions(self => {
    // *** private functions ***

    /**
     * We always call this as part of some other load - either loading program cards with corresponding log data,
     * loading the latest logs for MyData, or loading additional pages of logs
     */
    const loadLogSlice = flow(function* loadLogSlice({ startDate, endDate, trackerId }) {
      if (trackerId) {
        if (Array.isArray(trackerId)) {
          const logs = yield getEnv(self).logRepository.getDailyLogs({
            startDate,
            endDate,
            trackers: trackerId,
            clientId: self.id,
          });
          updateTrackers({
            store: self,
            trackers: self.trackersFor(trackerId),
            startDate,
            endDate,
            logs,
          });
        } else {
          const tracker = self.trackerFor(trackerId);
          const logs = yield getEnv(self).logRepository.getDailyLogsForTracker({
            startDate,
            endDate,
            trackerId: tracker.id,
            trackerType: tracker.type,
            clientId: self.id,
          });
          self.trackerFor(trackerId).swapLogPage({ startDate, endDate, logs });
        }
      } else {
        const trackers = yield getEnv(self).logRepository.getDailyLogs({
          startDate,
          endDate,
          clientId: self.id,
        });

        // TODO: remove trackers that dissappear
        updateTrackers({ store: self, trackers, startDate, endDate });
      }
    });

    // *** public functions ***

    /**
     * Call this as-is with no options to load latest events and corresponding log data.
     * Will load a previous range that's at least 30 days long (expanding to fit whole calendar weeks for
     * the sake of calculating weekly goals)
     * Adjust options.date to load a pervious 30+ days.
     * Thus, this isn't really "newest" events (but not ready to change the name yet)
     */
    const loadNewestProgramEvents = flow(function* loadNewestProgramEvents(options) {
      const today = getEnv(self).getToday();
      const myOptions = {
        date: today, // date from which to start loading next events, move back to load previous events
        flush: false, // if true
        minDaysBack: 30,
        automaticallyLoadNextRange: true, // if true and no events are found in current range, check the next one
        pageForwardMainFeed: true, // if true, set the next page, so this will be included in the main feed. Set to false for out-of-turn loads, such as from a program card
        programCardIds: null, // If non-null, only load program events for specific program cards
        ...options,
      };
      const { startDate, endDate } = dateRangeForFullWeeksCoveringRange(myOptions);
      self.loadProgramDataState.setPending({
        trackerId: null,
        startDate,
        endDate,
      });
      try {
        // load corresponding logs
        const { events, cards, nextAt, trackers } = yield getEnv(
          self
        ).logRepository.getProgramCardsAndEvents({
          clientId: self.id,
          startDate,
          endDate: endDate > today ? today : endDate, // hack around API returning future dates
          programCardIds: myOptions.programCardIds,
        });

        if (trackers) {
          // with the new-client-profile the API now returns a trackers object containing
          // any trackers and associated data that appear on the client's feed cards
          // these can be parsed in the logRepository in getProgramCardsAndEvents
          // if available, update appropriate tracker pages based on returned tracker data
          updateTrackers({ store: self, trackers, startDate, endDate });
        } else {
          // even though we will reload program cards if there's no cards available,
          // we still load the latest log data no matter what, because we do all card and log data loading right here,
          // and we ALWAYS need the latest log data for My Data tab, even if we don't need it for cards.
          // It's a little weird, an unfortunate side effect of these two sources needing to be in lockstep
          yield loadLogSlice({ startDate, endDate });
        }

        // always ensure that this load returns at least one program card with logs if they are available
        // this prevents people who only have really old cards from not seeing anything
        if (!events.length && nextAt && myOptions.automaticallyLoadNextRange) {
          self.loadNewestProgramEvents({
            ...myOptions,
            date: nextAt.date,
            automaticallyLoadNextRange: false, // prevent runaway queries in case of bad server data
          });
          return;
        }

        // flush after load and the magic of mobx will make it look like nothing ever went away!
        if (myOptions.flush) {
          self.programCards = [];
          self.programEvents = [];
          self.nextProgramEventDate = null;
        }
        self.programCards = unionBy(cards, self.programCards, c => c.id);
        self.programEvents = unionBy(
          events,
          getDatesOutsideOfRange({
            data: self.programEvents,
            startDate,
            endDate,
            getDateFromRecord: record => record.date,
          }),
          c => c.id
        );
        if (myOptions.pageForwardMainFeed) {
          self.nextProgramEventDate = nextAt ? nextAt.date : null;
        }
        self.loadProgramDataState.setDone();
      } catch (error) {
        console.log(error);
        self.loadProgramDataState.setFailed(error);
      }
    });

    const loadAdditionalProgramEvents = flow(function* loadAdditionalProgramEvents(options) {
      if (!self.nextProgramEventDate) {
        // bit of safety because these could get called rapidly in succession
        return;
      }
      // the only difference between the two loads is that one starts from today, the other from the tail date from the last query
      yield self.loadNewestProgramEvents({
        ...options,
        date: self.nextProgramEventDate,
        automaticallyLoadNextRange: false, // prevent runaway queries in case of bad server data
      });
    });

    const refreshTodaysLogs = flow(function* refreshTodaysLogs() {
      self.loadProgramDataState.setPending();
      try {
        const today = getEnv(self).getToday();
        // TODO: this reloads too much when we pop back from entry screen, need to throttle it
        yield loadLogSlice({ startDate: today, endDate: today });
        self.loadProgramDataState.setDone();
      } catch (error) {
        self.loadProgramDataState.setFailed(error);
      }
    });

    /**
     * Load page of data surrounding date if it was not already loaded recently
     * trackerId may either be a single ID or an array of IDs
     */
    const loadLogsAroundDate = flow(function* loadLogsAroundDate({ date, timeframe, trackerId }) {
      const prospectiveDateRange = self.sliceDateRangeFor({ date, timeframe });
      // prevent loading the future
      if (!prospectiveDateRange) {
        return;
      }
      if (trackerId) {
        // shift back one day if date range starts the day before (for sleep)
        let shiftDayWindow = false;
        if (Array.isArray(trackerId)) {
          trackerId.forEach(id => {
            const tracker = self.trackerFor(id);
            if (tracker.clientMeta.shiftDayWindow) {
              shiftDayWindow = true;
            }
          });
        } else {
          const tracker = self.trackerFor(trackerId);
          shiftDayWindow = tracker.clientMeta.shiftDayWindow;
        }
        if (shiftDayWindow) {
          prospectiveDateRange.startDate = getDateMinusXDays({
            date: prospectiveDateRange.startDate,
            x: 1,
          });
        }
      }
      // check if most recent load contained this same data; if so, don't reload
      if (self.loadProgramDataState.isDone && self.loadProgramDataState.context) {
        if (
          (self.loadProgramDataState.context.trackerId === null /* loaded all */ ||
            isEqual(trackerId, self.loadProgramDataState.context.trackerId)) &&
          prospectiveDateRange.startDate >= self.loadProgramDataState.context.startDate &&
          prospectiveDateRange.endDate <= self.loadProgramDataState.context.endDate
        ) {
          return;
        }
      }
      // set context to prevent a rapid reload of the same data, particularly when choosing a new date
      self.loadProgramDataState.setPending({
        trackerId,
        startDate: prospectiveDateRange.startDate,
        endDate: prospectiveDateRange.endDate,
      });
      try {
        yield loadLogSlice({
          startDate: prospectiveDateRange.startDate,
          endDate: prospectiveDateRange.endDate,
          trackerId,
        });
        self.loadProgramDataState.setDone();
      } catch (error) {
        self.loadProgramDataState.setFailed(error);
      }
    });

    const removeCard = function(card) {
      self.programEvents
        .filter(e => e.programCard.serverId === card.serverId)
        .forEach(e => destroy(e));
      self.programCards.filter(c => c.serverId === card.serverId).forEach(c => destroy(c));
    };

    const removeFromFeed = flow(function*(card, user) {
      self.saveProgramDataState.setPending();
      try {
        yield getEnv(self).logRepository.removeFromFeed(card, user);
        removeCard(card);
        self.saveProgramDataState.setDone();
      } catch (e) {
        self.saveProgramDataState.setFailed(e);
      }
    });

    const resetFeed = flow(function*(user) {
      self.saveProgramDataState.setPending();
      try {
        yield getEnv(self).logRepository.resetFeed(user);
        self.programCards.forEach(c => removeCard(c));
        self.saveProgramDataState.setDone();
      } catch (e) {
        console.log(e);
        self.saveProgramDataState.setFailed(e);
      }
    });

    /**
     * Load a card stack relative to the latest date it was delivered.
     * This is used when accessing a card from a tag, where the card may or may not be loaded with the main feed
     */
    const loadStackHistory = flow(function* loadStackHistory({ relativeToEvent }) {
      // NOTE: we used to abort this load if the event was from today, since presumably that would already be loaded with the feed.
      // THAT'S NOT ALWAYS TRUE. If you access the tag without the feed refreshing first, and a card was added to the tag, it will not
      // be there unless we reload!
      self.loadStackHistoryState.setPending();
      // load events for just the section neede to populate the card
      yield self.loadNewestProgramEvents({
        date: relativeToEvent.date,
        pageForwardMainFeed: false,
        automaticallyLoadNextRange: false,
        programCardIds: [relativeToEvent.programCard.id],
      });
      self.loadStackHistoryState.copyLoadingStateFrom(self.loadProgramDataState);
    });

    return {
      // refresh My Data
      refreshTodaysLogs,
      // Load slices on log entry:
      loadLogsAroundDate,
      // refresh Program tab top items (and filter by tag, eventually (TODO))
      loadNewestProgramEvents,
      loadAdditionalProgramEvents,
      removeFromFeed,
      resetFeed,
      loadStackHistory,
    };
  });

export default ReadOnlyLogStore;
