import { DateTime, Interval } from 'luxon';
import { types } from 'mobx-state-tree';
import ProgramCardAssignment from './ProgramCardAssignment';
import ProgramEvent, { ProgramCardForLogStoreReference } from './ProgramEvent';
import UserProgramAssignment from './UserProgramAssignment';

const Share = types
  .model({
    id: types.maybe(types.identifierNumber),
    cardId: ProgramCardForLogStoreReference,
    startAt: types.maybeNull(types.string),
    endAt: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    stackShareId: types.maybeNull(types.number),
    stackShare: types.maybeNull(ProgramCardAssignment, {}),
    repeatingType: types.maybeNull(types.enumeration(['DAILY', 'WEEKLY'])),
    rank: types.maybeNull(types.number),
    userProgramShareId: types.maybeNull(types.number),
    userProgramShare: types.maybeNull(UserProgramAssignment),
  })
  .views(self => ({
    get isProgramShare() {
      return !!self.userProgramShare;
    },
    get isProgramCardShare() {
      return !!self.stackShare;
    },
    get entityType() {
      return self.stackShare
        ? self.stackShare.entityType
        : self.userProgramShare && self.userProgramShare.share
          ? self.userProgramShare.share.entityType
          : null;
    },
    get entityId() {
      return self.stackShare
        ? self.stackShare.entityId
        : self.userProgramShare && self.userProgramShare.share
          ? self.userProgramShare.share.entityId
          : null;
    },
    get share() {
      return self.stackShare
        ? self.stackShare
        : self.userProgramShare && self.userProgramShare.share
          ? self.userProgramShare.share
          : null;
    },
    get distributor() {
      return self.share.distributor;
    },
    get autoAssign() {
      return self.stackShare
        ? self.stackShare.autoAssign
        : self.userProgramShare && self.userProgramShare.share
          ? self.userProgramShare.share.autoAssign
          : false;
    },
    get assignee() {
      return self.share ? self.share.assignee : null;
    },
    get type() {
      return self.stackShare ? 'card' : 'program';
    },
    get day() {
      return self.userProgramShare
        ? Math.floor(
            Interval.fromDateTimes(
              DateTime.fromSQL(self.userProgramShare.startAt),
              DateTime.local()
            ).length('days') + 1
          )
        : null;
    },
    get hasBegun() {
      return self.userProgramShare
        ? Interval.fromDateTimes(
            DateTime.fromSQL(self.userProgramShare.startAt),
            DateTime.local()
          ).length('days') >= 0
        : false;
    },
    get isCompleted() {
      if (self.isProgramCardShare) {
        return self.stackShare.repeatingType === null
          ? true
          : self.endAt === null
            ? false
            : Interval.fromDateTimes(DateTime.fromSQL(self.endAt), DateTime.local()).length(
                'days'
              ) >= 0;
      } else {
        // Need to compute program share completion by comparing self.day() against program
        // Currently common isn't tracking the program itself but we should implement this long term
        return false;
      }
    },
  }));

const ProgramEventV2 = types
  .model('ProgramEventV2', {
    id: types.identifier,
    // range of dates this item is focused on.
    // usually a single day, but may be an entire week for a weekly goal
    date: types.string,
    share: types.optional(Share, {}),
    showCardOpenedStatus: types.optional(types.boolean, false),
  })
  .views(self => ({
    get day() {
      return self.share && self.share.userProgramShare
        ? Math.floor(
            DateTime.fromSQL(self.date).diff(
              DateTime.fromSQL(self.share.userProgramShare.startAt),
              'days'
            ).days + 1
          )
        : null;
    },
  }));

export default types.compose(
  'ProgramEventV2',
  ProgramEvent,
  ProgramEventV2
);
