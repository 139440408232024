import { reduce, sortBy, last } from 'lodash';
import { jsDateToDateString, dateRangeForInterval } from '../../lib/dates';

/**
 * Utilities for calculating various things about trackers and logs.
 */

function totalForDate({ logs, date }) {
  let runningTotal = 0;
  const logsToTotal = logsForDate({ logs, date });
  logsToTotal.forEach(log => {
    runningTotal = runningTotal + log.primaryValue;
  });
  // can't detect if a single value is NaN without a separate loop
  // hopefully this doesn't break anything
  return {
    total: isNaN(runningTotal) ? 0 : runningTotal,
    numRecords: logsToTotal.length,
  };
}

function totalForWeek({ logs, date }) {
  const { startDate, endDate } = dateRangeForInterval({ date, interval: 'week' });
  let runningTotal = 0;
  const logsToTotal = logs.filter(l => l.date >= startDate && l.date <= endDate);
  logsToTotal.forEach(log => {
    runningTotal = runningTotal + log.primaryValue;
  });
  // can't detect if a single value is NaN without a separate loop
  // hopefully this doesn't break anything
  return {
    total: isNaN(runningTotal) ? 0 : runningTotal,
    numRecords: logsToTotal.length,
  };
}

function latestForDate({ logs, date }) {
  let logsFiltered = logsForDate({ date, logs });
  if (logsFiltered.length > 0) {
    const latest = reduce(
      logsFiltered,
      (latest, log) => (!latest || log.userTime >= latest.userTime ? log : latest),
      null
    );
    if (latest) {
      return latest;
    }
  }
  return null;
}

function latestHistoricalForDate({ logs, date }) {
  // first check today
  const latestLogForDate = latestForDate({ logs, date });
  if (latestLogForDate) {
    return latestLogForDate;
  }
  // next get latest record from a previous date
  const logsBeforeDateSorted = sortBy(
    logs.filter(l => jsDateToDateString(l.userTime) < date),
    l => l.userTime
  );

  if (logsBeforeDateSorted.length) {
    return last(logsBeforeDateSorted);
  }

  return null;
}

function logsForDate({ logs, date }) {
  return logs.filter(l => l.isLoggedOnDate(date));
}

export { totalForDate, latestForDate, logsForDate, latestHistoricalForDate, totalForWeek };
