import { sortBy, uniqBy } from 'lodash';
import { flow, getEnv, types } from 'mobx-state-tree';
import { LoadingState } from '../common';
import FeedCard from './FeedCard';
import ProgramEventV2 from './ProgramEventV2';

const TagPage = types
  .model('TagPage', {
    id: types.identifier,
    isPublic: types.optional(types.boolean, false),
    clientId: types.maybeNull(types.number),
    programCards: types.optional(types.array(FeedCard), []),
    programEvents: types.optional(types.array(ProgramEventV2), []),
    loadTagEventsState: types.optional(LoadingState, {}),
  })
  .views(self => ({
    get eventsForTagSorted() {
      const eventsFiltered = uniqBy(self.programEvents, p => p.programCard.stackId);
      return sortBy(eventsFiltered, p => p.programCard.tagRank);
    },
  }))
  .actions(self => {
    const loadTagEvents = flow(function* loadTagEvents() {
      const today = getEnv(self).getToday();
      self.loadTagEventsState.setPending();
      try {
        const data = yield getEnv(self).logRepository.getCardEventsForTag({
          tag: self.id,
          endDate: today,
          clientId: self.clientId,
          isPublic: self.isPublic,
        });
        self.programCards = data.cards;
        self.programEvents = data.events;
        self.loadTagEventsState.setDone();
      } catch (e) {
        self.loadTagEventsState.setFailed(e);
      }
    });

    return {
      loadTagEvents,
    };
  });

export default TagPage;
