import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex, min } from 'lodash';
import { DateTime } from 'luxon';
import graphStyleDefaults from './graphStyleDefaults';
import TouchTrackerOverlay from './util/TouchTrackerOverlay';
import CommonGraphSpacingProvider from './util/NewCommonGraphSpacingProvider';
import ColumnHighlight from './util/ColumnHighlight';
import YAxisLine from './util/YAxisLine';

function hoursToSeconds(hours) {
  return hours * 3600;
}

function secondsToHours(seconds) {
  return seconds / 3600;
}

// used in y axis formatting below
const startOfTodayDateTime = DateTime.fromJSDate(new Date()).startOf('day');

// change from a series of end seconds/ duration seconds, to start/ end hours
// with a time window corresponding to max(latestEndTime,earliestStartTime) to latestEndTime
function mungeToNonOverlappingIntervals(data) {
  const myData = [];
  let latestEndTime = 0;
  let earliestStartTime = hoursToSeconds(24) - 1; // this shift allows an empty graph to start at midnight

  // step 1: add start duration and find latest end and earliest start
  data.forEach(dataPoint => {
    const valuesForDate = [];
    dataPoint.value.forEach(value => {
      const userDateTime = DateTime.fromSQL(value.endUserTime);
      const end = userDateTime.diff(userDateTime.startOf('day')).as('seconds');
      const start = end - value.duration;
      if (start < earliestStartTime) {
        earliestStartTime = start;
      }
      if (end > latestEndTime) {
        latestEndTime = end;
      }
      valuesForDate.push({
        start,
        end,
        duration: value.duration,
      });
    });
    myData.push({
      date: dataPoint.date,
      value: valuesForDate,
    });
  });

  // step 2: figure out time window
  const endOfWindow = latestEndTime;
  let sizeOfWindow = min([hoursToSeconds(24), latestEndTime - earliestStartTime]);
  if (Math.abs(sizeOfWindow) === hoursToSeconds(24) - 1 /* if never changed because empty */) {
    // set from midnight to midnight for empty graph
    latestEndTime = hoursToSeconds(24) - 1;
    sizeOfWindow = hoursToSeconds(24);
  }
  if (sizeOfWindow > hoursToSeconds(24)) {
    sizeOfWindow = hoursToSeconds(24);
  }

  // extend window and center it if range is too small for at least two y axis lines
  const minimumSizeOfWindow = hoursToSeconds(6);
  if (sizeOfWindow < minimumSizeOfWindow) {
    const diffToMinimumSize = minimumSizeOfWindow - sizeOfWindow;
    sizeOfWindow = sizeOfWindow + diffToMinimumSize;
    latestEndTime = latestEndTime + diffToMinimumSize / 2;
  }

  // figure out minimum size of window
  const startOfWindow = latestEndTime - sizeOfWindow;

  // step 3: normalize start and duration relative to window,
  // mark carryover values
  const carryOverIntervals = [];
  myData.forEach((dataPoint, index) => {
    dataPoint.value.forEach(interval => {
      const newStart = interval.start - startOfWindow;
      const newEnd = interval.end - startOfWindow;
      // if start is still negative, mark for carrying over to previous day
      if (newStart < 0) {
        const remainingDuration = 0 - newStart;
        interval.duration = newEnd;
        carryOverIntervals.push({
          index,
          start: sizeOfWindow - remainingDuration,
          end: endOfWindow,
          duration: remainingDuration,
        });
      }
      // set values now that math is done
      interval.start = newStart < 0 ? 0 : newStart;
      interval.end = newEnd;
    });
  });

  // step 4: apply carry over values
  carryOverIntervals.forEach(interval => {
    if (interval.index >= 1) {
      myData[interval.index - 1].value.push({
        start: interval.start,
        end: interval.end,
        duration: interval.duration,
      });
    }
  });

  const startOfWindowFromMidnight =
    startOfWindow >= 0 ? startOfWindow : hoursToSeconds(24) + startOfWindow;

  return {
    data: myData,
    endOfWindow,
    startOfWindow,
    sizeOfWindow,
    startOfWindowFromMidnight,
  };
}

class DurationGraph extends Component {
  getGraphProps = data => {
    const {
      height,
      width,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
      paddingLeft,
      paddingRight,
      paddingBottom,
      paddingTop,
    } = this.props;

    const {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      dataMarkerStartY,
      barWidth,
      xAxisCaptionY,
    } = CommonGraphSpacingProvider.getCommonSpacingProps({
      numDates: data.length,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      graphWidth: width,
      graphHeight: height,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
    });

    /*let nonNullData = data.filter(
      d => d.value !== null && d.value !== undefined && d.value.length > 0
    );
    let nonNullDataValues = nonNullData.map(g => {
      const lastRecord = maxBy(g.value, o => o.start);
      return lastRecord ? lastRecord.start + lastRecord.duration : 0;
    });

    if (!nonNullDataValues.length) {
      nonNullDataValues.push(hoursToSeconds(24));
    }*/

    //let maxValue = Math.max.apply(null, nonNullDataValues);

    return {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      barWidth,
      //maxValue, // don't trust this because we do the sizeOfWindow math above, that's the real max value relative to start time
      dataMarkerStartY,
      xAxisCaptionY,
    };
  };

  render() {
    const {
      data,
      xAxisLabelType,
      color,
      height,
      width,
      formatDateAsWeekday,
      formatDateAsDayOfMonth,
      axisMarkerFontSize,
      axisMarkerTextColor,
      xAxisMarkerTextColor,
      svg,
      onHoverOverDate,
      onPressDate,
      unselectedDateOpacity,
      selectedDate,
      selectionBoxFillColor,
      selectionBoxStrokeColor,
      selectionBoxCornerRadius,
      paddingLeft,
      paddingRight,
      yAxisLineProps,
      useSkinnyLine,
    } = this.props;

    const { G, Text, Svg, Rect } = svg;

    const {
      data: myData,
      startOfWindowFromMidnight,
      sizeOfWindow,
    } = mungeToNonOverlappingIntervals(data);

    const graphProps = this.getGraphProps(myData);
    const {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      dataMarkerStartY,
      barWidth,
      xAxisCaptionY,
    } = graphProps;

    const fillColor = color;

    const selectedIndex = selectedDate ? findIndex(data, d => d.date === selectedDate) : -1;

    const timeThatDayRollsOver = startOfWindowFromMidnight;

    // y axis props
    const commonTargetLineProps = {
      formatYAxisValue: value => {
        const offsetTime = timeThatDayRollsOver + value;
        return startOfTodayDateTime
          .plus({ seconds: offsetTime })
          .toFormat('ha')
          .toLowerCase();
      },
      width,
      axisMarkerFontSize,
      axisMarkerTextColor,
      svg,
      drawHeight: maxBarHeight,
      minValue: 0,
      tipHeight: 0,
      maxValue: sizeOfWindow,
      fixedLeftOffset: paddingLeft,
      fixedRightOffset: paddingRight,
      highestTarget: sizeOfWindow,
      invertedY: true,
      ...graphProps,
      ...yAxisLineProps,
    };

    const distanceBetweenLines = secondsToHours(sizeOfWindow) > 18 ? 6 : 3;
    const numberOfLines = Math.ceil(secondsToHours(sizeOfWindow) / distanceBetweenLines);
    const lines = [...Array(numberOfLines)].map((x, i) => (
      <YAxisLine
        key={i.toString()}
        target={i * hoursToSeconds(distanceBetweenLines) + (3600 - (timeThatDayRollsOver % 3600))}
        {...commonTargetLineProps}
      />
    ));

    return (
      <Svg height={height} width={width}>
        <G x="0" y={dataMarkerStartY}>
          {lines}
        </G>
        <ColumnHighlight
          svg={svg}
          height={height}
          selectionBoxCornerRadius={selectionBoxCornerRadius}
          selectionBoxFillColor={selectionBoxFillColor}
          selectionBoxStrokeColor={selectionBoxStrokeColor}
          dataMarkerStartX={dataMarkerStartX}
          columnWidth={columnWidth}
          barWidth={barWidth}
          selectedIndex={selectedIndex}
          maxBarHeight={maxBarHeight}
          dataMarkerStartY={dataMarkerStartY}
          showAxisDash={xAxisLabelType === 'none'}
          useSkinnyLine={useSkinnyLine || xAxisLabelType === 'everyXDays'}
          xAxisCaptionY={xAxisCaptionY}
          axisMarkerFontSize={axisMarkerFontSize}
          xAxisMarkerTextColor={xAxisMarkerTextColor}
        />
        {myData.map((dataPoint, index) => {
          // map each day, then the points within the days
          return (
            <G id="Group" x="0" y="0" key={dataPoint.date}>
              <G x={dataMarkerStartX} y={0}>
                <G id="bar" x={index * columnWidth} y="0">
                  {xAxisLabelType === 'weekdays' && (
                    <Text
                      fontSize={axisMarkerFontSize}
                      fill={xAxisMarkerTextColor}
                      fillOpacity={
                        selectedDate && dataPoint.date !== selectedDate ? unselectedDateOpacity : 1
                      }
                      x={columnWidth / 2}
                      y={xAxisCaptionY}
                      weight="bold"
                      textAnchor="middle">
                      {formatDateAsWeekday(dataPoint.date)}
                    </Text>
                  )}
                  {xAxisLabelType === 'everyXDays' && index % 4 === 0 ? (
                    <Text
                      fontSize={axisMarkerFontSize}
                      fill={xAxisMarkerTextColor}
                      fillOpacity={
                        selectedDate && dataPoint.date !== selectedDate ? unselectedDateOpacity : 1
                      }
                      x={columnWidth / 2}
                      y={xAxisCaptionY}
                      weight="bold"
                      textAnchor="middle">
                      {formatDateAsDayOfMonth(dataPoint.date)}
                    </Text>
                  ) : null}
                  {dataPoint.value.map(interval => {
                    // each interval within the day
                    const barHeight = (interval.duration / sizeOfWindow) * maxBarHeight;
                    return (
                      <Rect
                        key={`durationInterval.${dataPoint.date}.${interval.start}`}
                        id={`durationInterval.${dataPoint.date}.${interval.start}`}
                        fill={fillColor}
                        fillOpacity={
                          selectedDate && dataPoint.date !== selectedDate
                            ? unselectedDateOpacity
                            : 1
                        }
                        strokeWidth="1"
                        stroke="rgba(255,255,255,.3)"
                        x={columnWidth / 2 - barWidth / 2}
                        y={
                          dataMarkerStartY +
                          maxBarHeight -
                          maxBarHeight * ((sizeOfWindow - interval.start) / sizeOfWindow)
                        }
                        width={barWidth}
                        height={barHeight}
                        rx={barWidth / 4}
                        ry={barWidth / 4}
                      />
                    );
                  })}
                </G>
              </G>
            </G>
          );
        })}
        {onPressDate || onHoverOverDate ? (
          <TouchTrackerOverlay
            svg={svg}
            height={height}
            width={width}
            selectedTooltipIndex={selectedIndex}
            onHoverOverIndex={
              onHoverOverDate
                ? index => {
                    if (index < data.length) {
                      onHoverOverDate(data[index].date);
                    }
                  }
                : undefined
            }
            onPressIndex={
              onPressDate
                ? index => {
                    if (index < data.length) {
                      onPressDate(data[index].date);
                    }
                  }
                : undefined
            }
            graphStartingXPos={dataMarkerStartX}
            graphDataPointColumnWidth={columnWidth}
            numDataPoints={data.length}
          />
        ) : null}
      </Svg>
    );
  }
}

DurationGraph.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  // color for the bars
  color: PropTypes.string.isRequired,
  xAxisLabelType: PropTypes.oneOf(['weekdays', 'everyXDays', 'none']),
  // number that represents the top target for the graph
  greenTarget: PropTypes.number,
  // number that represents the middle target for the graph
  // if neither yellow nor green targets are specified,
  // the graph will add some "midpoint" lines
  // Note that the graph does not currently color the targets yellow or green!
  yellowTarget: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ).isRequired,
  // SVG wants absolute dimensions
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  // axis colors/ sizes
  axisMarkerTextColor: PropTypes.string,
  axisMarkerFontSize: PropTypes.number,
  axisMarkerDescenderHeight: PropTypes.number,
  // selection colors/ sizes
  // reduce opacity for unselected dates
  unselectedDateOpacity: PropTypes.number,
  selectionBoxFillColor: PropTypes.string,
  selectionBoxStrokeColor: PropTypes.string,
  selectionBoxHorizontalPadding: PropTypes.number,
  selectionBoxCornerRadius: PropTypes.number,
  // ???
  formatYAxisValue: PropTypes.func,
  // override date values
  formatDateAsWeekday: PropTypes.func,
  formatDateAsDayOfMonth: PropTypes.func,
  // spacing at edges of graph
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  // *** toggle display modes ***
  // called with date when user drags/ hovers over date
  onHoverOverDate: PropTypes.func,
  // called with date when user presses date
  onPressDate: PropTypes.func,
  // date for which to show tooltip
  selectedDate: PropTypes.string,
  // if true, show midpoint lines when there are no yellow/ green targets
  showMidpointLines: PropTypes.bool,
  useSkinnyLine: PropTypes.bool,
};

DurationGraph.defaultProps = {
  greenTarget: 0,
  yellowTarget: 0,

  formatDateAsWeekday: graphStyleDefaults.formatDateAsWeekday,
  formatDateAsDayOfMonth: graphStyleDefaults.formatDateAsDayOfMonth,
  xAxisLabelType: 'none',
  paddingLeft: 36,
  paddingRight: 16,
  paddingBottom: 16,
  paddingTop: 16,
  axisMarkerFontSize: graphStyleDefaults.axisMarkerFontSize,
  axisMarkerDescenderHeight: graphStyleDefaults.axisMarkerDescenderHeight,
  axisMarkerTextColor: graphStyleDefaults.axisMarkerTextColor,
  // *** toggle display modes ***
  // called with date when user drags/ hovers over date
  onHoverOverDate: () => {},
  // called with date when user presses date
  onPressDate: null,
  // reduce opacity for unselected dates
  unselectedDateOpacity: 1,
  // date for which to show tooltip
  selectedDate: null,
  showMidpointLines: false,
  selectionBoxFillColor: 'white',
  selectionBoxStrokeColor: graphStyleDefaults.axisMarkerTextColor,
  selectionBoxCornerRadius: 5,
  selectionBoxHorizontalPadding: 16,
  useSkinnyLine: false,
};

export default DurationGraph;
