import React from 'react';

export default class DayDots extends React.Component {
  render() {
    const {
      data,
      colorMapper,
      trackerColor,
      formatDateAsWeekday,
      axisMarkerFontSize,
      axisMarkerTextColor,
      firstDotX,
      columnWidth,
      dotDiameter,
      xAxisCaptionY,
      svg,
      unselectedDateOpacity,
      selectedIndex,
      formatDateAsDayOfMonth,
      maxBarHeight,
      xAxisLabelType,
      dataMarkerStartY,
    } = this.props;

    const { G, Text, Rect } = svg;

    const getDotProps = (value, index) => {
      if (value.value) {
        return {
          fill: colorMapper
            ? colorMapper(value.value)
            : value.value
              ? trackerColor
              : axisMarkerTextColor,
          x: columnWidth / 2 - dotDiameter / 2,
          y: dataMarkerStartY + maxBarHeight - dotDiameter,
          fillOpacity: index === selectedIndex ? 1 : unselectedDateOpacity,
          width: dotDiameter,
          height: dotDiameter,
          rx: dotDiameter / 2,
          ry: dotDiameter / 2,
        };
      }

      return {
        fill: 'none',
        strokeWidth: 1,
        stroke: colorMapper
          ? colorMapper(value.value)
          : value.value
            ? trackerColor
            : axisMarkerTextColor,
        x: columnWidth / 2 - dotDiameter / 2 + 0.5,
        y: dataMarkerStartY + maxBarHeight - dotDiameter + 0.5,
        width: dotDiameter - 1,
        height: dotDiameter - 1,
        rx: (dotDiameter - 1) / 2,
        ry: (dotDiameter - 1) / 2,
      };
    };

    return (
      <G x="0" y="0">
        {data.map((value, index) => (
          <G id="Group" x="0" y="0" key={value.date}>
            <G x={firstDotX} y="0">
              <G id="bar" x={index * columnWidth} y="0">
                {xAxisLabelType === 'weekdays' ? (
                  <Text
                    fontSize={axisMarkerFontSize}
                    fill={axisMarkerTextColor}
                    x={columnWidth / 2}
                    y={xAxisCaptionY}
                    weight="bold"
                    textAnchor="middle">
                    {formatDateAsWeekday(value.date)}
                  </Text>
                ) : null}
                {xAxisLabelType === 'everyXDays' && index % 4 === 0 ? (
                  <Text
                    fontSize={axisMarkerFontSize}
                    fill={axisMarkerTextColor}
                    x={columnWidth / 2}
                    y={xAxisCaptionY}
                    weight="bold"
                    textAnchor="middle">
                    {formatDateAsDayOfMonth(value.date)}
                  </Text>
                ) : null}
                <Rect id="Rectangle-2" {...getDotProps(value, index)} />
              </G>
            </G>
          </G>
        ))}
      </G>
    );
  }
}
