import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import graphStyleDefaults from './graphStyleDefaults';
import YAxisLine from './util/YAxisLine';
import TouchTrackerOverlay from './util/TouchTrackerOverlay';
import CommonGraphSpacingProvider from './util/NewCommonGraphSpacingProvider';
import ColumnHighlight from './util/ColumnHighlight';

class MinMaxBarGraph extends Component {
  getGraphProps = () => {
    const {
      data,
      height,
      width,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
      paddingLeft,
      paddingRight,
      paddingBottom,
      paddingTop,
    } = this.props;

    const {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      dataMarkerStartY,
      barWidth,
      xAxisCaptionY,
    } = CommonGraphSpacingProvider.getCommonSpacingProps({
      numDates: data.length,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      graphWidth: width,
      graphHeight: height,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
    });

    let maxValue = Math.max.apply(null, data.filter(g => g.value).map(g => g.value.max));
    // don't want lines to show up when there's no values
    if (Math.abs(maxValue) === Infinity) {
      maxValue = 0;
    }
    let minValue = Math.min.apply(null, data.filter(g => g.value).map(g => g.value.min));
    if (Math.abs(minValue) === Infinity) {
      minValue = 0;
    }

    // increase the graph min/ max if values are too tight together
    let topBottomBuffer = maxValue * 1.05 - maxValue;
    if ((maxValue - minValue) * 2 >= topBottomBuffer) {
      topBottomBuffer = 0;
    }
    maxValue = maxValue + topBottomBuffer;
    minValue = minValue - topBottomBuffer;
    if (minValue < 0) {
      minValue = 0;
    }

    return {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      barWidth,
      maxValue,
      dataMarkerStartY,
      xAxisCaptionY,
      minValue,
    };
  };

  render() {
    const {
      data,
      xAxisLabelType,
      color,
      height,
      width,
      formatYAxisValue,
      formatDateAsWeekday,
      formatDateAsDayOfMonth,
      axisMarkerFontSize,
      axisMarkerTextColor,
      svg,
      includesAverage,
      minMaxColorForAverageMode,
      onHoverOverDate,
      onPressDate,
      unselectedDateOpacity,
      selectedDate,
      selectionBoxCornerRadius,
      selectionBoxFillColor,
      selectionBoxStrokeColor,
      yAxisLineProps,
      xAxisMarkerTextColor,
      paddingLeft,
      paddingRight,
      useSkinnyLine,
    } = this.props;

    const { G, Text, Svg, Rect } = svg;

    const graphProps = this.getGraphProps();
    const {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      barWidth,
      maxValue,
      minValue,
      dataMarkerStartY,
      xAxisCaptionY,
    } = graphProps;

    const fillColor = color;

    const commonTargetLineProps = {
      formatYAxisValue,
      width,
      axisMarkerFontSize,
      axisMarkerTextColor,
      svg,
      xAxisLabelType,
      drawHeight: maxBarHeight,
      tipHeight: 0,
      fixedLeftOffset: paddingLeft,
      fixedRightOffset: paddingRight,
      ...graphProps,
      ...yAxisLineProps,
    };

    const getBarHeight = dataPoint => {
      const suggestedHeight =
        ((dataPoint.value.max - dataPoint.value.min) / (maxValue - minValue)) * maxBarHeight;

      // make any single point with same min and max a circle
      if (suggestedHeight < barWidth) {
        return barWidth;
      }
      return suggestedHeight;
    };

    const selectedIndex = selectedDate ? findIndex(data, d => d.date === selectedDate) : -1;

    return (
      <Svg height={height} width={width}>
        <G x="0" y={dataMarkerStartY}>
          <YAxisLine target={minValue} highestTarget={maxValue} {...commonTargetLineProps} />
          <YAxisLine target={maxValue} highestTarget={maxValue} {...commonTargetLineProps} />
        </G>
        <ColumnHighlight
          svg={svg}
          height={height}
          selectionBoxCornerRadius={selectionBoxCornerRadius}
          selectionBoxFillColor={selectionBoxFillColor}
          selectionBoxStrokeColor={selectionBoxStrokeColor}
          dataMarkerStartX={dataMarkerStartX}
          columnWidth={columnWidth}
          barWidth={barWidth}
          selectedIndex={selectedIndex}
          maxBarHeight={maxBarHeight}
          dataMarkerStartY={dataMarkerStartY}
          showAxisDash={xAxisLabelType === 'none'}
          useSkinnyLine={useSkinnyLine || xAxisLabelType === 'everyXDays'}
          xAxisCaptionY={xAxisCaptionY}
          axisMarkerFontSize={axisMarkerFontSize}
          xAxisMarkerTextColor={xAxisMarkerTextColor}
        />
        {data.map((dataPoint, index) => (
          <G id="Group" x="0" y="0" key={dataPoint.date}>
            <G x={dataMarkerStartX} y="0">
              <G id="bar" x={index * columnWidth} y="0">
                {xAxisLabelType === 'weekdays' && (
                  <Text
                    fontSize={axisMarkerFontSize}
                    fill={axisMarkerTextColor}
                    x={columnWidth / 2}
                    y={xAxisCaptionY}
                    weight="bold"
                    textAnchor="middle">
                    {formatDateAsWeekday(dataPoint.date)}
                  </Text>
                )}
                {xAxisLabelType === 'everyXDays' && index % 4 === 0 ? (
                  <Text
                    fontSize={axisMarkerFontSize}
                    fill={axisMarkerTextColor}
                    x={columnWidth / 2}
                    y={xAxisCaptionY}
                    weight="bold"
                    textAnchor="middle">
                    {formatDateAsDayOfMonth(dataPoint.date)}
                  </Text>
                ) : null}
                {/* bar for min/max */ dataPoint.value &&
                dataPoint.value.min !== dataPoint.value.max ? (
                  /* don't draw bar for null values */ <Rect
                    id="Rectangle-2"
                    fill={includesAverage ? minMaxColorForAverageMode : fillColor}
                    strokeWidth="1"
                    stroke="rgba(255,255,255,.3)"
                    x={columnWidth / 2 - barWidth / (includesAverage ? 4 : 2)}
                    y={
                      dataMarkerStartY +
                      (maxBarHeight -
                        ((dataPoint.value.max - minValue) / (maxValue - minValue)) * maxBarHeight)
                    }
                    width={barWidth / (includesAverage ? 2 : 1)}
                    height={getBarHeight(dataPoint)}
                    rx={barWidth / (includesAverage ? 4 : 2)}
                    ry={barWidth / (includesAverage ? 4 : 2)}
                    fillOpacity={
                      selectedDate && dataPoint.date !== selectedDate ? unselectedDateOpacity : 1
                    }
                  />
                ) : null}
                {/* point for single value */ dataPoint.value &&
                dataPoint.value.min === dataPoint.value.max &&
                !dataPoint.value.average ? (
                  /* don't draw bar for null values */ <Rect
                    id="Rectangle-2"
                    fill={fillColor}
                    strokeWidth="1"
                    stroke="rgba(255,255,255,.3)"
                    x={columnWidth / 2 - barWidth / 2}
                    y={
                      dataMarkerStartY +
                      (maxBarHeight -
                        ((dataPoint.value.max - minValue) / (maxValue - minValue)) * maxBarHeight) +
                      barWidth / -2
                    }
                    width={barWidth}
                    height={barWidth}
                    rx={barWidth / 2}
                    ry={barWidth / 2}
                    fillOpacity={
                      selectedDate && dataPoint.date !== selectedDate ? unselectedDateOpacity : 1
                    }
                  />
                ) : null}
              </G>
            </G>
          </G>
        ))}
        {onPressDate || onHoverOverDate ? (
          <TouchTrackerOverlay
            svg={svg}
            height={height}
            width={width}
            selectedTooltipIndex={selectedIndex}
            onHoverOverIndex={
              onHoverOverDate
                ? index => {
                    if (index < data.length) {
                      onHoverOverDate(data[index].date);
                    }
                  }
                : undefined
            }
            onPressIndex={
              onPressDate
                ? index => {
                    if (index < data.length) {
                      onPressDate(data[index].date);
                    }
                  }
                : undefined
            }
            graphStartingXPos={dataMarkerStartX}
            graphDataPointColumnWidth={columnWidth}
            numDataPoints={data.length}
          />
        ) : null}
      </Svg>
    );
  }
}

MinMaxBarGraph.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  // color for the bars
  color: PropTypes.string.isRequired,
  xAxisLabelType: PropTypes.oneOf(['weekdays', 'everyXDays', 'none']),
  // if true, may include an average value to show in the middle of of min/ max
  includesAverage: PropTypes.bool,
  // use the primary color average, then this color for the range when includesAverage is true
  minMaxColorForAverageMode: PropTypes.string,
  // number that represents the top target for the graph
  // If value is null, then there is no entry for that date
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
    })
  ).isRequired,
  // SVG wants absolute dimensions
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  formatYAxisValue: PropTypes.func,
  // override date values
  formatDateAsWeekday: PropTypes.func,
  formatDateAsDayOfMonth: PropTypes.func,
  axisMarkerFontSize: PropTypes.number,
  axisMarkerDescenderHeight: PropTypes.number,
  axisMarkerTextColor: PropTypes.string,
  // spacing props
  minTooltipPlusStemHeight: PropTypes.number,
  // minimum spacing between left edge of graph and start of bars
  // prevents overlap of bars and y axis lines in tight spaces
  graphLeftMinOffset: PropTypes.number,
  // new tooltip stuff
  // *** toggle display modes ***
  // called with date when user drags/ hovers over date
  onHoverOverDate: PropTypes.func,
  // called with date when user presses date
  onPressDate: PropTypes.func,
  // reduce opacity for unselected dates
  unselectedDateOpacity: PropTypes.number,
  selectedDate: PropTypes.string,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  selectionBoxFillColor: PropTypes.string,
  selectionBoxStrokeColor: PropTypes.string,
  selectionBoxHorizontalPadding: PropTypes.number,
  selectionBoxCornerRadius: PropTypes.number,
  useSkinnyLine: PropTypes.bool,
};

MinMaxBarGraph.defaultProps = {
  minMaxColorForAverageMode: 'gray',
  formatYAxisValue: graphStyleDefaults.formatYAxisValue,
  formatDateAsWeekday: graphStyleDefaults.formatDateAsWeekday,
  formatDateAsDayOfMonth: graphStyleDefaults.formatDateAsDayOfMonth,
  axisMarkerFontSize: graphStyleDefaults.axisMarkerFontSize,
  axisMarkerDescenderHeight: graphStyleDefaults.axisMarkerDescenderHeight,
  axisMarkerTextColor: graphStyleDefaults.axisMarkerTextColor,
  minTooltipPlusStemHeight: graphStyleDefaults.minTooltipPlusStemHeight,
  graphLeftMinOffset: graphStyleDefaults.barGraphLeftMinOffset,
  xAxisLabelType: 'none',
  // called with date when user drags/ hovers over date
  onHoverOverDate: () => {},
  // called with date when user presses date
  onPressDate: () => {},
  // reduce opacity for unselected dates
  unselectedDateOpacity: 1,
  // date for which to show tooltip
  selectedDate: null,
  selectionBoxFillColor: 'white',
  selectionBoxStrokeColor: graphStyleDefaults.axisMarkerTextColor,
  selectionBoxCornerRadius: 5,
  selectionBoxHorizontalPadding: 16,
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 16,
  paddingTop: 16,
  useSkinnyLine: false,
};

export default MinMaxBarGraph;
