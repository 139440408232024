import React, { Component } from 'react';
import { findIndex, find } from 'lodash';
import PropTypes from 'prop-types';
import graphStyleDefaults from './graphStyleDefaults';
import TouchTrackerOverlay from './util/TouchTrackerOverlay';
import CommonGraphSpacingProvider from './util/NewCommonGraphSpacingProvider';
import DayDots from './util/DayDots';
import ColumnHighlight from './util/ColumnHighlight';

class FreeformQuestionTrackerGraph extends Component {
  render() {
    const {
      data,
      trackerColor,
      height,
      width,
      formatDateAsWeekday,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
      axisMarkerTextColor,
      svg,
      formatDateAsDayOfMonth,
      xAxisLabelType,
      selectedDate,
      onHoverOverDate,
      onPressDate,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      selectionBoxFillColor,
      selectionBoxCornerRadius,
      selectionBoxStrokeColor,
      unselectedDateOpacity,
      xAxisMarkerTextColor,
      useSkinnyLine,
    } = this.props;

    const { Svg } = svg;

    const {
      columnWidth,
      dataMarkerStartX,
      dataMarkerLowestY,
      barWidth,
      maxBarHeight,
      dataMarkerStartY,
      xAxisCaptionY,
    } = CommonGraphSpacingProvider.getCommonSpacingProps({
      numDates: data.length,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      graphWidth: width,
      graphHeight: height,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
    });

    const selectedIndex = selectedDate ? findIndex(data, d => d.date === selectedDate) : -1;

    return (
      <Svg height={height} width={width}>
        <ColumnHighlight
          svg={svg}
          height={height}
          selectionBoxCornerRadius={selectionBoxCornerRadius}
          selectionBoxStrokeColor={selectionBoxStrokeColor}
          selectionBoxFillColor={selectionBoxFillColor}
          dataMarkerStartX={dataMarkerStartX}
          columnWidth={columnWidth}
          barWidth={barWidth}
          selectedIndex={selectedIndex}
          maxBarHeight={maxBarHeight}
          dataMarkerStartY={dataMarkerStartY}
          showAxisDash={xAxisLabelType === 'none'}
          useSkinnyLine={useSkinnyLine || xAxisLabelType === 'everyXDays'}
          xAxisCaptionY={xAxisCaptionY}
          axisMarkerFontSize={axisMarkerFontSize}
          xAxisMarkerTextColor={xAxisMarkerTextColor}
        />
        <DayDots
          svg={svg}
          data={data}
          dataMarkerLowestY={dataMarkerLowestY}
          dataMarkerStartY={dataMarkerStartY}
          dotDiameter={barWidth}
          columnWidth={columnWidth}
          trackerColor={trackerColor}
          axisMarkerFontSize={axisMarkerFontSize}
          axisMarkerTextColor={axisMarkerTextColor}
          firstDotX={dataMarkerStartX}
          formatDateAsWeekday={formatDateAsWeekday}
          xAxisCaptionY={xAxisCaptionY}
          unselectedDateOpacity={unselectedDateOpacity}
          selectedIndex={selectedIndex}
          xAxisLabelType={xAxisLabelType}
          maxBarHeight={maxBarHeight}
          formatDateAsDayOfMonth={formatDateAsDayOfMonth}
        />
        {onPressDate || onHoverOverDate ? (
          <TouchTrackerOverlay
            svg={svg}
            height={height}
            width={width}
            selectedTooltipIndex={selectedIndex}
            onHoverOverIndex={
              onHoverOverDate
                ? index => {
                    if (index < data.length) {
                      onHoverOverDate(data[index].date);
                    }
                  }
                : undefined
            }
            onPressIndex={
              onPressDate
                ? index => {
                    if (index < data.length) {
                      onPressDate(data[index].date);
                    }
                  }
                : undefined
            }
            graphStartingXPos={dataMarkerStartX}
            graphDataPointColumnWidth={columnWidth}
            numDataPoints={data.length}
          />
        ) : null}
      </Svg>
    );
  }
}

FreeformQuestionTrackerGraph.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  // color for the tracker
  // for a freeform answer, this color will be the only one used.
  // for multiple choice, this color will be used to generate lighter/ darker shades for different canned choices
  trackerColor: PropTypes.string.isRequired,
  // number that represents the top target for the graph
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ).isRequired,
  // SVG wants absolute dimensions
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  // override how to display any numerical values we show
  // Takes the value and returns the value as a number or string
  // This is applied to values and targets, so if you have values that
  // are seconds but you're formatting them as minutes, your values and targets should be seconds
  // override date values
  formatDateAsWeekday: PropTypes.func,
  formatDateAsDayOfMonth: PropTypes.func,
  formatDateAsShortDate: PropTypes.func,
  axisMarkerFontSize: PropTypes.number,
  axisMarkerDescenderHeight: PropTypes.number,
  axisMarkerTextColor: PropTypes.string,
  xAxisLabelType: PropTypes.oneOf(['weekdays', 'everyXDays', 'none']),
  timelineBarHeight: PropTypes.number,
  // called with date when user drags/ hovers over date
  onHoverOverDate: PropTypes.func,
  // called with date when user presses date
  onPressDate: PropTypes.func,
  // *** toggle display modes ***
  // reduce opacity for unselected dates
  unselectedDateOpacity: PropTypes.number,
  selectedDate: PropTypes.string,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  selectionBoxFillColor: PropTypes.string,
  selectionBoxStrokeColor: PropTypes.string,
  selectionBoxHorizontalPadding: PropTypes.number,
  selectionBoxCornerRadius: PropTypes.number,
  useSkinnyLine: PropTypes.bool,
};

FreeformQuestionTrackerGraph.defaultProps = {
  formatDateAsDayOfMonth: graphStyleDefaults.formatDateAsDayOfMonth,
  formatDateAsShortDate: graphStyleDefaults.formatDateAsShortDate,
  formatDateAsWeekday: graphStyleDefaults.formatDateAsWeekday,
  axisMarkerFontSize: graphStyleDefaults.axisMarkerFontSize,
  axisMarkerDescenderHeight: graphStyleDefaults.axisMarkerDescenderHeight,
  axisMarkerTextColor: graphStyleDefaults.axisMarkerTextColor,
  timelineBarHeight: graphStyleDefaults.questionGraphTimelineHeight,
  xAxisLabelType: 'none',
  // called with date when user presses date
  onPressDate: null,
  onHoverOverDate: () => {},
  // reduce opacity for unselected dates
  unselectedDateOpacity: 1,
  // date for which to show tooltip
  selectedDate: null,
  selectionBoxFillColor: 'white',
  selectionBoxStrokeColor: graphStyleDefaults.axisMarkerTextColor,
  selectionBoxCornerRadius: 5,
  selectionBoxHorizontalPadding: 16,
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 16,
  paddingTop: 16,
  useSkinnyLine: false,
};

export default FreeformQuestionTrackerGraph;
