import { NativeModules, NativeEventEmitter } from 'react-native';

const { ValidicBluetooth } = NativeModules;

const ValidicBluetoothEvents = {
  onBluetoothPeripheralDidPair: 'ValidicOnBluetoothPeripheralDidPair',
  onBluetoothPassiveDidFail: 'ValidicBluetoothOnPassiveDidFail',
  onBluetoothPeripheralDidNotPair: 'ValidicOnBluetoothPeripheralDidNotPair',
  onVerboseLog: 'ValidicOnLogVerbose',
};

export { ValidicBluetooth, NativeEventEmitter, ValidicBluetoothEvents };
