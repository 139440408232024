import numeral from 'numeral';
import { Duration } from 'luxon';
import { unitsForValueAndUom } from '../lib/strings';
import formatLogValueForDisplay from './formatLogValueForDisplay';

const secondsToHoursAndMinutes = seconds => {
  const timeString = Duration.fromMillis(seconds * 1000).toFormat('h:m');
  const timeStringParts = timeString.split(':');
  return {
    hours: timeStringParts[0],
    minutes: timeStringParts[1],
  };
};

/**
 * Format a single tracker aggregate total or individual log value into separate display components
 */
function buildTrackerComponents({
  tracker,
  value,
  fieldAbbreviations,
  usePreferredTimeUnit = false,
  useMetricUnits = false,
  useLongFormForUnits = false,
}) {
  // TODO: make this common

  const summaryValue = value;
  let components = [];

  // normalize primary fields/ values into array of objects
  if (typeof summaryValue === 'number' /* total or single number value */) {
    if (
      tracker.clientMeta.primaryField === 'duration' ||
      tracker.clientMeta.primaryField === 'hiDuration'
    ) {
      // for goals- a nice condensed format
      if (usePreferredTimeUnit) {
        const preferredTimeUnit =
          tracker.clientMeta.preferredTimeUnit === 'userDefined'
            ? tracker.primaryFieldUnits() /* minutes or hours used for custom duration */
            : tracker.clientMeta.preferredTimeUnit;
        if (preferredTimeUnit === 'hours') {
          components.push({
            field: 'hours',
            value: numeral(summaryValue / 3600).format('0,0[.]0'),
            units: unitsForValueAndUom(summaryValue / 3600, preferredTimeUnit),
          });
        } else {
          components.push({
            field: 'minutes',
            value: numeral(summaryValue / 60).format('0,0[.]0'),
            units: unitsForValueAndUom(summaryValue / 3600, preferredTimeUnit),
          });
        }
      } else {
        const timeParts = secondsToHoursAndMinutes(summaryValue);
        if (timeParts.hours && timeParts.hours !== '0' /* oops these are strings */) {
          components.push({
            field: 'hours',
            value: timeParts.hours,
            units: 'h',
          });
        }
        if (timeParts.minutes && timeParts.minutes !== '0') {
          components.push({
            field: 'minutes',
            value: timeParts.minutes,
            units: 'm',
          });
        }
      }
    } else {
      components.push({
        field: tracker.primaryField,
        value: summaryValue
          ? formatLogValueForDisplay({
              value: summaryValue,
              fieldName: tracker.primaryField,
              useMetricUnits,
              decimalPlaces: tracker.clientMeta.decimalPlaces,
            })
          : 0,
        units: unitsForValueAndUom(
          summaryValue,
          tracker.primaryFieldUnits({ useMetricUnits }),
          useLongFormForUnits
        ),
      });
    }
    // could be latest of a single field or multiple filelds
  } else if (typeof summaryValue === 'object') {
    // get primary fields and add them as components
    let primaryFields = tracker.primaryField;
    // single primary field
    if (typeof primaryFields === 'string') {
      primaryFields = [primaryFields];
    }
    components = primaryFields.map(primaryField => ({
      field: primaryField,
      value: summaryValue
        ? formatLogValueForDisplay({
            value: summaryValue[primaryField],
            fieldName: primaryField,
            useMetricUnits,
            decimalPlaces: tracker.clientMeta.decimalPlaces,
          })
        : null,
      units:
        primaryFields.length === 1
          ? unitsForValueAndUom(
              summaryValue ? summaryValue : null,
              tracker.primaryFieldUnits({ useMetricUnits }),
              useLongFormForUnits
            )
          : primaryField === 'response'
            ? null
            : fieldAbbreviations[primaryField.toUpperCase()],
    }));

    // used by heart rate to remove min/max/avg or heart rate if they're not available
    // if down to a single field, just use bpm instead of field name
    if (tracker.clientMeta.hideEmptyFields) {
      components = components.filter(
        c => c.value /* unfilled values seem to be null lately*/ && c.value !== '0'
      );

      if (components.length === 1) {
        components[0].units = tracker.clientMeta.primaryFieldUom;
      }
    }
  }

  return components;
}

export default buildTrackerComponents;
