import React, { Component } from 'react';
import { findLastIndex } from 'lodash';
import PropTypes from 'prop-types';
import graphStyleDefaults from './graphStyleDefaults';
import ComplianceCircleInner from './util/ComplianceCircleInner';

class ComplianceCircle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // if not fixed, show latest point by default, then change on future hover
      index: findLastIndex(this.props.data, d => d.value),
    };
  }

  render() {
    const {
      date,
      diameter,
      svg,
      captionFontSize,
      captionTextColor,
      completedColor,
      notCompletedColor,
      formatDateAsDayOfMonth,
      formatDateAsWeekday,
      textFontWeight,
      segments,
      showColors,
      spaceBetweenTopCaptionAndCircle,
      showDate,
    } = this.props;

    const { Svg, G, Text } = svg;

    return (
      <Svg
        height={diameter + (showDate ? spaceBetweenTopCaptionAndCircle + captionFontSize : 0)}
        width={diameter}>
        {showDate ? (
          <G>
            <Text
              fontSize={captionFontSize}
              x={diameter / 2}
              y={captionFontSize / 2}
              fill={captionTextColor}
              textAnchor="middle"
              fontWeight={textFontWeight}
              alignmentBaseline="middle"
              dominantBaseline="middle">
              {formatDateAsWeekday(date)}
            </Text>
          </G>
        ) : null}
        <G x={0} y={showDate ? captionFontSize + spaceBetweenTopCaptionAndCircle : 0}>
          <G x={diameter / 2} y={diameter / 2}>
            <ComplianceCircleInner
              middleText={showDate ? formatDateAsDayOfMonth(date) : ''}
              showColors={showColors}
              segments={segments}
              svg={svg}
              diameter={diameter}
              completedColor={completedColor}
              notCompletedColor={notCompletedColor}
              circleStrokeWidth={3}
              degreesSeparationBetweenSegments={4}
              captionFontSize={captionFontSize}
              captionTextColor={captionTextColor}
              textFontWeight={textFontWeight}
              spaceBetweenTopCaptionAndCircle={spaceBetweenTopCaptionAndCircle}
            />
          </G>
        </G>
      </Svg>
    );
  }
}

ComplianceCircle.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  captionFontSize: PropTypes.number,
  captionTextColor: PropTypes.string,
  completedColor: PropTypes.string,
  notCompletedColor: PropTypes.string,
  formatDateAsWeekday: PropTypes.func,
  formatDateAsDayOfMonth: PropTypes.func,
  textFontWeight: PropTypes.string,
  // segments to display in the order they should be displayed
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string, // only needed if showColors is true
      isCompleted: PropTypes.bool,
    })
  ).isRequired,
  // if true, show the actual colors of the tracker segments instead of grayscale
  showColors: PropTypes.bool,
  spaceBetweenTopCaptionAndCircle: PropTypes.number,
  // show the day of week above and the date number in the middle of the circle
  showDate: PropTypes.bool,
};

ComplianceCircle.defaultProps = {
  captionFontSize: graphStyleDefaults.toolTipUnitsFontSize,
  captionTextColor: graphStyleDefaults.axisMarkerTextColor,
  completedColor: '#807F80',
  notCompletedColor: '#CFCFCF',
  formatDateAsWeekday: graphStyleDefaults.formatDateAsWeekday,
  formatDateAsDayOfMonth: graphStyleDefaults.formatDateAsDayOfMonth,
  textFontWeight: 'normal',
  showColors: false,
  spaceBetweenTopCaptionAndCircle: 0,
  showDate: true,
};

export default ComplianceCircle;
