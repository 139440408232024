import { types, getParent } from 'mobx-state-tree';
import { find } from 'lodash';
import Tracker from './Tracker';
import ProgramCardComponentBase from './internal/ProgramCardComponentBase';

// A custom reference for trackers that will always resolve to the "nearest" tracker.
// what happens with the default reference is if we have multiple ReadOnlyLogStores (for, say, multiple clients),
// it'll detect an ambiguious reference if they both have the same tracker.
// An alternate solution to this would be to use a different identifier for trackers on a log store,
// since those are really "UserTrackers" and not just trackers in general
// Another alternate solution would be some kind of injectable thing
const TrackerForLogStoreReference = types.maybeNull(
  types.reference(Tracker, {
    // given an identifier, find the user
    get(identifier /* string */, parent /*Store*/) {
      const logStore = getParent(parent, 4); // I hate this version of getParent because it depends on depth
      // however, we're pretty tightly-bound to this structure, so it's unlikely the parent with the relevant trackers would move
      if (logStore) {
        const tracker = find(logStore.trackers, t => t.id == identifier);
        return tracker || null;
      }
    },
    // given a tracker, produce the identifier that should be stored
    set(value /* Tracker */) {
      return value.id;
    },
  })
);

const FeedPart = types.model('FeedPart', {
  trackerId: TrackerForLogStoreReference,
});

const FeedCardComponent = types.compose(
  'FeedCardComponent',
  ProgramCardComponentBase,
  FeedPart
);

export default FeedCardComponent;
