import { groupBy, map, sortBy, unionBy, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { flow, getEnv, types } from 'mobx-state-tree';
import { dateRangeForFullWeeksCoveringRange } from '../../lib/dates';
import { getDatesOutsideOfRange } from '../common';
import ReadOnlyLogStore, { updateTrackers } from './ReadOnlyLogStore';

const apiDateFormat = 'yyyy-MM-dd'; // TODO: refactor this out

const ActivityPart = types
  .model('ActivityPart', {
    // Track the page we've most recently loaded so we can identify which log data points are on the page
    // and, critically, which log data points are most recent before the current page/
    // We use this info to determine the start date for the next page
    currentPage: types.optional(
      types.model({
        startDate: types.maybeNull(types.string),
        endDate: types.maybeNull(types.string),
      }),
      {}
    ),
  })
  .views(self => ({
    //Sort program events, then pluck out tracker-type components with logged data from any cards that have them
    get programLogsSorted() {
      return sortBy(self.programEvents.slice(), a => a.sortKey)
        .reverse()
        .map(g => ({
          date: g.date,
          components: g.programCard.components.filter(
            c =>
              c.type === 'tracker' &&
              c.hasTracker &&
              c.tracker.isValueEnteredForDate(g.headerInfo.date)
          ),
        }));
    },
    // Group the tracker-type components by date
    get logsGroupedByDate() {
      return map(
        groupBy(self.programLogsSorted.filter(g => g.components.length), g => g.date),
        (g, k) => ({
          date: k,
          components: uniqBy(g.map(c => c.components).flatten(), c => c.activityFeedUniquenessKey),
        })
      );
    },
    get logsBeforeCurrentPage() {
      const startDate = DateTime.fromSQL(self.currentPage.startDate);
      return self.trackers
        .map(t => t.user.logs.filter(l => DateTime.fromSQL(l.userTime) < startDate))
        .flatten();
    },
    get logsOnCurrentPages() {
      const startDate = DateTime.fromSQL(self.currentPage.startDate);
      return self.trackers
        .map(t => t.user.logs.filter(l => DateTime.fromSQL(l.userTime) > startDate))
        .flatten();
    },
    // Since we fetched all tracker data with HEAD (last record before the requested start_date), if there are any logs before the current page they indicate the presence of earlier data
    get canLoadAdditionalProgramEvents() {
      return self.logsBeforeCurrentPage.length > 0;
    },
    // When we called the API we fetched HEADs for all log data - any logs which fell before the current page
    // indicate the most recent log BEFORE the current page
    // Therefore, this is the next date we want to page back to
    get nextDateToLoad() {
      const logs = self.logsBeforeCurrentPage;
      return logs.length
        ? DateTime.max
            .apply(null, logs.map(l => DateTime.fromSQL(l.userTime)))
            .toFormat(apiDateFormat)
        : null;
    },
  }))
  .actions(self => {
    //Public Functions
    /**
     * Call this as-is with no options to load latest events and corresponding log data.
     * Will load a previous range that's at least 30 days long (expanding to fit whole calendar weeks for
     * the sake of calculating weekly goals)
     * Adjust options.date to load a pervious 30+ days.
     * Thus, this isn't really "newest" events (but not ready to change the name yet)
     */
    const loadNewestProgramEvents = flow(function* loadNewestProgramEvents(options) {
      const today = getEnv(self).getToday();
      const myOptions = {
        date: today, // date from which to start loading next events, move back to load previous events
        flush: false, // if true
        minDaysBack: 7,
        automaticallyLoadNextRange: true, // if true and no events are found in current range, check the next one
        ...options,
      };
      const { startDate, endDate } = dateRangeForFullWeeksCoveringRange(myOptions);
      self.loadProgramDataState.setPending({
        trackerId: null,
        startDate,
        endDate,
      });
      try {
        // load corresponding logs
        const { events, cards, trackers } = yield getEnv(
          self
        ).logRepository.getProgramCardsAndEvents({
          clientId: self.id,
          startDate,
          endDate: endDate > today ? today : endDate, // hack around API returning future dates
          pageByLogDate: true,
        });

        self.currentPage = { startDate, endDate };

        // with the new-client-profile the API now returns a trackers object containing
        // any trackers and associated data that appear on the client's feed cards
        // these can be parsed in the logRepository in getProgramCardsAndEvents
        // if available, update appropriate tracker pages based on returned tracker data
        if (trackers) updateTrackers({ store: self, trackers, startDate, endDate });

        // always ensure that this load returns at least one program card with logs if they are available
        // this prevents people who only have really old cards from not seeing anything
        // if there aren't any logs on the current page then we don't have any data to show - go ahead and load the next page
        if (
          self.logsOnCurrentPages.length === 0 &&
          self.canLoadAdditionalProgramEvents &&
          myOptions.automaticallyLoadNextRange
        ) {
          self.loadNewestProgramEvents({
            ...myOptions,
            date: self.nextDateToLoad,
            automaticallyLoadNextRange: false, // prevent runaway queries in case of bad server data
          });
          return;
        }

        // flush after load and the magic of mobx will make it look like nothing ever went away!
        if (myOptions.flush) {
          self.programCards = [];
          self.programEvents = [];
          self.nextProgramEventDate = null;
        }
        self.programCards = unionBy(cards, self.programCards, c => c.id);
        self.programEvents = unionBy(
          events,
          getDatesOutsideOfRange({
            data: self.programEvents,
            startDate,
            endDate,
            getDateFromRecord: record => record.date,
          }),
          c => c.id
        );
        self.loadProgramDataState.setDone();
      } catch (error) {
        console.log(error);
        self.loadProgramDataState.setFailed(error);
      }
    });

    // This functions very similarly to loadAdditionalProgramEvents in ReadOnlyLogStore
    // EXCEPT we use nextDateToLoad, which is derived by looking at the HEADs of the log data
    const loadAdditionalProgramEvents = flow(function* loadAdditionalProgramEvents(options) {
      if (!self.nextDateToLoad) {
        // bit of safety because these could get called rapidly in succession
        return;
      }
      // the only difference between the two loads is that one starts from today, the other from the tail date from the last query
      yield self.loadNewestProgramEvents({
        ...options,
        date: self.nextDateToLoad,
        automaticallyLoadNextRange: false, // prevent runaway queries in case of bad server data
      });
    });

    return {
      loadNewestProgramEvents,
      loadAdditionalProgramEvents,
    };
  });

const ActivityStore = types.compose(
  'ActivityStore',
  ReadOnlyLogStore,
  ActivityPart
);

export default ActivityStore;
