const Colors = {
  RED: 'rgb(255, 76, 80)',
  YELLOW: 'rgb(254, 223, 99)',
  LIGHT_YELLOW: 'rgb(239,236,207)',
  GREEN: 'rgb(14, 149, 43)',
  ORANGE: 'rgb(255, 119, 68)',
  LIGHT_GREEN: 'rgb(200,233,202)',
  BLACK: 'rgb(0, 0, 0)',
  WHITE: 'rgb(255, 255, 255)',
  FROSTED: 'rgba(255, 255, 255, .4)',
  GREY: 'rgb(208,208,208)',
  LIGHT_GREY: 'rgb(224,225,225)',
  LIGHT_TEXT: 'rgb(143,142,147)',
  DARK_TEXT: 'rgb(34,35,38)',
  BLUE: 'rgb(89,131,240)',
};

const EntityTypes = {
  USER: 'user',
  GROUP: 'club',
  COMPANY: 'company',
  COACH: 'coach',
};

export { Colors, EntityTypes };