import { DateTime } from 'luxon';
import { reduce, sortBy, last } from 'lodash';
import { jsDateToDateString, getDateMinusXDays } from '../../lib/dates';

/**
 * Indicates if the time component of user_time is not relevant because the activity represents
 * a daily total instead of a particular point or duration of time
 * @param {Object} log log DTO from API
 * @return {boolean} true if the log is an untimed activity, false if otherwise
 */
const isUntimedActivity = log => {
  // in v4, we've often appended these props to the object but may run them through the catalog again for more info
  // (e.g., for graphs)
  if (log.isUntimedActivity) {
    return true;
  }
  if (log.type === 'pedometer') {
    return true;
  }
  // cheesy detector for aggregate heart rate
  if (log.avgHeartRate || log.avg_heart_rate) {
    const time = getTimeForLog(log);
    return (
      time.getHours() === 0 &&
      time.getMinutes() === 0 &&
      time.getSeconds() === 0 &&
      time.getMilliseconds() === 0
    );
  }
  return false;
};

// derives primary value from primary field, which can be an array in the case of blood pressure
const getPrimaryFieldValue = ({ log, primaryField }) => {
  if (Array.isArray(primaryField)) {
    const primaryValueObj = {};
    primaryField.forEach(field => {
      primaryValueObj[field] = log[field];
    });
    return primaryValueObj;
  }
  if (primaryField) {
    return log[primaryField];
  }
  return null;
};

const isTimedActivity = log => {
  // in v4, we've often appended these props to the object but may run them through the catalog again for more info
  // (e.g., for graphs)
  if (log.isTimedActivity) {
    return true;
  }
  if (log.type === 'active') {
    return true;
  }
  // heart rate, weight, body fat, blood glucose
  const time = getTimeForLog(log);
  return (
    time.getHours() !== 0 ||
    time.getMinutes() !== 0 ||
    time.getSeconds() !== 0 ||
    time.getMilliseconds() !== 0
  );
};

const isSynced = log => {
  // in v4, we've often appended these props to the object but may run them through the catalog again for more info
  // (e.g., for graphs)
  if (log.isSynced) {
    return true;
  }
  const effectiveSource = getEffectiveSource(log);
  return effectiveSource && effectiveSource.toUpperCase() !== 'NUDGE';
};

/**
 * Gets custom units for the tracker (if defined)
 * @param {Object} tracker tracker DTO from API
 * @return {string} gets custom units if available, null if otherwise
 */
const getCustomUnits = tracker => {
  return tracker.meta.config ? tracker.meta.config.units : null;
};

/**
 * Turns userTime SQL timestamp to JS Date
 */
const userTimeToJsDate = userTime => {
  if (userTime instanceof Date) {
    return userTime;
  }
  return DateTime.fromSQL(userTime).toJSDate();
};

/**
 * Gets the JS Date representation for the user time for a log
 * @param {*} log log DTO from API
 * @return {Date} local JS Date for log entry
 */
const getTimeForLog = log => {
  /* don't try to convert a moment object- these are more frequent in Coach Web */
  if (log.userTime && !log.userTime._isAMomentObject) {
    return userTimeToJsDate(log.userTime);
  }
  if (log.user_time) {
    return userTimeToJsDate(log.user_time);
  }
};

/**
 * Returns total for a field for a particular date, given an array of logs
 *
 * @param {Object} param (destructured params)
 * @param {string} param.logs - array of log DTO's from API. May contain multiple dates.
 * @param {string} param.date - The date to return the total for
 * @param {string} param.primaryField - the field to total
 * @param {func} param.isDuplicate - (optional) if function returns true, log is duplicate and should not be counted in total
 * Takes a function that is passed the log as a parameter. By default, function always returns false
 * @return {number} the total of the values of primary field
 */
const totalForDate = ({ logs, date, primaryField, isDuplicate = () => false }) => {
  return totalsForDate({ logs, date, fieldsToTotal: [primaryField], isDuplicate })[primaryField];
};

/**
 * Returns totals for field one or more fields for a particular date, given an array of logs
 * Currently only used for calories
 *
 * @param {Object} param (destructured params)
 * @param {string} param.logs - array of log DTO's from API. May contain multiple dates.
 * @param {string} param.date - The date to return the total for
 * @param {Array} param.fieldsToTotal - array of field names to total
 * @param {func} param.isDuplicate - (optional) if function returns true, log is duplicate and should not be counted in total
 * Takes a function that is passed the log as a parameter. By default, function always returns false
 * @return {object} map of each field and its total
 */
const totalsForDate = ({ logs, date, fieldsToTotal, isDuplicate = () => false }) => {
  let runningTotals = {};
  fieldsToTotal.forEach(field => {
    runningTotals[field] = 0;
  });
  logsForDate({ logs, date }).forEach(log => {
    fieldsToTotal.forEach(field => {
      if (!isDuplicate(log)) {
        runningTotals[field] = runningTotals[field] + log[field];
        if (isNaN(runningTotals[field])) {
          runningTotals[field] = 0;
        }
      }
    });
  });

  return runningTotals;
};

/**
 * We separate the reducer so it can be used in getLogCatalog
 */
const latestLogReducer = (latest, nextLog) =>
  // if there is no latest
  // or if the new log is an untimed activity
  // or if the latest is not untimed and the new log is newer,
  // replace latest with the new log
  // This way, we get latest log, except untimed will override all
  !latest ||
  // give apple records first priority by not writing over them with future untimed records
  // give daily aggregate logs priority over workout logs
  //
  // conditions for using the next log instead of latest:
  // a) if latest is timed and next log is untimed, use next log
  (isTimedActivity(latest) && isUntimedActivity(nextLog)) ||
  // b) if next log is untimed and from apple, use next log
  (isUntimedActivity(nextLog) && getEffectiveSource(nextLog) === 'Apple') ||
  // c) if latest is timed and the next log has a more recent time, use next log
  (isTimedActivity(latest) && getTimeForLog(nextLog) >= getTimeForLog(latest))
    ? nextLog
    : latest;

/**
 * Returns the latest log for the date
 *
 * @param {Object} param (destructured params)
 * @param {string} param.logs - array of log DTO's from API. May contain multiple dates.
 * @param {string} param.date - The date to return the total for
 * @return {Object} the log that was the latest for the day
 */
const latestForDate = ({ logs, date }) => {
  let logsFiltered = logsForDate({ date, logs });
  if (logsFiltered.length > 0) {
    const latest = reduce(logsFiltered, latestLogReducer, null);
    if (latest) {
      return latest;
    }
  }
  return null;
};

/**
 * For a date, get a list of trackers and whether the user has entered at least one value for that day
 */
const complianceForDate = ({ trackers, date }) => {
  const complianceSummaries = [];
  trackers.forEach(tracker => {
    complianceSummaries.push({
      trackerId: tracker.id,
      completed: !!latestForDate({ logs: tracker.user.logs, date }),
      rank: tracker.user.settings.rank,
      // for convenience, since we usually organize by color
      palettesId: tracker.palettesId,
    });
  });
  return complianceSummaries;
};

function latestHistoricalForDate({ logs, date }) {
  // first check today
  const latestLogForDate = latestForDate({ logs, date });
  if (latestLogForDate) {
    return latestLogForDate;
  }
  // next get latest record from a previous date
  const logsBeforeDateSorted = sortBy(
    logs.filter(l => DateTime.fromJSDate(getTimeForLog(l)) < DateTime.fromSQL(date)),
    l => getTimeForLog(l)
  );

  if (logsBeforeDateSorted.length) {
    return last(logsBeforeDateSorted);
  }

  return null;
}

const logsForDate = ({ logs, date }) => {
  const comparisonDateTime = DateTime.fromSQL(date);
  return logs.filter(l => {
    const logDateTime = DateTime.fromJSDate(getTimeForLog(l));
    return logDateTime.hasSame(comparisonDateTime, 'day');
  });
};

/**
 * Used for calculations/ formatting of related records, like for showing a parent child relationship
 */
const getEffectiveSource = log => {
  if (log.via && log.via.startsWith('Apple')) {
    return 'Apple';
  }
  return log.source;
};

const capitalizeFirstLetter = text => {
  if (text && text.length) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return text;
};

function sourceToDisplaySource(source) {
  if (!source) {
    return 'Nudge';
  }
  if (source.toLowerCase() === 'googlefit') {
    return 'Google Fit';
  }
  if (source.toLowerCase() === 'garmin_connect') {
    return 'Garmin Connect';
  }
  if (source.toLowerCase() === 'connect') {
    return 'Garmin Connect';
  }
  if (source.toLowerCase() === 'apple_health') {
    return 'Apple Health';
  }
  return capitalizeFirstLetter(source);
}

function logGroupingDate({ log, shiftDayWindow = false, timeWindowStart = '00:00:00' }) {
  if (!shiftDayWindow) {
    return jsDateToDateString(getTimeForLog(log));
  }
  const jsLogTime = getTimeForLog(log);
  const logDate = jsDateToDateString(jsLogTime);
  const latestTimeForCurrentDay = DateTime.fromSQL(`${logDate} ${timeWindowStart}`);
  const logDateTime = DateTime.fromJSDate(jsLogTime);
  // later than 6 pm on day of - put on next day
  if (logDateTime >= latestTimeForCurrentDay) {
    return getDateMinusXDays({ date: logDate, x: 1 });
  }
  // otherwise, keep on same day
  return logDate;
}

export {
  logsForDate,
  latestForDate,
  complianceForDate,
  latestHistoricalForDate,
  totalsForDate,
  getTimeForLog,
  isUntimedActivity,
  getCustomUnits,
  isSynced,
  isTimedActivity,
  totalForDate,
  getEffectiveSource,
  sourceToDisplaySource,
  getPrimaryFieldValue,
  latestLogReducer,
  logGroupingDate,
};
