/**
        String constants representing HealthKit sample types.
        @enum {string}
     */
    const HKSampleTypes = {
      HKQuantityTypeIdentifierBodyMassIndex: "HKQuantityTypeIdentifierBodyMassIndex",
      HKQuantityTypeIdentifierBodyFatPercentage: "HKQuantityTypeIdentifierBodyFatPercentage",
      HKQuantityTypeIdentifierHeight: "HKQuantityTypeIdentifierHeight",
      HKQuantityTypeIdentifierBodyMass: "HKQuantityTypeIdentifierBodyMass",
      HKQuantityTypeIdentifierLeanBodyMass: "HKQuantityTypeIdentifierLeanBodyMass",
      HKQuantityTypeIdentifierStepCount: "HKQuantityTypeIdentifierStepCount",
      HKQuantityTypeIdentifierDistanceWalkingRunning: "HKQuantityTypeIdentifierDistanceWalkingRunning",
      HKQuantityTypeIdentifierDistanceCycling: "HKQuantityTypeIdentifierDistanceCycling",
      HKQuantityTypeIdentifierBasalEnergyBurned: "HKQuantityTypeIdentifierBasalEnergyBurned",
      HKQuantityTypeIdentifierActiveEnergyBurned: "HKQuantityTypeIdentifierActiveEnergyBurned",
      HKQuantityTypeIdentifierFlightsClimbed: "HKQuantityTypeIdentifierFlightsClimbed",
      HKQuantityTypeIdentifierNikeFuel: "HKQuantityTypeIdentifierNikeFuel",
      HKQuantityTypeIdentifierAppleExerciseTime: "HKQuantityTypeIdentifierAppleExerciseTime",
      HKQuantityTypeIdentifierHeartRate: "HKQuantityTypeIdentifierHeartRate",
      HKQuantityTypeIdentifierBodyTemperature: "HKQuantityTypeIdentifierBodyTemperature",
      HKQuantityTypeIdentifierBasalBodyTemperature: "HKQuantityTypeIdentifierBasalBodyTemperature",
      HKQuantityTypeIdentifierBloodPressureSystolic: "HKQuantityTypeIdentifierBloodPressureSystolic",
      HKQuantityTypeIdentifierBloodPressureDiastolic: "HKQuantityTypeIdentifierBloodPressureDiastolic",
      HKQuantityTypeIdentifierRespiratoryRate: "HKQuantityTypeIdentifierRespiratoryRate",
      HKQuantityTypeIdentifierOxygenSaturation: "HKQuantityTypeIdentifierOxygenSaturation",
      HKQuantityTypeIdentifierPeripheralPerfusionIndex: "HKQuantityTypeIdentifierPeripheralPerfusionIndex",
      HKQuantityTypeIdentifierBloodGlucose: "HKQuantityTypeIdentifierBloodGlucose",
      HKQuantityTypeIdentifierNumberOfTimesFallen: "HKQuantityTypeIdentifierNumberOfTimesFallen",
      HKQuantityTypeIdentifierElectrodermalActivity: "HKQuantityTypeIdentifierElectrodermalActivity",
      HKQuantityTypeIdentifierInhalerUsage: "HKQuantityTypeIdentifierInhalerUsage",
      HKQuantityTypeIdentifierBloodAlcoholContent: "HKQuantityTypeIdentifierBloodAlcoholContent",
      HKQuantityTypeIdentifierForcedVitalCapacity: "HKQuantityTypeIdentifierForcedVitalCapacity",
      HKQuantityTypeIdentifierForcedExpiratoryVolume1: "HKQuantityTypeIdentifierForcedExpiratoryVolume1",
      HKQuantityTypeIdentifierPeakExpiratoryFlowRate: "HKQuantityTypeIdentifierPeakExpiratoryFlowRate",
      HKQuantityTypeIdentifierDietaryFatTotal: "HKQuantityTypeIdentifierDietaryFatTotal",
      HKQuantityTypeIdentifierDietaryFatPolyunsaturated: "HKQuantityTypeIdentifierDietaryFatPolyunsaturated",
      HKQuantityTypeIdentifierDietaryFatMonounsaturated: "HKQuantityTypeIdentifierDietaryFatMonounsaturated",
      HKQuantityTypeIdentifierDietaryFatSaturated: "HKQuantityTypeIdentifierDietaryFatSaturated",
      HKQuantityTypeIdentifierDietaryCholesterol: "HKQuantityTypeIdentifierDietaryCholesterol",
      HKQuantityTypeIdentifierDietarySodium: "HKQuantityTypeIdentifierDietarySodium",
      HKQuantityTypeIdentifierDietaryCarbohydrates: "HKQuantityTypeIdentifierDietaryCarbohydrates",
      HKQuantityTypeIdentifierDietaryFiber: "HKQuantityTypeIdentifierDietaryFiber",
      HKQuantityTypeIdentifierDietarySugar: "HKQuantityTypeIdentifierDietarySugar",
      HKQuantityTypeIdentifierDietaryEnergyConsumed: "HKQuantityTypeIdentifierDietaryEnergyConsumed",
      HKQuantityTypeIdentifierDietaryProtein: "HKQuantityTypeIdentifierDietaryProtein",
      HKQuantityTypeIdentifierDietaryVitaminA: "HKQuantityTypeIdentifierDietaryVitaminA",
      HKQuantityTypeIdentifierDietaryVitaminB6: "HKQuantityTypeIdentifierDietaryVitaminB6",
      HKQuantityTypeIdentifierDietaryVitaminB12: "HKQuantityTypeIdentifierDietaryVitaminB12",
      HKQuantityTypeIdentifierDietaryVitaminC: "HKQuantityTypeIdentifierDietaryVitaminC",
      HKQuantityTypeIdentifierDietaryVitaminD: "HKQuantityTypeIdentifierDietaryVitaminD",
      HKQuantityTypeIdentifierDietaryVitaminE: "HKQuantityTypeIdentifierDietaryVitaminE",
      HKQuantityTypeIdentifierDietaryVitaminK: "HKQuantityTypeIdentifierDietaryVitaminK",
      HKQuantityTypeIdentifierDietaryCalcium: "HKQuantityTypeIdentifierDietaryCalcium",
      HKQuantityTypeIdentifierDietaryIron: "HKQuantityTypeIdentifierDietaryIron",
      HKQuantityTypeIdentifierDietaryThiamin: "HKQuantityTypeIdentifierDietaryThiamin",
      HKQuantityTypeIdentifierDietaryRiboflavin: "HKQuantityTypeIdentifierDietaryRiboflavin",
      HKQuantityTypeIdentifierDietaryNiacin: "HKQuantityTypeIdentifierDietaryNiacin",
      HKQuantityTypeIdentifierDietaryFolate: "HKQuantityTypeIdentifierDietaryFolate",
      HKQuantityTypeIdentifierDietaryBiotin: "HKQuantityTypeIdentifierDietaryBiotin",
      HKQuantityTypeIdentifierDietaryPantothenicAcid: "HKQuantityTypeIdentifierDietaryPantothenicAcid",
      HKQuantityTypeIdentifierDietaryPhosphorus: "HKQuantityTypeIdentifierDietaryPhosphorus",
      HKQuantityTypeIdentifierDietaryIodine: "HKQuantityTypeIdentifierDietaryIodine",
      HKQuantityTypeIdentifierDietaryMagnesium: "HKQuantityTypeIdentifierDietaryMagnesium",
      HKQuantityTypeIdentifierDietaryZinc: "HKQuantityTypeIdentifierDietaryZinc",
      HKQuantityTypeIdentifierDietarySelenium: "HKQuantityTypeIdentifierDietarySelenium",
      HKQuantityTypeIdentifierDietaryCopper: "HKQuantityTypeIdentifierDietaryCopper",
      HKQuantityTypeIdentifierDietaryManganese: "HKQuantityTypeIdentifierDietaryManganese",
      HKQuantityTypeIdentifierDietaryChromium: "HKQuantityTypeIdentifierDietaryChromium",
      HKQuantityTypeIdentifierDietaryMolybdenum: "HKQuantityTypeIdentifierDietaryMolybdenum",
      HKQuantityTypeIdentifierDietaryChloride: "HKQuantityTypeIdentifierDietaryChloride",
      HKQuantityTypeIdentifierDietaryPotassium: "HKQuantityTypeIdentifierDietaryPotassium",
      HKQuantityTypeIdentifierDietaryCaffeine: "HKQuantityTypeIdentifierDietaryCaffeine",
      HKQuantityTypeIdentifierDietaryWater: "HKQuantityTypeIdentifierDietaryWater",
      HKQuantityTypeIdentifierUVExposure: "HKQuantityTypeIdentifierUVExposure",
      HKCategoryTypeIdentifierSleepAnalysis: "HKCategoryTypeIdentifierSleepAnalysis",
      HKCategoryTypeIdentifierAppleStandHour: "HKCategoryTypeIdentifierAppleStandHour",
      HKCategoryTypeIdentifierCervicalMucusQuality: "HKCategoryTypeIdentifierCervicalMucusQuality",
      HKCategoryTypeIdentifierOvulationTestResult: "HKCategoryTypeIdentifierOvulationTestResult",
      HKCategoryTypeIdentifierMenstrualFlow: "HKCategoryTypeIdentifierMenstrualFlow",
      HKCategoryTypeIdentifierIntermenstrualBleeding: "HKCategoryTypeIdentifierIntermenstrualBleeding",
      HKCategoryTypeIdentifierSexualActivity: "HKCategoryTypeIdentifierSexualActivity",
      HKQuantityTypeIdentifierPushCount: "HKQuantityTypeIdentifierPushCount",
      HKQuantityTypeIdentifierDistanceWheelchair: "HKQuantityTypeIdentifierDistanceWheelchair",
      HKCategoryTypeIdentifierMindfulSession: "HKCategoryTypeIdentifierMindfulSession",
      HKQuantityTypeIdentifierSwimmingStrokeCount: "HKQuantityTypeIdentifierSwimmingStrokeCount",
      HKQuantityTypeIdentifierDistanceSwimming: "HKQuantityTypeIdentifierDistanceSwimming",
      HKCorrelationTypeIdentifierBloodPressure: "HKCorrelationTypeIdentifierBloodPressure",
      HKCorrelationTypeIdentifierFood: "HKCorrelationTypeIdentifierFood",
      HKWorkoutTypeIdentifier: "HKWorkoutTypeIdentifier"
  };

  export default HKSampleTypes;