import { types, isValidReference, isAlive } from 'mobx-state-tree';
import { v4 as uuid } from 'uuid';
import { imageMetadataToAttribution, resolveCardImage } from '../imageUtils';

const ProgramCardComponentBase = types
  .model('ProgramCardComponentBase', {
    id: types.optional(types.identifier, () => uuid()),
    serverId: types.maybeNull(types.number),
    rank: types.optional(types.number, 0),
    // unsplash image, or video metadata
    meta: types.frozen(),
    // custom image upload
    upload: types.frozen(),
    // possible values 'headline','text','tracker','button','video','image','video-youtube','video-vimeo','join-group-button','pdf',
    // no longer an enumeration so we don't crash an app while adding new values
    type: types.string,
    // former body fields
    url: types.maybeNull(types.string),
    body: types.maybeNull(types.string),
    placeholder: types.maybeNull(types.string),
    goalThreshold: types.maybeNull(types.number),
    goalType: types.maybeNull(types.enumeration(['TOTAL', 'TIMES'])),
    goalInterval: types.maybeNull(types.enumeration(['WEEKLY', 'DAILY', 'MONTHLY'])),
    clubId: types.maybeNull(types.number),
  })
  .views(self => ({
    get image() {
      return resolveCardImage({ uploadedImage: self.upload, catalogImage: self.meta });
    },
    get hasTracker() {
      return !!self.tracker;
    },
    get hasGoal() {
      return (
        self.hasTracker &&
        !!self.goalType &&
        !!self.goalInterval &&
        !!self.goalThreshold &&
        self.tracker.clientMeta.supportsGoals
      );
    },
    // makes accessing the reference a little more semantic
    get tracker() {
      if (isAlive(self) && isValidReference(() => self.trackerId)) {
        return self.trackerId;
      }
      return null;
    },
    // returns object with { id, authorName, imageUrl, authorUrl } if component is an image requiring attribution
    get imageAttribution() {
      return self.type === 'image' ? imageMetadataToAttribution(self.meta) : null;
    },
    get activityFeedUniquenessKey() {
      if (!self.hasTracker) return self.id;
      return `${self.tracker.id}${self.goalInterval &&
        '|' + self.goalInterval}${self.goalThreshold && '|' + self.goalThreshold}`;
    },
  }));

export default ProgramCardComponentBase;
