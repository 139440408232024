import React from 'react';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

// This should probably be moved into the ComplianceCircle component

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function startAngForSegIndex(segments, index) {
  if (!segments) {
    return 0;
  }
  if (index === 0) return 0;
  let value = 0;
  for (let i = 0; i < index; i++) {
    value += segments[i].percent;
  }
  return value * 360;
}

function endAngForSegIndex(segments, index) {
  if (!segments) {
    return 0;
  }
  if (index === 0) return segments[0].percent * 360;
  let value = 0;
  for (let i = 0; i <= index; i++) {
    value += segments[i].percent;
  }
  return value * 360;
}

function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
    ' '
  );

  return d;
}

export default class ComplianceCircleInner extends React.Component {
  render() {
    const {
      middleText,
      svg,
      completedColor,
      notCompletedColor,
      diameter,
      circleStrokeWidth,
      degreesSeparationBetweenSegments,
      captionFontSize,
      captionTextColor,
      textFontWeight,
      segments,
      showColors,
    } = this.props;

    const { G, Path, Circle, Text } = svg;

    const radius = diameter / 2 - circleStrokeWidth;

    // segments to draw as part of the pie
    // sort by completed (actually by not completed since it seems to go in opposite order)
    // so the circle can show a percentage of completion rather than just a bunch of funny colors
    const segmentsFinal = [];

    for (let i = 0; i < segments.length; i++) {
      segmentsFinal.push({
        color: segments[i].isCompleted
          ? showColors
            ? segments[i].color
            : completedColor
          : notCompletedColor,
        percent: 1 / segments.length,
      });
    }

    // mobile requires alignmentBaseline, but technically it should be dominantBaseline, and Firefox demands that

    return (
      <G>
        <G>
          <Text
            fontSize={captionFontSize}
            x={0}
            y={1}
            fill={captionTextColor}
            textAnchor="middle"
            fontWeight={textFontWeight}
            alignmentBaseline="middle"
            dominantBaseline="middle">
            {middleText}
          </Text>
        </G>
        {segmentsFinal.map(
          (segment, index) =>
            segment.percent < 1 && segment.percent > 0 ? (
              <G key={index.toString()}>
                <Path
                  stroke={segment.color}
                  strokeWidth={circleStrokeWidth}
                  fill="none"
                  d={describeArc(
                    0,
                    0,
                    radius,
                    startAngForSegIndex(segmentsFinal, index) +
                      degreesSeparationBetweenSegments / 2,
                    endAngForSegIndex(segmentsFinal, index) - degreesSeparationBetweenSegments / 2
                  )}
                />
              </G>
            ) : (
              /* takes up whole pie */ <G key={index.toString()}>
                <Circle
                  stroke={segment.color}
                  strokeWidth={circleStrokeWidth}
                  fill="none"
                  cx="0"
                  cy="0"
                  r={radius}
                />
              </G>
            )
        )}
      </G>
    );
  }
}
