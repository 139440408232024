import { types, applySnapshot, getSnapshot } from 'mobx-state-tree';
import FormError from './FormError';

const stateValues = ['pending', 'done', 'error', 'notStarted'];

const LoadingState = types
  .model('LoadingState', {
    status: types.optional(types.enumeration(stateValues), 'notStarted'),
    error: types.frozen(),
    // used to quickly identify particular types of errors. These are appended
    // when the error is created and cleared when the transaction is restarted
    formErrors: types.optional(types.array(FormError), []),
    // if last status update to pending cleared an error, this is true.
    // Used to suggest that we may be trying to recover from an error.
    isInFirstPendingStateSinceError: types.optional(types.boolean, false),
    firstLoadCompleted: types.optional(types.boolean, false),
    // if true, operation had a cancel requested but the process performing the operation
    // has not acked it yet.
    isCancelRequested: types.optional(types.boolean, false),
    // variable for storing information about the current query to indicate why it is pending
    context: types.frozen(),
  })
  .views(self => ({
    get isNetworkError() {
      // TODO
      return false;
    },
    get isPendingFirstLoad() {
      return !self.firstLoadCompleted;
    },
    get isPending() {
      return self.status === 'pending';
    },
    get isFailed() {
      return self.status === 'error';
    },
    get isDone() {
      return self.status === 'done';
    },
    get isNotStarted() {
      return self.status === 'notStarted';
    },
  }))
  .actions(self => {
    const clearError = () => {
      self.error = null;
      self.formErrors.clear();
    };

    const setDone = () => {
      self.status = 'done';
      self.firstLoadCompleted = true;
      self.isCancelRequested = false;
      self.clearError();
      self.isInFirstPendingStateSinceError = false;
    };

    const setPending = context => {
      if (self.status === 'error') {
        self.isInFirstPendingStateSinceError = true;
      } else {
        self.isInFirstPendingStateSinceError = false;
      }
      self.isCancelRequested = false;
      self.status = 'pending';
      self.context = context;
      self.clearError();
    };

    const setFailed = (error, formErrors) => {
      self.isCancelRequested = false;
      self.status = 'error';
      self.clearError();
      self.error = error;
      self.formErrors = formErrors;
      self.isInFirstPendingStateSinceError = false;
    };

    const reset = () => {
      self.context = null;
      self.status = 'notStarted';
      self.clearError();
      self.isCancelRequested = false;
      self.isInFirstPendingStateSinceError = false;
      self.firstLoadCompleted = false;
    };

    // if an operation is pending, set it to cancel requested
    // up to whoever is modifying this status to respect cancellation
    const requestCancel = () => {
      if (self.isPending) {
        self.isCancelRequested = true;
      }
    };

    // When wrapping an async function with another, you may want to base the success or failure of the outer async function
    // on what happened on the inner function. That way, you can fail the outer if the inner fails
    const copyLoadingStateFrom = (source, options) => {
      if (options && options.copyOnFailureOnly && source.isFailed) {
        applySnapshot(self, getSnapshot(source));
        return;
      }
      applySnapshot(self, getSnapshot(source));
    };

    return {
      setDone,
      setFailed,
      setPending,
      reset,
      requestCancel,
      clearError,
      copyLoadingStateFrom,
    };
  });

export default LoadingState;
