import { types } from 'mobx-state-tree';
import { v4 as uuid } from 'uuid';
import Distributor from './Distributor';

const diffInDays = startAt => Math.floor((new Date() - startAt) / 86400000);
const dateString = d => `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear() % 1000}`;

const ProgramAssignment = types
  .model({
    id: types.optional(types.identifier, uuid()),
    serverId: types.maybeNull(types.number),
    companyId: types.maybeNull(types.number),
    entityId: types.maybeNull(types.union(types.number, types.string)),
    entityType: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    photoUrl: types.maybeNull(types.string),
    startAt: types.optional(types.Date, () => new Date()),
    endAt: types.maybeNull(types.Date),
    model: types.maybeNull(types.enumeration(['rolling', 'cohort'])),
    programId: types.maybeNull(types.number),
    distributor: types.maybeNull(Distributor),
  })
  .actions(self => ({
    setStartAt: s => (self.startAt = s),
    setEndAt: s => (self.endAt = s),
    setEntity: e => {
      self.entityId = e.id;
      self.entityType = e.type;
      self.firstName = e.firstName;
      self.lastName = e.lastName;
      self.title = e.title;
      self.photoUrl = e.photoUrl;
      self.model = e.model;
      self.startAt = e.startAt;
    },
  }))
  .views(self => ({
    get isNew() {
      return self.serverId === null;
    },
    get modelString() {
      return self.entityType === 'group'
        ? self.model === 'cohort'
          ? 'Cohort '
          : 'Open Enrollment '
        : '';
    },
    get assignedString() {
      const modelString = self.modelString;
      return diffInDays(self.startAt) >= 0
        ? `${modelString}on Day ${diffInDays(self.startAt) + 1}`
        : `${modelString}starting ${dateString(self.startAt)}`;
    },
    get hasBegun() {
      return diffInDays(self.startAt) >= 0;
    },
    get day() {
      return diffInDays(self.startAt) + 1;
    },
    get startDate() {
      return dateString(self.startAt);
    },
    get assignee() {
      if (self.entityType === 'user') {
        return self.firstName + ' ' + self.lastName;
      } else if (self.entityType === 'coach') {
        return 'All Clients';
      } else if (self.entityType === 'company') {
        return 'All ' + self.title + ' Clients';
      } else {
        return self.title;
      }
    },
  }));

export default ProgramAssignment;
