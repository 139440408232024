import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import graphStyleDefaults from './graphStyleDefaults';
import YAxisLine from './util/YAxisLine';
import TouchTrackerOverlay from './util/TouchTrackerOverlay';
import GraphedLine from './util/GraphedLine';
import CommonGraphSpacingProvider from './util/NewCommonGraphSpacingProvider';
import ColumnHighlight from './util/ColumnHighlight';

const getNonNullPropValuesFromObject = (obj, propNames) => {
  const values = [];
  propNames.forEach(propName => {
    if (obj[propName] !== null && obj[propName] !== undefined) {
      values.push(obj[propName]);
    }
  });
  return values;
};

class MultiLineGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: -1,
    };
  }

  render() {
    const {
      data,
      lineDefinitions,
      head,
      tail,
      height,
      width,
      svg,
      formatYAxisValue,
      formatDateAsDayOfMonth,
      formatDateAsWeekday,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
      axisMarkerTextColor,
      bottomCaptionText,
      lineWidth,
      pointRadius,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      yAxisLineProps,
      xAxisLabelType,
      formatDateAsMonth,
      alternativeYAxisLines,
      overrideMaxValue,
      overrideMinValue,
      selectedDate,
      onHoverOverDate,
      onPressDate,
      selectionBoxFillColor,
      selectionBoxCornerRadius,
      selectionBoxStrokeColor,
      xAxisMarkerTextColor,
      yAxisLineMode,
      useSkinnyLine,
      GraphedLineComponent,
      touchTrackerOverlayProps,
      columnHighlightProps,
    } = this.props;
    const { G, Text, Svg } = svg;

    const {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      dataMarkerStartY,
      barWidth,
      xAxisCaptionY,
    } = CommonGraphSpacingProvider.getCommonSpacingProps({
      numDates: data.length,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      graphWidth: width,
      graphHeight: height,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
    });

    // name of each prop on data.value, one per lime
    const valuePropNames = lineDefinitions.map(ld => ld.valuePropName);
    let nonNullData = data.filter(d => d.value !== null && d.value !== undefined);
    // incorporate the tail and head into the min/max if needed, as the graph will try to draw a line between
    // head and tail (or just flat line tail if needed)
    // OR... just use it all the time? Seems like it might actually give some better context when moving between values
    //if (!nonNullData.length) {
    if (tail) {
      nonNullData.push(tail);
    }
    if (head) {
      nonNullData.push(head);
    }

    let maxValue = Math.max(
      ...nonNullData
        .filter(g => g.value)
        // use absurdly low number to guard against Infinity when no probs on object have values
        .map(g => Math.max(...getNonNullPropValuesFromObject(g.value, valuePropNames), 0))
    );
    let minValue = Math.min(
      ...nonNullData
        .filter(g => g.value)
        // use absurdly large number to guard against -Infinity when no probs on object have values
        .map(g => Math.min(...getNonNullPropValuesFromObject(g.value, valuePropNames), 10000000000))
    );

    // provides more space outside of the two lines, putting them closer together
    // (could do this other ways)
    const spaceOutside = maxValue * 0.1;
    maxValue = overrideMaxValue || maxValue + spaceOutside;
    minValue = overrideMinValue || minValue - spaceOutside / 2;

    const commonTargetLineProps = {
      formatYAxisValue,
      width,
      axisMarkerFontSize,
      axisMarkerTextColor,
      svg,
      drawHeight: maxBarHeight,
      minValue,
      maxValue,
      tipHeight: 0,
      fixedLeftOffset: paddingLeft,
      fixedRightOffset: paddingRight,
      ...yAxisLineProps,
    };

    // one object for each value prop, containing all the elements that will be rendered for each
    const valuePropElements = [];

    lineDefinitions.forEach(lineDefinition => {
      const valuePropName = lineDefinition.valuePropName;
      const valuePropElement = {};
      valuePropElement.key = `${valuePropName}Elements`;

      // 1) Y axis line indicating average
      let dataForAverage = data.filter(d => d.value && d.value[valuePropName]);
      // incorporate tail if there are no data points at all so we can see some lines
      if (!dataForAverage.length && tail && tail[valuePropName]) {
        dataForAverage.push(tail);
      }

      let sum = 0;
      dataForAverage.forEach(d => {
        sum += d.value[valuePropName];
      });

      const average = Math.ceil(sum / dataForAverage.length);
      if (!alternativeYAxisLines) {
        // average of each line
        if (yAxisLineMode === 'default') {
          valuePropElement.yAxisLine = (
            <YAxisLine
              key={`${valuePropName}YAxisLine`}
              target={average}
              highestTarget={average}
              yAxisRightLabel={lineDefinition.yAxisLabelSuffix}
              {...commonTargetLineProps}
            />
          );
        }
      }

      // 2 Graphed line

      valuePropElement.graphedLine = (
        <GraphedLineComponent
          key={`${valuePropName}GraphedLine`}
          svg={svg}
          color={lineDefinition.color}
          columnWidth={columnWidth}
          drawWidth={width - paddingLeft - paddingRight}
          drawHeight={maxBarHeight}
          data={data.map(d => ({ date: d.date, value: d.value ? d.value[valuePropName] : null }))}
          head={
            head && head.value && head.value[valuePropName]
              ? { date: head.date, value: head.value ? head.value[valuePropName] : null }
              : null
          }
          tail={
            tail && tail.value && tail.value[valuePropName]
              ? { date: tail.date, value: tail.value ? tail.value[valuePropName] : null }
              : null
          }
          smoothing={0}
          minValue={minValue}
          maxValue={maxValue}
          lineWidth={lineWidth}
          pointRadius={pointRadius}
          pointsOffset={0}
          {...lineDefinition}
        />
      );

      valuePropElements.push(valuePropElement);
    });

    const selectedIndex = selectedDate ? findIndex(data, d => d.date === selectedDate) : -1;

    const myUseSkinnyLine =
      useSkinnyLine || xAxisLabelType === 'everyXDays' || xAxisLabelType === 'everyMonth';

    const columnHighlight = (
      <ColumnHighlight
        svg={svg}
        height={height}
        selectionBoxCornerRadius={selectionBoxCornerRadius}
        selectionBoxFillColor={selectionBoxFillColor}
        selectionBoxStrokeColor={selectionBoxStrokeColor}
        dataMarkerStartX={dataMarkerStartX}
        columnWidth={columnWidth}
        barWidth={barWidth}
        selectedIndex={selectedIndex}
        maxBarHeight={maxBarHeight}
        dataMarkerStartY={dataMarkerStartY}
        useSkinnyLine={myUseSkinnyLine}
        showAxisDash={xAxisLabelType === 'none'}
        xAxisCaptionY={xAxisCaptionY}
        axisMarkerFontSize={axisMarkerFontSize}
        xAxisMarkerTextColor={xAxisMarkerTextColor}
        {...columnHighlightProps}
      />
    );

    return (
      <Svg height={height} width={width}>
        {!useSkinnyLine && columnHighlight /* bigger selection box under graph */}
        <G x={dataMarkerStartX} y={dataMarkerStartY}>
          {valuePropElements.map(v => v.graphedLine)}
        </G>
        {bottomCaptionText && (
          <Text
            fontSize={axisMarkerFontSize}
            fill={axisMarkerTextColor}
            x={width / 2}
            y={xAxisCaptionY}
            weight="bold"
            textAnchor="middle">
            {bottomCaptionText}
          </Text>
        )}
        {!bottomCaptionText
          ? data.map((value, index) => (
              <G id="Group" x="0" y="0" key={value.date}>
                <G x={dataMarkerStartX} y={0}>
                  <G id="bar" x={index * columnWidth} y="0">
                    {xAxisLabelType === 'weekdays' && (
                      <Text
                        fontSize={axisMarkerFontSize}
                        fill={axisMarkerTextColor}
                        x={columnWidth / 2}
                        y={xAxisCaptionY}
                        weight="bold"
                        textAnchor="middle">
                        {formatDateAsWeekday(value.date)}
                      </Text>
                    )}
                    {xAxisLabelType === 'everyXDays' && index % 4 === 0 ? (
                      <Text
                        fontSize={axisMarkerFontSize}
                        fill={axisMarkerTextColor}
                        x={columnWidth / 2}
                        y={xAxisCaptionY}
                        weight="bold"
                        textAnchor="middle">
                        {formatDateAsDayOfMonth(value.date)}
                      </Text>
                    ) : null}
                    {xAxisLabelType === 'everyMonth' && value.date.endsWith('01') ? (
                      <Text
                        fontSize={axisMarkerFontSize}
                        fill={axisMarkerTextColor}
                        x={columnWidth / 2}
                        y={xAxisCaptionY}
                        weight="bold"
                        textAnchor="middle">
                        {formatDateAsMonth(value.date)}
                      </Text>
                    ) : null}
                  </G>
                </G>
              </G>
            ))
          : null}
        <G x="0" y={dataMarkerStartY}>
          {alternativeYAxisLines}
          {valuePropElements.map(v => v.yAxisLine)}
        </G>
        {useSkinnyLine && columnHighlight /* skinny line over graph */}
        {onPressDate || onHoverOverDate ? (
          <TouchTrackerOverlay
            svg={svg}
            height={height}
            width={width}
            selectedTooltipIndex={selectedIndex}
            onHoverOverIndex={
              onHoverOverDate
                ? index => {
                    if (index < data.length) {
                      onHoverOverDate(data[index].date);
                    }
                  }
                : undefined
            }
            onPressIndex={
              onPressDate
                ? index => {
                    if (index < data.length) {
                      onPressDate(data[index].date);
                    }
                  }
                : undefined
            }
            graphStartingXPos={dataMarkerStartX}
            graphDataPointColumnWidth={columnWidth}
            numDataPoints={data.length}
            {...touchTrackerOverlayProps}
          />
        ) : null}
      </Svg>
    );
  }
}

MultiLineGraph.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  // define each line that will be graphed
  lineDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      // name of prop on value prop of each data point that contains the graphable value
      valuePropName: PropTypes.string.isRequired,
      // color to graph the line
      color: PropTypes.string.isRequired,
      // optional suffix after the y axis label value
      yAxisLabelSuffix: PropTypes.string,
      // optional; if used, this value will be the top line tooltip; the others will be the second line
      isPrimary: PropTypes.bool,
    })
  ).isRequired,
  // when generating automatic midpoints, how many lines to draw
  numYAxisLines: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.object,
    })
  ).isRequired,
  //Optionally include "head" and "tail" points so we can draw where the graph is "going"
  head: PropTypes.shape({
    date: PropTypes.string.isRequired,
    value: PropTypes.object,
  }),
  tail: PropTypes.shape({
    date: PropTypes.string.isRequired,
    value: PropTypes.object,
  }),
  // SVG wants absolute dimensions
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  xAxisLabelType: PropTypes.oneOf(['weekdays', 'everyXDays', 'everyMonth', 'none']),
  formatYAxisValue: PropTypes.func,
  // default = average line OR use alternativeYAxisLines
  // none = don't show any
  yAxisLineMode: PropTypes.oneOf(['default', 'none']),
  // if not null, provide own yaxis lines
  // used for generic line graph to provide lowMidHigh ranges
  alternativeYAxisLines: PropTypes.element,
  // override date values
  formatDateAsDayOfMonth: PropTypes.func,
  formatDateAsWeekday: PropTypes.func,
  formatDateAsMonth: PropTypes.func,
  axisMarkerFontSize: PropTypes.number,
  axisMarkerDescenderHeight: PropTypes.number,
  axisMarkerTextColor: PropTypes.string,
  bottomCaptionText: PropTypes.string,
  lineWidth: PropTypes.number,
  pointRadius: PropTypes.number,
  // selection colors/ sizes
  // reduce opacity for unselected dates
  unselectedDateOpacity: PropTypes.number,
  selectionBoxFillColor: PropTypes.string,
  selectionBoxStrokeColor: PropTypes.string,
  selectionBoxHorizontalPadding: PropTypes.number,
  selectionBoxCornerRadius: PropTypes.number,
  // spacing at edges of graph
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  // passthrough props to Y Axis lines
  yAxisLineProps: PropTypes.any,
  // relative spacing props - change data window to give more or less space for lines
  overrideMinValue: PropTypes.number,
  overrideMaxValue: PropTypes.number,
  // new tooltip stuff
  // *** toggle display modes ***
  // called with date when user drags/ hovers over date
  onHoverOverDate: PropTypes.func,
  // called with date when user presses date
  onPressDate: PropTypes.func,
  // date for which to show tooltip
  selectedDate: PropTypes.string,
  useSkinnyLine: PropTypes.bool,
  // override to provide a different type of graphed line
  // used by LiftGainGraph to provide polygons, clip paths, etc.
  GraphedLineComponent: PropTypes.any,
};

MultiLineGraph.defaultProps = {
  numYAxisLines: 0,
  formatYAxisValue: graphStyleDefaults.formatYAxisValue,
  formatDateAsDayOfMonth: graphStyleDefaults.formatDateAsDayOfMonth,
  formatDateAsWeekday: graphStyleDefaults.formatDateAsWeekday,
  formatDateAsMonth: graphStyleDefaults.formatDateAsMonth,
  axisMarkerFontSize: graphStyleDefaults.axisMarkerFontSize,
  axisMarkerDescenderHeight: graphStyleDefaults.axisMarkerDescenderHeight,
  axisMarkerTextColor: graphStyleDefaults.axisMarkerTextColor,
  xAxisLabelType: 'none',
  bottomCaptionText: null,
  lineWidth: 2,
  pointRadius: 3,
  yAxisLineProps: null,
  yAxisLineMode: 'default',
  alternativeYAxisLines: null,
  overrideMinValue: null,
  overrideMaxValue: null,
  // called with date when user drags/ hovers over date
  onHoverOverDate: () => {},
  // called with date when user presses date
  onPressDate: null,
  // reduce opacity for unselected dates
  unselectedDateOpacity: 1,
  // date for which to show tooltip
  selectedDate: null,
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 16,
  paddingTop: 16,
  selectionBoxFillColor: 'white',
  selectionBoxStrokeColor: graphStyleDefaults.axisMarkerTextColor,
  selectionBoxCornerRadius: 5,
  useSkinnyLine: false,
  GraphedLineComponent: GraphedLine,
};

export default MultiLineGraph;
