import { DateTime } from 'luxon';
import numeral from 'numeral';

export default {
  axisMarkerFontSize: 11,
  axisMarkerDescenderHeight: 10,
  axisMarkerTextColor: '#676b72', // for y axis only now TODO: fix name
  xAxisMarkerTextColor: 'black', // separate prop that we can apply opacity to
  toolTipValueFontSize: 22,
  toolTipUnitsFontSize: 14,
  toolTipValueTextColor: 'white',
  toolTipDateFontSize: 11,
  toolTipDateTextColor: '#676b72',
  xAxisLabelSpace: 16,
  minTooltipPlusStemHeight: 50,
  formatYAxisValue: val => numeral(val).format('0,0'),
  formatToolTipValue: val => numeral(val).format('0,0.[0]'),
  formatDateAsWeekday: date =>
    DateTime.fromISO(date)
      .toFormat('DDDD')
      .substr(0, 1),
  formatDateAsDayOfMonth: date => DateTime.fromISO(date).toFormat('d'),
  formatDateAsMonth: date => DateTime.fromISO(date).toFormat('MMM'),
  formatDateAsShortDate: date => DateTime.fromISO(date).toFormat('M/d'),
  barGraphLeftMinOffset: 40,
  spacePerTooltipCharacter: 10,
  questionTooltipBackgroundColor: '#ebebeb',
  questionTooltipTextColor: 'black',
  questionGraphTimelineHeight: 8,
};
