import { DateTime } from 'luxon';
import moment from 'moment-timezone';

/**
 * RULES FOR THIS LIBRARY:
 * - if a function has to consider what "today" is, it MUST use getToday()
 * - All dates-without-times are returned in string format, in YYYY-MM-DD
 * - All dates-with-times are returned as JavaScript Date objects
 * - Those last two rules also apply to input parameters
 * - Where possible, always except either a string date or JS Date. Use toDateTime to normalize.
 * - No Moment.js unless we can't do it another way (e.g. the relative date stuff that's not in luxon). Moment people told us to move on.
 * - No leaking DateTimes or Moments!
 * - It's OK to do other date math on the outside with Luxon directly- just add stuff here if you think we can share it, need it more than once
 * - New functions shouldn't need a today parameter to change the reference date, since we do that now with overrideToday()
 *
 * HOW TO USE IN YOUR APP WITHOUT GOING CRAZY:
 * - If you're using "today" in your app, get it from getToday() or else weird stuff will happen
 * - If you're overriding "today" in your app, call overrideToday() with the new date value, then always use getToday() when you need today
 * - Don't store today in an MST store, or create an MST store view that returns today. It WILL NOT trigger a refresh on date change. We learned this lesson the hard way.
 *
 * FUTURE DIRECTIONS:
 * - wondering if getToday() should be accessed via React context for easier injection for unit testing?
 */

// variable used to override what "today" is
// as long as you're doing all date functions from within this library, today should be the same

const overrides = {
  today: null,
};

// unfortunately the server is probably always going to return NY time rather than UTC
const SERVER_TIMEZONE = 'America/New_York';

const getToday = () => {
  return overrides.today || jsDateToDateString(new Date());
};

/**
 *
 * @param newToday - must be date string
 */
const overrideToday = newToday => {
  overrides.today = newToday;
};

/**
 * returns true if date is on the same day as today
 * @param date - date string or JS Date
 */
const isToday = date => {
  return isSameDay(date, getToday());
};

const dateFormat = 'yyyy-MM-dd';
//const dateTimeFormat = 'yyyy-MM-dd HH:mm:00';

const isSameDay = (date1, date2) => {
  const dateTime1 = toDateTime(date1);
  const dateTime2 = toDateTime(date2);
  return dateTime1.hasSame(dateTime2, 'day');
};

/**
 * Turns a JS or SQL string date into a Luxon DateTime
 * @param {} sqlOrJsDate
 */
const toDateTime = sqlOrJsDate => {
  if (sqlOrJsDate instanceof Date) {
    return DateTime.fromJSDate(sqlOrJsDate);
  }
  // timestamps are always in NY time
  return DateTime.fromSQL(sqlOrJsDate);
};

/**
 * New very simple version that does all local time conversions
 */
const jsDateToDateString = jsDate => {
  return DateTime.fromJSDate(jsDate).toFormat(dateFormat);
};

const jsDateToDateTimeString = jsDate => {
  return DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd HH:mm:ss'); // DIFFERENT from moment.js. This cost me hours!!!
};

/**
 * New very simple version that does all local time conversions
 */
const dateStringToJsDate = dateString => {
  return DateTime.fromSQL(dateString).toJSDate();
};

const enumerateDaysForRange = ({ startDate, endDate, ignoreFutureDates = true }) => {
  const today = getToday();
  let currentDate = startDate;
  const enumeratedDates = [];
  while (currentDate <= endDate) {
    if (ignoreFutureDates && currentDate > today) {
      break;
    }
    enumeratedDates.push(currentDate);
    currentDate = toDateTime(currentDate)
      .plus({ days: 1 })
      .toFormat(dateFormat);
  }

  return enumeratedDates;
};

// gets date strings starting from date - x days to the current date
const getLastXDaysFrom = ({ date, x }) => {
  const today = toDateTime(date);
  return Array.from(Array(x).keys())
    .reverse()
    .map(day => today.minus({ days: day }).toFormat(dateFormat));
};

const getDateMinusXDays = ({ date, x }) => {
  const startingDate = toDateTime(date);
  return startingDate.minus({ days: x }).toFormat(dateFormat);
};

const getDatePlusXDays = ({ date, x }) => {
  const startingDate = toDateTime(date);
  return startingDate.plus({ days: x }).toFormat(dateFormat);
};

const dateForBeginningOfDay = date => {
  let givenDateTime = toDateTime(date);
  return DateTime.local(givenDateTime.year, givenDateTime.month, givenDateTime.day).toJSDate();
};

const dateForEndOfDay = date => {
  let givenDateTime = toDateTime(date);
  return DateTime.local(
    givenDateTime.year,
    givenDateTime.month,
    givenDateTime.day,
    23,
    59
  ).toJSDate();
};

const getDatesForRange = ({ startDate, endDate }) => {
  const dates = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = DateTime.fromSQL(currentDate)
      .plus({ days: 1 })
      .toFormat(dateFormat);
  }

  return dates;
};

const getDatesForLogs = ({ logs, startDate, endDate }) => {
  return [
    ...new Set(
      logs
        .map(l => DateTime.fromSQL(l.userTime).toFormat(dateFormat))
        .filter(d => d >= startDate && d <= endDate)
        .sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        })
    ),
  ];
};

const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

/**
 * Convert a timezone-less time into JS Date (local time) - used for logs
 */
const userTimeToJsDate = userTime => {
  return DateTime.fromSQL(userTime).toJSDate();
};

const jsDateToUserTime = jsDate => {
  return DateTime.fromJSDate(jsDate).toFormat(dateTimeFormat);
};

/**
 * Intermediate function that does correction for weeks starting on sunday.
 * Takes a Luxon DateTime and returns { startDate, endDate } object with Luxon DateTime formats for the props.
 */
const dateRangeForWeek = ({ dateTime, weekStartsOnSunday = true }) => {
  if (weekStartsOnSunday) {
    // add one day to a Sunday date so now we're at about the start of the week
    const dateTimeWithinWeek = dateTime.weekday === 7 ? dateTime.plus({ days: 1 }) : dateTime;
    return {
      startDateTime: dateTimeWithinWeek.startOf('week').minus({ days: 1 }),
      endDateTime: dateTimeWithinWeek.endOf('week').minus({ days: 1 }),
    };
  } else {
    return {
      startDateTime: dateTime.startOf('week'),
      endDateTime: dateTime.endOf('week'),
    };
  }
};

const dateRangeForInterval = ({
  date,
  interval /* week or month OR 4weeks custom interval for testing */,
  weekStartsOnSunday = true,
}) => {
  const dateTime = toDateTime(date);
  let firstDateInRange;
  let lastDateInRange;
  // this is a custom interval just for program card testing
  // we'll take it out once we fix program card paging
  // we did this in order to always load enough data
  if (interval === '4weeks') {
    const rangeForOneWeek = dateRangeForWeek({ dateTime, weekStartsOnSunday });
    lastDateInRange = rangeForOneWeek.endDateTime;
    firstDateInRange = rangeForOneWeek.startDateTime.minus({ weeks: 3 });
  } else if (interval === 'week') {
    const rangeForOneWeek = dateRangeForWeek({ dateTime, weekStartsOnSunday });
    lastDateInRange = rangeForOneWeek.endDateTime;
    firstDateInRange = rangeForOneWeek.startDateTime;
  } else {
    // works for month, quarter, year
    firstDateInRange = dateTime.startOf(interval);
    lastDateInRange = dateTime.endOf(interval);
  }
  return {
    startDate: firstDateInRange.toFormat(dateFormat),
    endDate: lastDateInRange.toFormat(dateFormat),
  };
};

/* Calculates date range that covers:
 * at least minDaysBack days between startDate and date (useful for charting)
*/
const dateRange = ({ date, minDaysBack }) => ({
  startDate: getDateMinusXDays({ date, x: minDaysBack - 1 }),
  endDate: date,
});

/* Calculates date range that covers:
 * at least minDaysBack days between startDate and date (useful for charting)
 * an integer number of full weeks between startDate and endDate (useful for weekly goals)
*/
const dateRangeForFullWeeksCoveringRange = ({ date, minDaysBack, weekStartsOnSunday = true }) => {
  const minimumInterval = dateRange({ date, minDaysBack });

  return {
    startDate: dateRangeForWeek({
      dateTime: toDateTime(minimumInterval.startDate),
      weekStartsOnSunday,
    }).startDateTime.toFormat(dateFormat),
    endDate: dateRangeForInterval({ date: minimumInterval.endDate, interval: 'week' }).endDate,
  };
};

/**
 * Not entirely working... will return 0 if the date is within the current week, 1 if the last week, or 2 if 2+ weeks.
 * Enough to figure out whether to say "this week" or "last week" somewhere
 */
const numWeeksAgo = ({ date, weekStartsOnSunday = true }) => {
  const todayDateTime = toDateTime(getToday());
  const myDateTime = toDateTime(date);
  const currentWeek = dateRangeForWeek({ dateTime: todayDateTime, weekStartsOnSunday });
  if (myDateTime >= currentWeek.startDateTime && myDateTime <= currentWeek.endDateTime) {
    return 0;
  }
  if (
    myDateTime >= currentWeek.startDateTime.minus({ weeks: 1 }) &&
    myDateTime <= currentWeek.endDateTime.minus({ weeks: 1 })
  ) {
    return 1;
  }
  return 2;
};

const dates = {
  date: timestamp => {
    if (DateTime.local().hasSame(timestamp, 'year')) {
      return timestamp.toFormat('MMM d');
    }
    return timestamp.toFormat('MMM d, ’yy');
  },
  relativeDate: timestamp => {
    if (DateTime.local().hasSame(timestamp, 'day')) {
      return 'Today';
    } else if (
      DateTime.local()
        .minus({ days: 1 })
        .hasSame(timestamp, 'day')
    ) {
      return 'Yesterday';
    } else if (DateTime.local().diff(timestamp, 'days').days < 7) {
      return timestamp.toFormat('cccc');
    } else if (DateTime.local().hasSame(timestamp, 'year')) {
      return timestamp.toFormat('MMM d');
    } else {
      return timestamp.toFormat('MMM d, ’yy');
    }
  },
  relativeDateTime: timestamp => {
    if (DateTime.local().hasSame(timestamp, 'day')) {
      return `Today, ${timestamp.toFormat('t')}`;
    } else if (
      DateTime.local()
        .minus({ days: 1 })
        .hasSame(timestamp, 'day')
    ) {
      return `Yesterday, ${timestamp.toFormat('t')}`;
    } else if (DateTime.local().diff(timestamp, 'days').days < 7) {
      return timestamp.toFormat('cccc, t');
    } else if (DateTime.local().hasSame(timestamp, 'year')) {
      return timestamp.toFormat('MMM d, t');
    } else {
      return timestamp.toFormat('MMM d, ’yy, t');
    }
  },
  relativeTimeFirst: timestamp => {
    if (DateTime.local().hasSame(timestamp, 'day')) {
      return `${timestamp.toFormat('t')}, Today`;
    } else if (
      DateTime.local()
        .minus({ days: 1 })
        .hasSame(timestamp, 'day')
    ) {
      return `${timestamp.toFormat('t')}, Yesterday`;
    } else if (DateTime.local().diff(timestamp, 'days').days < 7) {
      return timestamp.toFormat('t, cccc');
    } else if (DateTime.local().hasSame(timestamp, 'year')) {
      return timestamp.toFormat('t, MMM d');
    } else {
      return timestamp.toFormat('t, MMM d, ’yy');
    }
  },
  timeAgo: timestamp => {
    if (DateTime.local().hasSame(timestamp, 'day')) {
      return `Today`;
    } else if (
      DateTime.local()
        .minus({ days: 1 })
        .hasSame(timestamp, 'day')
    ) {
      return 'Yesterday';
    } else {
      //Luxon 1.2.1 doesn't support relative time. Consider upgrading to at least 1.9.1 to get this feature; use moment for the time being which supports this.
      return moment(timestamp).fromNow();
    }
  },
  timeAgoCompact: timestamp => {
    const diff = Math.round(DateTime.local().diff(timestamp, 'seconds').seconds);
    if (diff < 3600) {
      return Math.max(Math.floor(diff / 60), 0) + 'm';
    } else if (diff < 86400) {
      return Math.floor(diff / 3600) + 'h';
    } else {
      return Math.floor(diff / 86400) + 'd';
    }
  },
};

const nudgeDate = ({ dateString, dateStyle, daysAgo, hoursAgo, minutesAgo }) => {
  let date;
  if (dateString) {
    date = DateTime.fromSQL(dateString);
  } else {
    date = DateTime.local();
    if (daysAgo) date = date.minus({ days: daysAgo });
    if (hoursAgo) date = date.minus({ hours: hoursAgo });
    if (minutesAgo) date = date.minus({ minutes: minutesAgo });
  }

  return dates[dateStyle](date);
};

// Managed apps on Android don't support the intl extension that luxon relies on for time zone conversions. Hence, we're gonna stick with Moment which doesn't need this dependency. Note that `format` needs to be in moment format, not luxon format.
const convertToClientTimeZone = (date, user, format) => {
  const timeZone =
    user && user.profile && user.profile.timezone ? user.profile.timezone : SERVER_TIMEZONE;
  const isValidZone = moment.tz.zone(timeZone) !== null;
  const validTimeZone = isValidZone ? timeZone : SERVER_TIMEZONE;

  return moment
    .tz(date, SERVER_TIMEZONE)
    .tz(validTimeZone)
    .format(format);
};

export {
  getDatesForRange,
  getDatesForLogs,
  getLastXDaysFrom,
  getDateMinusXDays,
  getDatePlusXDays,
  dateForBeginningOfDay,
  dateForEndOfDay,
  jsDateToDateString,
  jsDateToDateTimeString,
  dateStringToJsDate,
  isSameDay,
  toDateTime,
  userTimeToJsDate,
  jsDateToUserTime,
  dateRangeForInterval,
  enumerateDaysForRange,
  numWeeksAgo,
  overrideToday,
  getToday,
  isToday,
  dateRange,
  dateRangeForFullWeeksCoveringRange,
  nudgeDate,
  convertToClientTimeZone,
};
