import React, { Component } from 'react';
import PropTypes from 'prop-types';

function findNearestToSnapTo({ data, index }) {
  const howFarToLook = data.length > 60 ? 3 : 0;
  if (howFarToLook === 0) {
    return {
      snapToNearby: false,
    };
  }
  const indiciesToLookAt = [];
  for (let i = -1 * howFarToLook - 1; i <= howFarToLook; i++) {
    if (index + i >= 0 && index + 1 < data.length) {
      indiciesToLookAt.push(index + i);
    }
  }
  const dataPointIndiciesWithValues = [];
  indiciesToLookAt.forEach(indice => {
    if (data[indice] && data[indice].value) {
      dataPointIndiciesWithValues.push(indice);
    }
  });

  // just one? snap to it
  if (dataPointIndiciesWithValues.length === 1) {
    return {
      snapToNearby: true,
      snapToIndice: dataPointIndiciesWithValues[0],
    };
  }
  return {
    snapToNearby: false,
  };
}

export default class TouchTrackerOverlay extends Component {
  state = { hoverTouchIndex: -1, onPressTouchIndex: -1 };

  _updatePositionBasedOnMovement = ({ locationX, onActionFn, touchStateField }) => {
    const {
      graphStartingXPos,
      graphDataPointColumnWidth,
      numDataPoints,
      selectionMode,
      data,
    } = this.props;
    let newTouchIndex = Math.max(
      Math.min(
        Math.round(
          (locationX - graphDataPointColumnWidth / 2 - graphStartingXPos) /
            graphDataPointColumnWidth
        ),
        numDataPoints - 1
      ),
      0
    );

    // throw out touches that point to an empty date
    if (selectionMode === 'withData') {
      if (!data[newTouchIndex].value) {
        const snapToAdvice = findNearestToSnapTo({ data, index: newTouchIndex });
        if (snapToAdvice.snapToNearby) {
          newTouchIndex = snapToAdvice.snapToIndice;
        } else {
          return;
        }
      }
    }

    // TODO: this likely bugs out if there are zero data points!

    if (this.state[touchStateField] !== newTouchIndex && numDataPoints) {
      this.props[onActionFn](newTouchIndex);
      this.setState({
        [touchStateField]: newTouchIndex,
      });
    }
  };

  onPressIn = ({ nativeEvent: { offsetX, locationX /*locationY, target*/ } }) => {
    this._updatePositionBasedOnMovement({
      locationX: locationX || offsetX,
      onActionFn: 'onHoverOverIndex',
      touchStateField: 'hoverTouchIndex',
    });
    this._updatePositionBasedOnMovement({
      locationX: locationX || offsetX,
      onActionFn: 'onPressIndex',
      touchStateField: 'onPressTouchIndex',
    });
  };

  onPressMove = ({ nativeEvent: { locationX /*locationY, target*/ } }) => {
    this._updatePositionBasedOnMovement({
      locationX,
      onActionFn: 'onHoverOverIndex',
      touchStateField: 'hoverTouchIndex',
    });
  };

  onMouseMove = ({ nativeEvent: { offsetX } }) => {
    this._updatePositionBasedOnMovement({
      locationX: offsetX,
      onActionFn: 'onHoverOverIndex',
      touchStateField: 'hoverTouchIndex',
    });
  };

  onMouseLeave = () => {
    this.setState({ hoverTouchIndex: -1 });
  };

  render() {
    const { svg, width, height } = this.props;

    const { Rect, G } = svg;

    const barPressEventProps = {};

    barPressEventProps.onResponderMove = this.onPressMove;
    barPressEventProps.onPressIn = this.onPressIn;
    barPressEventProps.onMouseMove = this.onMouseMove;
    barPressEventProps.onMouseLeave = this.onMouseLeave;

    return (
      <G x="0" y="0">
        <Rect
          fill="rgba(0,0,0,0)"
          x="0"
          y="0"
          rx={0}
          ry={0}
          width={width}
          height={height}
          {...barPressEventProps}
        />
      </G>
    );
  }
}

TouchTrackerOverlay.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  // SVG wants absolute dimensions
  // This is the absolute height and width of the graph
  // Don't account for any other smaller heights and widths here, because
  // this defines the touchable area, not the drawable area!
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  // Offset from the left side where the graphed area starts
  // Used to calculate what point you are touching
  graphStartingXPos: PropTypes.number.isRequired,
  // How wide the touchable area is for a single data point
  // Used for hit slop
  graphDataPointColumnWidth: PropTypes.number.isRequired,
  // number of data points on the graph. Used to determine which index is being touched
  numDataPoints: PropTypes.number.isRequired,
  // if showing a fixed tooltip with no hover option, which element to show?
  // if null, allow any to be selected be hovering
  fixedDataIndex: PropTypes.number,
  onPressIndex: PropTypes.func,
  onHoverOverIndex: PropTypes.func,
  // select any date or just dates with data
  selectionMode: PropTypes.oneOf(['any', 'withData']),
  // required if using selectionMode = withData
  data: PropTypes.array,
};

TouchTrackerOverlay.defaultProps = {
  fixedDataIndex: null,
  onPressIndex: () => {},
  onHoverOverIndex: () => {},
  selectionMode: 'any',
  data: null,
};
