import React, { Component } from 'react';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import graphStyleDefaults from './graphStyleDefaults';
import TouchTrackerOverlay from './util/TouchTrackerOverlay';
import CommonGraphSpacingProvider from './util/NewCommonGraphSpacingProvider';
import PieGraph from './util/PieGraph';
import DayDots from './util/DayDots';
import ColumnHighlight from './util/ColumnHighlight';
import getMultipleChoiceAnswerColorMapper from './util/getMultipleChoiceAnswerColorMapper';

class MultipleChoiceQuestionTrackerGraph extends Component {
  render() {
    const {
      data,
      trackerColor,
      height,
      width,
      formatDateAsDayOfMonth,
      formatDateAsWeekday,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
      axisMarkerTextColor,
      svg,
      possibleAnswers,
      xAxisLabelType,
      pieGraphCircleStrokeWidth,
      pieGraphDiameter,
      selectedDate,
      onHoverOverDate,
      onPressDate,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      selectionBoxCornerRadius,
      selectionBoxStrokeColor,
      selectionBoxFillColor,
      unselectedDateOpacity,
      pieGraphPositionCheat,
      xAxisMarkerTextColor,
      columnHighlightHeight,
      noAnswerColor,
      useSkinnyLine,
    } = this.props;

    const { Svg, G } = svg;

    const {
      columnWidth,
      dataMarkerStartX,
      dataMarkerLowestY,
      barWidth,
      maxBarHeight,
      dataMarkerStartY,
      xAxisCaptionY,
    } = CommonGraphSpacingProvider.getCommonSpacingProps({
      numDates: data.length,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      graphWidth: width,
      graphHeight: height,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
    });

    const selectedIndex = selectedDate ? findIndex(data, d => d.date === selectedDate) : -1;

    let myPieGraphDiameter = pieGraphDiameter;

    if (!pieGraphDiameter) {
      myPieGraphDiameter = maxBarHeight / 2.5;
    }

    const colorMapper = getMultipleChoiceAnswerColorMapper({
      baseColor: trackerColor,
      noAnswerColor,
      possibleAnswers,
    });

    return (
      <Svg height={height} width={width}>
        <ColumnHighlight
          svg={svg}
          height={height}
          selectionBoxCornerRadius={selectionBoxCornerRadius}
          selectionBoxFillColor={selectionBoxFillColor}
          selectionBoxStrokeColor={selectionBoxStrokeColor}
          dataMarkerStartX={dataMarkerStartX}
          columnWidth={columnWidth}
          barWidth={barWidth}
          selectedIndex={selectedIndex}
          maxBarHeight={maxBarHeight}
          dataMarkerStartY={dataMarkerStartY}
          showAxisDash={xAxisLabelType === 'none'}
          useSkinnyLine={useSkinnyLine || xAxisLabelType === 'everyXDays'}
          xAxisCaptionY={xAxisCaptionY}
          axisMarkerFontSize={axisMarkerFontSize}
          highlightOffsetFromTop={maxBarHeight - columnHighlightHeight}
          xAxisMarkerTextColor={xAxisMarkerTextColor}
          colorMapper={colorMapper}
          data={data}
        />
        <G
          x={width / 2}
          y={
            myPieGraphDiameter +
            pieGraphPositionCheat /* DON'T apply top padding here - we use it to pad the date buttons instead */
          }>
          <PieGraph
            svg={svg}
            data={data}
            trackerColor={trackerColor}
            diameter={myPieGraphDiameter}
            labelFontSize={axisMarkerFontSize}
            labelTextColor={axisMarkerTextColor}
            emptyColor={noAnswerColor}
            possibleAnswers={possibleAnswers}
            noAnswerPlaceholder="No Answer"
            circleStrokeWidth={pieGraphCircleStrokeWidth}
            maxLabelLength={18}
            showPercent
          />
        </G>
        <DayDots
          svg={svg}
          data={data}
          dataMarkerLowestY={dataMarkerLowestY}
          dataMarkerStartY={dataMarkerStartY}
          dotDiameter={barWidth}
          columnWidth={columnWidth}
          trackerColor={trackerColor}
          axisMarkerFontSize={axisMarkerFontSize}
          axisMarkerTextColor={axisMarkerTextColor}
          firstDotX={dataMarkerStartX}
          formatDateAsWeekday={formatDateAsWeekday}
          xAxisCaptionY={xAxisCaptionY}
          unselectedDateOpacity={unselectedDateOpacity}
          selectedIndex={selectedIndex}
          xAxisLabelType={xAxisLabelType}
          maxBarHeight={maxBarHeight}
          formatDateAsDayOfMonth={formatDateAsDayOfMonth}
          colorMapper={colorMapper}
        />
        {onPressDate || onHoverOverDate ? (
          <TouchTrackerOverlay
            svg={svg}
            height={height}
            width={width}
            selectedTooltipIndex={selectedIndex}
            onHoverOverIndex={
              onHoverOverDate
                ? index => {
                    if (index < data.length) {
                      onHoverOverDate(data[index].date);
                    }
                  }
                : undefined
            }
            onPressIndex={
              onPressDate
                ? index => {
                    if (index < data.length) {
                      onPressDate(data[index].date);
                    }
                  }
                : undefined
            }
            graphStartingXPos={dataMarkerStartX}
            graphDataPointColumnWidth={columnWidth}
            numDataPoints={data.length}
          />
        ) : null}
      </Svg>
    );
  }
}

MultipleChoiceQuestionTrackerGraph.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  xAxisLabelType: PropTypes.oneOf(['weekdays', 'everyXDays', 'none']),
  // color for the tracker
  // for a freeform answer, this color will be the only one used.
  // for multiple choice, this color will be used to generate lighter/ darker shades for different canned choices
  trackerColor: PropTypes.string.isRequired,
  // number that represents the top target for the graph
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ).isRequired,
  // all possible answers for a multiple choice question
  possibleAnswers: PropTypes.arrayOf(PropTypes.string),
  // SVG wants absolute dimensions
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  formatDateAsDayOfMonth: PropTypes.func,
  formatDateAsShortDate: PropTypes.func,
  formatDateAsWeekday: PropTypes.func,
  axisMarkerFontSize: PropTypes.number,
  axisMarkerDescenderHeight: PropTypes.number,
  axisMarkerTextColor: PropTypes.string,
  // spacing props
  minTooltipPlusStemHeight: PropTypes.number,
  timelineBarHeight: PropTypes.number,
  // base height of line between dot/ bar and tooltip
  tooltipLineHeight: PropTypes.number,
  // width of pie graph line
  pieGraphCircleStrokeWidth: PropTypes.number,
  // total diameter of the pie graph
  // if not specified, uses an equation based on height and width of graph
  pieGraphDiameter: PropTypes.number,
  // new tooltip stuff
  // *** toggle display modes ***
  // called with date when user drags/ hovers over date
  onHoverOverDate: PropTypes.func,
  // called with date when user presses date
  onPressDate: PropTypes.func,
  // reduce opacity for unselected dates
  unselectedDateOpacity: PropTypes.number,
  selectedDate: PropTypes.string,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  selectionBoxFillColor: PropTypes.string,
  selectionBoxStrokeColor: PropTypes.string,
  selectionBoxHorizontalPadding: PropTypes.number,
  selectionBoxCornerRadius: PropTypes.number,
  pieGraphPositionCheat: PropTypes.number,
  columnHighlightHeight: PropTypes.number,
  noAnswerColor: PropTypes.string,
  useSkinnyLine: PropTypes.bool,
};

MultipleChoiceQuestionTrackerGraph.defaultProps = {
  formatDateAsDayOfMonth: graphStyleDefaults.formatDateAsDayOfMonth,
  formatDateAsShortDate: graphStyleDefaults.formatDateAsShortDate,
  formatDateAsWeekday: graphStyleDefaults.formatDateAsWeekday,
  axisMarkerFontSize: graphStyleDefaults.axisMarkerFontSize,
  axisMarkerDescenderHeight: graphStyleDefaults.axisMarkerDescenderHeight,
  axisMarkerTextColor: graphStyleDefaults.axisMarkerTextColor,
  timelineBarHeight: graphStyleDefaults.questionGraphTimelineHeight,
  xAxisLabelType: 'none',
  tooltipLineHeight: 24,
  pieGraphCircleStrokeWidth: 10,
  pieGraphDiameter: null,
  // called with date when user presses date
  onPressDate: null,
  onHoverOverDate: () => {},
  // reduce opacity for unselected dates
  unselectedDateOpacity: 1,
  // date for which to show tooltip
  selectedDate: null,
  selectionBoxFillColor: 'white',
  selectionBoxStrokeColor: graphStyleDefaults.axisMarkerTextColor,
  selectionBoxCornerRadius: 5,
  selectionBoxHorizontalPadding: 16,
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 16,
  paddingTop: 16,
  columnHighlightHeight: 16,
  pieGraphPositionCheat: 0,
  noAnswerColor: 'gray',
  useSkinnyLine: false,
};

export default MultipleChoiceQuestionTrackerGraph;
