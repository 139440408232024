function roundUpNearest10(num) {
  return Math.ceil(num / 10) * 10;
}

function roundDownNearest10(num) {
  return Math.floor(num / 10) * 10;
}

function getLineGraphBounds({
  values,
  minDelta,
  overrideMinValue = null,
  overrideMaxValue = null,
  minTopBottomBuffer = 0,
  roundToTens = false,
}) {
  let nonNullData = values.filter(v => v !== null && v !== undefined);

  let maxValue = Math.max.apply(null, nonNullData);
  let minValue = Math.min.apply(null, nonNullData);

  let graphMinValue = minValue;
  let graphMaxValue = maxValue;

  // increase the graph min/ max if values are too tight together

  // new rules, set a min delta for standard line graphs, use if values are too close
  if (minDelta) {
    if (maxValue - minValue < minDelta) {
      const diffBetweenDeltaAndMinDelta = minDelta - (maxValue - minValue);
      graphMaxValue += diffBetweenDeltaAndMinDelta / 2;
      graphMinValue -= diffBetweenDeltaAndMinDelta / 2;
    }
  } else {
    // old rules - try to make min/ max a function of the max value if values are too close, ignore otherwise
    let topBottomBuffer = maxValue * 1.1 - maxValue;
    if (maxValue - minValue >= topBottomBuffer) {
      topBottomBuffer = 0;
    }
    if (topBottomBuffer < minTopBottomBuffer) {
      topBottomBuffer = minTopBottomBuffer;
    }
    graphMaxValue = maxValue + topBottomBuffer;
    graphMinValue = minValue - topBottomBuffer;
  }
  if (graphMinValue < 0) {
    graphMinValue = 0;
  }

  // should be impossible at this point
  if (graphMinValue === graphMaxValue) {
    graphMinValue = graphMaxValue / 2;
    // takes care of zero across the board
    // which should be impossible
    if (graphMaxValue === graphMinValue) {
      graphMaxValue = 1;
    }
  }

  // apply fixed min/ max values at this point
  if (overrideMinValue !== null && overrideMaxValue !== null) {
    graphMinValue = overrideMinValue;
    graphMaxValue = overrideMaxValue;
  }

  if (roundToTens) {
    graphMaxValue = roundUpNearest10(graphMaxValue);
    graphMinValue = roundDownNearest10(graphMinValue);
  }

  return {
    minValue: graphMinValue,
    maxValue: graphMaxValue,
  };
}

export default getLineGraphBounds;
