import { types } from 'mobx-state-tree';
import { v4 as uuid } from 'uuid';

const Tag = types
  .model('Tag', {
    id: types.optional(types.identifier, () => uuid()),
    serverId: types.maybeNull(types.number),
    companyId: types.maybeNull(types.number),
    name: types.optional(types.string, ''),
    isPublic: types.optional(types.boolean, false),
    rank: types.optional(types.number, 0),
    cardIds: types.optional(types.array(types.number), []),
  })
  .actions(self => ({
    setServerId: id => (self.serverId = id),
    setCompanyId: id => (self.companyId = id),
    setName: name => (self.name = name),
    setIsPublic: p => (self.isPublic = p),
    setIsTag: () => (self.isPublic = true),
    setIsFolder: () => (self.isPublic = false),
    setRank: r => (self.rank = r),
    insertCard: (index, id) => self.cardIds.splice(index, 0, id),
    removeCard: id => self.cardIds.splice(self.cardIds.indexOf(id), 1),
    swapCards: (oldId, newId) => {
      const oldIndex = self.cardIds.indexOf(oldId);
      const newIndex = self.cardIds.indexOf(newId);
      if (oldIndex === -1 || newIndex === -1) return;
      const cardIds = [...self.cardIds];
      const [oldCardId] = cardIds.splice(oldIndex, 1);
      cardIds.splice(newIndex, 0, oldCardId);
      self.cardIds = cardIds;
    },
  }))
  .views(self => ({
    get isNew() {
      return self.serverId === null;
    },
    hasCard(card) {
      return self.cardIds.includes(card.serverId);
    },
    get isTag() {
      return self.isPublic;
    },
    get isFolder() {
      return !self.isPublic;
    },
  }));

export default Tag;
