import { types } from 'mobx-state-tree';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function lowercaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

function entityPageStoreFactory({
  friendlyObjectPluralName,
  friendlyObjectSingularName,
  PageObjectClass,
}) {
  const lowerCasePluralObjectName = lowercaseFirstLetter(friendlyObjectPluralName);
  const upperCaseSingularObjectName = capitalizeFirstLetter(friendlyObjectSingularName);
  const PageableStore = types
    .model(`${lowerCasePluralObjectName}Store`, {
      [`${lowerCasePluralObjectName}`]: types.optional(types.array(PageObjectClass), []),
    })
    .actions(self => {
      const getObject = id => {
        let existingObject = self[lowerCasePluralObjectName].find(f => f.id === id);
        // create new topic feed if it doesn't exist
        // topic feeds include enough props from groups to be able to display
        if (!existingObject) {
          self[lowerCasePluralObjectName].push({
            id,
          });
          existingObject = self[lowerCasePluralObjectName].find(f => f.id === id);
        }
        return existingObject;
      };

      return {
        [`get${upperCaseSingularObjectName}`]: getObject,
      };
    });

  return PageableStore;
}

export default entityPageStoreFactory;
