import React from 'react';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import getMultipleChoiceAnswerColorMapper from './getMultipleChoiceAnswerColorMapper';

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function startAngForSegIndex(segments, index) {
  if (!segments) {
    return 0;
  }
  if (index === 0) return 0;
  let value = 0;
  for (let i = 0; i < index; i++) {
    value += segments[i].percent;
  }
  return value * 360;
}

function endAngForSegIndex(segments, index) {
  if (!segments) {
    return 0;
  }
  if (index === 0) return segments[0].percent * 360;
  let value = 0;
  for (let i = 0; i <= index; i++) {
    value += segments[i].percent;
  }
  return value * 360;
}

function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
    ' '
  );

  return d;
}

export default class PieGraph extends React.Component {
  renderLabel(segment, radius, startAngle, endAngle, lineLength, size, color) {
    const { maxLabelLength, showPercent } = this.props;
    const { G, Text, Line } = this.props.svg;

    const midAngle = startAngle + (endAngle - startAngle) / 2;
    const midPoint = polarToCartesian(0, 0, radius, midAngle);
    const rightSide = midAngle < 180;
    const distance = radius + lineLength;

    const text =
      truncate(segment.value, {
        length: maxLabelLength - (showPercent ? 5 : 0),
        omission: '...',
      }) + (showPercent ? ', ' + Math.round(segment.percent * 100) + '%' : '');
    return (
      <G>
        <Line
          x1={midPoint.x}
          y1={midPoint.y}
          x2={rightSide ? distance : -1 * distance}
          y2={midPoint.y}
          stroke={segment.color}
        />
        <Text
          fontSize={size}
          x={rightSide ? distance + 4 : -1 * (distance + 4)}
          y={midPoint.y}
          fill={color}
          textAnchor={rightSide ? 'start' : 'end'}
          alignmentBaseline="middle">
          {text}
        </Text>
      </G>
    );
  }

  render() {
    const {
      data,
      trackerColor,
      labelTextColor,
      labelFontSize,
      emptyColor,
      svg,
      possibleAnswers,
      noAnswerPlaceholder,
      diameter,
      circleStrokeWidth,
      showNoAnswer,
    } = this.props;

    const { G, Path, Circle } = svg;

    const radius = diameter - circleStrokeWidth;

    const colorMapper = getMultipleChoiceAnswerColorMapper({
      baseColor: trackerColor,
      noAnswerColor: emptyColor,
      possibleAnswers,
    });

    // segments to draw as part of the pie
    const pieSegments = [];

    const numDaysWithNoAnswer = data.filter(
      d => d.value === null || d.value === undefined || !possibleAnswers.find(a => a === d.value)
    ).length;

    // default - don't count no answer
    let totalDaysInPie = data.length - numDaysWithNoAnswer;

    // special case - no answer;
    // OR an answer that's no longer in the list of possible answers
    if (showNoAnswer) {
      totalDaysInPie = data.length;
      pieSegments.push({
        percent: numDaysWithNoAnswer / totalDaysInPie,
        value: noAnswerPlaceholder,
        color: emptyColor,
      });
    }

    possibleAnswers.forEach(possibleAnswer => {
      const percent = data.filter(d => d.value === possibleAnswer).length / totalDaysInPie;
      // don't include 0% items
      if (percent > 0) {
        pieSegments.push({
          percent: data.filter(d => d.value === possibleAnswer).length / totalDaysInPie,
          value: possibleAnswer,
          color: colorMapper(possibleAnswer),
        });
      }
    });

    const distanceFromCircleToCaption = circleStrokeWidth;

    return (
      <G>
        {pieSegments.map(
          (segment, index) =>
            segment.percent < 1 && segment.percent > 0 ? (
              <G key={index.toString()}>
                <Path
                  stroke={segment.color}
                  strokeWidth={circleStrokeWidth}
                  fill="none"
                  d={describeArc(
                    0,
                    0,
                    radius,
                    startAngForSegIndex(pieSegments, index),
                    endAngForSegIndex(pieSegments, index)
                  )}
                />
                {this.renderLabel(
                  segment,
                  radius,
                  startAngForSegIndex(pieSegments, index),
                  endAngForSegIndex(pieSegments, index),
                  distanceFromCircleToCaption /*labelDistance*/,
                  labelFontSize,
                  labelTextColor
                )}
              </G>
            ) : (
              /* takes up whole pie */ <G key={index.toString()}>
                <Circle
                  stroke={segment.color}
                  strokeWidth={circleStrokeWidth}
                  fill="none"
                  cx="0"
                  cy="0"
                  r={radius}
                />
                {this.renderLabel(
                  segment,
                  radius,
                  0,
                  180,
                  distanceFromCircleToCaption /*labelDistance*/,
                  labelFontSize,
                  labelTextColor
                )}
              </G>
            )
        )}
      </G>
    );
  }
}
