import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import graphStyleDefaults from './graphStyleDefaults';
import TouchTrackerOverlay from './util/TouchTrackerOverlay';
import CommonGraphSpacingProvider from './util/NewCommonGraphSpacingProvider';
import ColumnHighlight from './util/ColumnHighlight';
import YAxisLine from './util/YAxisLine';

class BarGraph extends Component {
  getGraphProps = () => {
    const {
      data,
      height,
      greenTarget,
      width,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
      paddingLeft,
      paddingRight,
      paddingBottom,
      paddingTop,
    } = this.props;

    const {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      dataMarkerStartY,
      barWidth,
      xAxisCaptionY,
    } = CommonGraphSpacingProvider.getCommonSpacingProps({
      numDates: data.length,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      graphWidth: width,
      graphHeight: height,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
    });

    let maxValue = Math.max.apply(null, data.map(g => g.value));
    // this bump up keeps Android from crashing due to trying to convert a null to a double
    // (probably divide-by-zero error)
    if (maxValue < 1) {
      maxValue = 1;
    }
    // this bump up ensures that the target line shows even if there is no data, or all the data
    // is less than the target.
    if (maxValue < greenTarget) {
      maxValue = greenTarget + greenTarget * 0.1;
    }

    // if no targets, show midpoints as y axis lines
    // UPDATE: we're going to do max and halfway to max so these lines stop shifting
    // in weird ways mid-entry
    let midPoint1 = Math.ceil(maxValue / 2);
    const midPoint2 = Math.ceil(maxValue);
    if (midPoint1 === midPoint2) {
      // cancel out midPoint1 if they're too close, so it doesn't appear
      midPoint1 = 0;
    }

    return {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      barWidth,
      maxValue,
      dataMarkerStartY,
      xAxisCaptionY,
      midPoint1,
      midPoint2,
    };
  };

  render() {
    const {
      data,
      greenTarget,
      yellowTarget,
      xAxisLabelType,
      color,
      height,
      width,
      formatDateAsWeekday,
      formatDateAsDayOfMonth,
      axisMarkerFontSize,
      axisMarkerTextColor,
      xAxisMarkerTextColor,
      svg,
      onHoverOverDate,
      onPressDate,
      unselectedDateOpacity,
      selectedDate,
      showMidpointLines,
      selectionBoxFillColor,
      selectionBoxStrokeColor,
      selectionBoxCornerRadius,
      formatYAxisValue,
      paddingLeft,
      paddingRight,
      yAxisLineProps,
      useSkinnyLine,
    } = this.props;

    const { G, Text, Svg, Rect } = svg;

    const graphProps = this.getGraphProps();
    const {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      dataMarkerStartY,
      barWidth,
      maxValue,
      xAxisCaptionY,
      midPoint1,
      midPoint2,
    } = graphProps;

    const fillColor = color;

    const selectedIndex = selectedDate ? findIndex(data, d => d.date === selectedDate) : -1;

    // y axis props
    const commonTargetLineProps = {
      formatYAxisValue,
      width,
      axisMarkerFontSize,
      axisMarkerTextColor,
      svg,
      drawHeight: maxBarHeight,
      minValue: 0,
      tipHeight: 0,
      fixedLeftOffset: paddingLeft,
      fixedRightOffset: paddingRight,
      ...graphProps,
      ...yAxisLineProps,
    };

    return (
      <Svg height={height} width={width}>
        <G x="0" y={dataMarkerStartY}>
          <YAxisLine target={yellowTarget} highestTarget={greenTarget} {...commonTargetLineProps} />
          <YAxisLine target={greenTarget} highestTarget={greenTarget} {...commonTargetLineProps} />
          {showMidpointLines && !yellowTarget && !greenTarget
            ? [
                <YAxisLine
                  key="1"
                  target={midPoint1}
                  highestTarget={midPoint2}
                  {...commonTargetLineProps}
                />,
                <YAxisLine
                  key="2"
                  target={midPoint2}
                  highestTarget={midPoint2}
                  {...commonTargetLineProps}
                />,
              ]
            : null}
        </G>
        <ColumnHighlight
          svg={svg}
          height={height}
          selectionBoxCornerRadius={selectionBoxCornerRadius}
          selectionBoxFillColor={selectionBoxFillColor}
          selectionBoxStrokeColor={selectionBoxStrokeColor}
          dataMarkerStartX={dataMarkerStartX}
          columnWidth={columnWidth}
          barWidth={barWidth}
          selectedIndex={selectedIndex}
          maxBarHeight={maxBarHeight}
          dataMarkerStartY={dataMarkerStartY}
          showAxisDash={xAxisLabelType === 'none'}
          useSkinnyLine={useSkinnyLine || xAxisLabelType === 'everyXDays'}
          xAxisCaptionY={xAxisCaptionY}
          axisMarkerFontSize={axisMarkerFontSize}
          xAxisMarkerTextColor={xAxisMarkerTextColor}
        />
        {data.map((value, index) => {
          // smallest possible bar is a round circle, regardless of value
          const barHeight = Math.max(
            (value.value / maxValue) * maxBarHeight,
            value.value > 0 ? barWidth : 0
          );
          return (
            <G id="Group" x="0" y="0" key={value.date}>
              <G x={dataMarkerStartX} y={0}>
                <G id="bar" x={index * columnWidth} y="0">
                  {xAxisLabelType === 'weekdays' && (
                    <Text
                      fontSize={axisMarkerFontSize}
                      fill={xAxisMarkerTextColor}
                      fillOpacity={
                        selectedDate && value.date !== selectedDate ? unselectedDateOpacity : 1
                      }
                      x={columnWidth / 2}
                      y={xAxisCaptionY}
                      weight="bold"
                      textAnchor="middle">
                      {formatDateAsWeekday(value.date)}
                    </Text>
                  )}
                  {xAxisLabelType === 'everyXDays' && index % 4 === 0 ? (
                    <Text
                      fontSize={axisMarkerFontSize}
                      fill={xAxisMarkerTextColor}
                      fillOpacity={
                        selectedDate && value.date !== selectedDate ? unselectedDateOpacity : 1
                      }
                      x={columnWidth / 2}
                      y={xAxisCaptionY}
                      weight="bold"
                      textAnchor="middle">
                      {formatDateAsDayOfMonth(value.date)}
                    </Text>
                  ) : null}
                  {xAxisLabelType === 'everyPoint' ? (
                    <Text
                      fontSize={axisMarkerFontSize}
                      fill={xAxisMarkerTextColor}
                      fillOpacity={
                        selectedDate && value.date !== selectedDate ? unselectedDateOpacity : 1
                      }
                      x={columnWidth / 2}
                      y={xAxisCaptionY}
                      weight="bold"
                      textAnchor="middle">
                      {formatDateAsDayOfMonth(value.date)}
                    </Text>
                  ) : null}
                  <Rect
                    id="Rectangle-2"
                    fill={fillColor}
                    fillOpacity={
                      selectedDate && value.date !== selectedDate ? unselectedDateOpacity : 1
                    }
                    strokeWidth="1"
                    stroke="rgba(255,255,255,.3)"
                    x={columnWidth / 2 - barWidth / 2}
                    y={dataMarkerStartY + maxBarHeight - barHeight}
                    width={barWidth}
                    height={barHeight}
                    rx={barWidth / 2}
                    ry={barWidth / 2}
                  />
                </G>
              </G>
            </G>
          );
        })}
        {onPressDate || onHoverOverDate ? (
          <TouchTrackerOverlay
            svg={svg}
            height={height}
            width={width}
            selectedTooltipIndex={selectedIndex}
            onHoverOverIndex={
              onHoverOverDate
                ? index => {
                    if (index < data.length) {
                      onHoverOverDate(data[index].date);
                    }
                  }
                : undefined
            }
            onPressIndex={
              onPressDate
                ? index => {
                    if (index < data.length) {
                      onPressDate(data[index].date);
                    }
                  }
                : undefined
            }
            graphStartingXPos={dataMarkerStartX}
            graphDataPointColumnWidth={columnWidth}
            numDataPoints={data.length}
          />
        ) : null}
      </Svg>
    );
  }
}

BarGraph.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  // color for the bars
  color: PropTypes.string.isRequired,
  xAxisLabelType: PropTypes.oneOf(['weekdays', 'everyXDays', 'none', 'everyPoint']),
  // number that represents the top target for the graph
  greenTarget: PropTypes.number,
  // number that represents the middle target for the graph
  // if neither yellow nor green targets are specified,
  // the graph will add some "midpoint" lines
  // Note that the graph does not currently color the targets yellow or green!
  yellowTarget: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ).isRequired,
  // SVG wants absolute dimensions
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  // axis colors/ sizes
  axisMarkerTextColor: PropTypes.string,
  axisMarkerFontSize: PropTypes.number,
  axisMarkerDescenderHeight: PropTypes.number,
  // selection colors/ sizes
  // reduce opacity for unselected dates
  unselectedDateOpacity: PropTypes.number,
  selectionBoxFillColor: PropTypes.string,
  selectionBoxStrokeColor: PropTypes.string,
  selectionBoxHorizontalPadding: PropTypes.number,
  selectionBoxCornerRadius: PropTypes.number,
  // ???
  formatYAxisValue: PropTypes.func,
  // override date values
  formatDateAsWeekday: PropTypes.func,
  formatDateAsDayOfMonth: PropTypes.func,
  // spacing at edges of graph
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  // *** toggle display modes ***
  // called with date when user drags/ hovers over date
  onHoverOverDate: PropTypes.func,
  // called with date when user presses date
  onPressDate: PropTypes.func,
  // date for which to show tooltip
  selectedDate: PropTypes.string,
  // if true, show midpoint lines when there are no yellow/ green targets
  showMidpointLines: PropTypes.bool,
  useSkinnyLine: PropTypes.bool,
};

BarGraph.defaultProps = {
  greenTarget: 0,
  yellowTarget: 0,
  formatYAxisValue: graphStyleDefaults.formatYAxisValue,
  formatDateAsWeekday: graphStyleDefaults.formatDateAsWeekday,
  formatDateAsDayOfMonth: graphStyleDefaults.formatDateAsDayOfMonth,
  xAxisLabelType: 'none',
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 16,
  paddingTop: 16,
  axisMarkerFontSize: graphStyleDefaults.axisMarkerFontSize,
  axisMarkerDescenderHeight: graphStyleDefaults.axisMarkerDescenderHeight,
  axisMarkerTextColor: graphStyleDefaults.axisMarkerTextColor,
  // *** toggle display modes ***
  // called with date when user drags/ hovers over date
  onHoverOverDate: () => {},
  // called with date when user presses date
  onPressDate: null,
  // reduce opacity for unselected dates
  unselectedDateOpacity: 1,
  // date for which to show tooltip
  selectedDate: null,
  showMidpointLines: false,
  selectionBoxFillColor: 'white',
  selectionBoxStrokeColor: graphStyleDefaults.axisMarkerTextColor,
  selectionBoxCornerRadius: 5,
  selectionBoxHorizontalPadding: 16,
  useSkinnyLine: false,
};

export default BarGraph;
