import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class YAxisLine extends Component {
  render() {
    const {
      formatYAxisValue,
      fixedLeftOffset,
      fixedRightOffset,
      width,
      axisMarkerFontSize,
      svg,
      axisMarkerTextColor,
      axisMarkerFontWeight,
      target,
      highestTarget,
      /* "graphProps" from BarGraph */
      drawHeight,
      minValue,
      maxValue,
      yAxisRightLabel,
      // number of pixels to knock line up or down a bit
      bumpPixels,
      reverseLabels,
      axisMarkerIndent,
      invertedY,
      isDashedLine,
    } = this.props;
    const { G, Text, Line } = svg;
    if (target !== null) {
      // highest target used to calculate starting point of line so its uniform
      // hard to make this consistent between graphs, though- might need another way
      const targetLineX = /*sizes.medium*/ fixedLeftOffset
        ? fixedLeftOffset
        : formatYAxisValue(highestTarget).length * 10;
      const targetY = invertedY
        ? drawHeight * ((target - minValue) / (maxValue - minValue))
        : drawHeight - drawHeight * ((target - minValue) / (maxValue - minValue));

      // if target is not highest target but it is too close to highest target
      // this might not work great for three lines, but those are usually low/ mid/ high anyway
      if (target !== highestTarget && !invertedY) {
        const highestTargetY =
          drawHeight - drawHeight * ((highestTarget - minValue) / (maxValue - minValue));
        if (targetY <= highestTargetY + axisMarkerFontSize + 2) {
          return null;
        }
      }

      const valueLabel = formatYAxisValue(target);

      const leftLabel = reverseLabels ? yAxisRightLabel : valueLabel;
      const rightLabel = reverseLabels ? valueLabel : yAxisRightLabel;

      return (
        <G key={target} x="0" y={targetY - axisMarkerFontSize + bumpPixels}>
          <Text
            fill={axisMarkerTextColor}
            fontSize={axisMarkerFontSize}
            x={/*sizes.medium*/ 0 + axisMarkerIndent}
            y="15"
            fontWeight={axisMarkerFontWeight}>
            {leftLabel}
          </Text>
          <Line
            x1={targetLineX}
            y1="11"
            x2={width - fixedRightOffset}
            y2="11"
            stroke="black"
            opacity=".25"
            strokeWidth=".3"
            strokeDasharray={isDashedLine ? '3,3' : undefined}
          />
          {yAxisRightLabel && (
            <Text
              fill={axisMarkerTextColor}
              fontSize={axisMarkerFontSize}
              x={width - axisMarkerIndent}
              textAnchor="end"
              y="15"
              fontWeight={axisMarkerFontWeight}>
              {rightLabel}
            </Text>
          )}
        </G>
      );
    }
    return null;
  }
}

YAxisLine.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  // Absolute width of the graph (unless you don't want this all the way across the graph)
  width: PropTypes.number.isRequired,
  // The actual height of the graphable area
  // So total height minus any elements that should not contain the actual graph
  drawHeight: PropTypes.number.isRequired,
  // This will be added to the line's height
  // Use this to nudge it up or down so it doesn't touch data points directly
  bumpPixels: PropTypes.number,
  // Styles for the things that can be styled
  axisMarkerFontSize: PropTypes.number.isRequired,
  // color will also be used for the line
  axisMarkerTextColor: PropTypes.string.isRequired,
  axisMarkerFontWeight: PropTypes.any,
  // Functions for formatting the values that appear via this component
  // Takes a value and returns a string
  formatYAxisValue: PropTypes.func.isRequired,
  // optional, if specified, ignore formatYAxisValue for setting left offset before line starts,
  // use a fixed value instead
  fixedLeftOffset: PropTypes.number,
  // should just call this the right offset, but, oh well
  fixedRightOffset: PropTypes.number,
  // The value that this actual line will be for
  target: PropTypes.number,
  // The highest target that will be shown on the graph
  // This should be the same as target if there is only one y axis line
  // This is used with formatYAxisValue to determine how wide the area for the label should be
  // So, all targets get uniform labels as long as highestTarget is the same for all lines.
  highestTarget: PropTypes.number,
  // target will be positioned somewhere between where a minValue and maxValue line would be on
  // the graph, relative to the drawHeight
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  // optional right side label
  yAxisRightLabel: PropTypes.string,
  // a bit of a hack to put the value label on the left, used for heart rate currently
  reverseLabels: PropTypes.bool,
  // how much to indent the axis marker from the edges
  axisMarkerIndent: PropTypes.number,
  invertedY: PropTypes.bool,
  isDashedLine: PropTypes.bool,
};

YAxisLine.defaultProps = {
  bumpPixels: 0,
  target: 0,
  highestTarget: 0,
  fixedLeftOffset: 0,
  fixedRightOffset: 0,
  yAxisRightLabel: null,
  reverseLabels: false,
  axisMarkerIndent: 0,
  invertedY: false,
  axisMarkerFontWeight: 100,
  isDashedLine: false,
};
