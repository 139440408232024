export default class CommonGraphSpacingProvider {
  static getCommonSpacingProps({
    numDates,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    graphWidth,
    graphHeight,
    axisMarkerFontSize,
    axisMarkerDescenderHeight,
    aboveGraphContentHeight = 0,
    maxDataMarkerWidth = 11,
  }) {
    const maxGraphWidth = graphWidth;
    const availableColumnWidth = (maxGraphWidth - paddingLeft - paddingRight) / numDates;
    const barWidth = Math.min(availableColumnWidth - 4, maxDataMarkerWidth);
    //const extraColumnSpace = availableColumnWidth - barWidth;
    // NOTE: this value doesn't work for line graphs yet because barWidth is incorporated
    const columnWidth = availableColumnWidth;
    const maxBarHeight =
      graphHeight -
      aboveGraphContentHeight -
      paddingTop -
      paddingBottom -
      axisMarkerFontSize -
      axisMarkerDescenderHeight;

    let dataMarkerStartX = paddingLeft;
    let dataMarkerStartY = paddingTop;

    // height from very top of graph to the xAxis text markers
    const xAxisHeight = maxBarHeight;

    // where to write dates or a canned caption below bars, etc.
    const xAxisCaptionY = dataMarkerStartY + maxBarHeight + axisMarkerFontSize + axisMarkerDescenderHeight;

    return {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      dataMarkerStartY,
      barWidth,
      xAxisHeight,
      xAxisCaptionY,
    };
  }
}
