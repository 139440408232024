
import { NativeModules, NativeEventEmitter } from 'react-native';
import HKSampleTypes from './HKSampleTypes';
import SHealthDataTypes from './SHealthDataTypes';

const { RNNudgeLib, ValidicHealthKit, ValidicSHealth } = NativeModules;

class ValidicEventEmitter {
  _nativeEmitter;
  constructor() {
    if (ValidicHealthKit) {
      this._nativeEmitter = new NativeEventEmitter(ValidicHealthKit);
    }
    // haven't added this yet for SHealth
    if (ValidicSHealth) {
      this._nativeEmitter = new NativeEventEmitter(ValidicSHealth);
    }
  }

  addListener(onRecordUploadSuccess, onRecordUploadError) {
    if (this._nativeEmitter) {
      const unsubscribers = [];
      unsubscribers.push(this._nativeEmitter.addListener(
        'ValidicOnRecordSubmitted',
        onRecordUploadSuccess ? onRecordUploadSuccess : () => {},
      ));
      unsubscribers.push(this._nativeEmitter.addListener(
        'ValidicOnRecordSubmitFailed',
        onRecordUploadError ? onRecordUploadError : () => {},
      ));

      return () => {
        unsubscribers.forEach(unsubscriber => {
          unsubscriber();
        });
      }
    }
    return () => {};
  }
}

export { RNNudgeLib, ValidicHealthKit, ValidicSHealth, HKSampleTypes, SHealthDataTypes, ValidicEventEmitter };
