import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { findIndex } from 'lodash';
import graphStyleDefaults from './graphStyleDefaults';
import TouchTrackerOverlay from './util/TouchTrackerOverlay';
import ColumnHighlight from './util/ColumnHighlight';
import CommonGraphSpacingProvider from './util/NewCommonGraphSpacingProvider';
import YAxisLine from './util/YAxisLine';

class CaloriesGraph extends Component {
  getGraphProps = () => {
    const {
      data,
      height,
      target,
      width,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
      paddingLeft,
      paddingRight,
      paddingBottom,
      paddingTop,
    } = this.props;

    const {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      dataMarkerStartY,
      barWidth,
      xAxisCaptionY,
    } = CommonGraphSpacingProvider.getCommonSpacingProps({
      numDates: data.length,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      graphWidth: width,
      graphHeight: height,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
    });

    let maxValue = Math.max.apply(null, data.map(g => g.value.calories));
    // this bump up keeps Android from crashing due to trying to convert a null to a double
    // (probably divide-by-zero error)
    if (maxValue < 1) {
      maxValue = 1;
    }
    // this bump up ensures that the target line shows even if there is no data, or all the data
    // is less than the target.
    if (maxValue < target) {
      maxValue = target + target * 0.1;
    }

    return {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      barWidth,
      maxValue,
      dataMarkerStartY,
      xAxisCaptionY,
    };
  };

  _renderBarSegments = ({ x, y, height, width, value, index, fillOpacity }) => {
    const { carbsColor, fatsColor, proteinsColor, caloriesColor, workaroundClipPathBug, svg } = this.props;

    const { Rect, G, Defs, ClipPath } = svg;

    // still show something even if there's no macronutrients - just a solid bar with the calories color
    if (!value.carbs && !value.fats && !value.proteins) {
      return (
        <Rect
          key="Rectangle-1"
          fill={caloriesColor}
          strokeWidth="0"
          stroke="rgba(255,255,255,.3)"
          x={x}
          y={y}
          width={width}
          height={height}
          rx={width / 2}
          ry={width / 2}
          fillOpacity={fillOpacity}
        />
      );
    }

    const totalNutrients = value.carbs + value.fats + value.proteins;

    const carbsHeight = (value.carbs / totalNutrients) * height;
    const fatsHeight = (value.fats / totalNutrients) * height;
    const proteinsHeight = (value.proteins / totalNutrients) * height;

    const tipRadius = width / 2;

    let clipPathId;

    if (workaroundClipPathBug) {
      // On android, the clip path doesn't seem to get updated on Expo 44 when adding a second
      // calories record, so the bar gets higher, but clips at the original height
      clipPathId = `roundCorners-${index}-${height}`;
    } else {
      clipPathId = `roundCorners-${index}`;
    }

    return (
      <G>
        <Defs>
          <ClipPath id={clipPathId}>
            <Rect x={0} y={0} width={width} height={height} rx={tipRadius} ry={tipRadius} />
          </ClipPath>
        </Defs>
        <G x={x} y={y} height={height} width={width} clipPath={`url(#${clipPathId})`}>
          <Rect
            key="Rectangle-1"
            fill={carbsColor}
            strokeWidth="0"
            stroke="rgba(255,255,255,.3)"
            x={0}
            y={0}
            rx={0}
            ry={0}
            width={width}
            height={carbsHeight}
            fillOpacity={fillOpacity}
          />
          <Rect
            key="Rectangle-2"
            fill={fatsColor}
            strokeWidth="0"
            stroke="rgba(255,255,255,.3)"
            x={0}
            y={carbsHeight}
            rx={0}
            ry={0}
            width={width}
            height={fatsHeight}
            fillOpacity={fillOpacity}
          />
          <Rect
            key="Rectangle-3"
            fill={proteinsColor}
            strokeWidth="0"
            stroke="rgba(255,255,255,.3)"
            x={0}
            y={carbsHeight + fatsHeight}
            rx={0}
            ry={0}
            width={width}
            height={proteinsHeight}
            fillOpacity={fillOpacity}
          />
        </G>
      </G>
    );
  };

  render() {
    const {
      data,
      xAxisLabelType,
      height,
      width,
      formatDateAsWeekday,
      formatDateAsDayOfMonth,
      axisMarkerFontSize,
      axisMarkerTextColor,
      svg,
      onHoverOverDate,
      onPressDate,
      selectedDate,
      selectionBoxCornerRadius,
      selectionBoxFillColor,
      selectionBoxStrokeColor,
      unselectedDateOpacity,
      xAxisMarkerTextColor,
      formatYAxisValue,
      target,
      paddingLeft,
      paddingRight,
      yAxisLineProps,
      useSkinnyLine,
    } = this.props;

    const { G, Text, Svg } = svg;

    const graphProps = this.getGraphProps();
    const {
      columnWidth,
      maxBarHeight,
      dataMarkerStartX,
      barWidth,
      maxValue,
      dataMarkerStartY,
      xAxisCaptionY,
    } = graphProps;

    const selectedIndex = selectedDate ? findIndex(data, d => d.date === selectedDate) : -1;

    const commonTargetLineProps = {
      formatYAxisValue,
      width,
      axisMarkerFontSize,
      axisMarkerTextColor,
      svg,
      drawHeight: maxBarHeight,
      minValue: 0,
      tipHeight: 0,
      fixedLeftOffset: paddingLeft,
      fixedRightOffset: paddingRight,
      ...graphProps,
      ...yAxisLineProps,
    };

    return (
      <Svg height={height} width={width}>
        <G x="0" y={dataMarkerStartY}>
          <YAxisLine target={target} highestTarget={target} {...commonTargetLineProps} />
        </G>
        <ColumnHighlight
          svg={svg}
          height={height}
          selectionBoxCornerRadius={selectionBoxCornerRadius}
          selectionBoxFillColor={selectionBoxFillColor}
          selectionBoxStrokeColor={selectionBoxStrokeColor}
          dataMarkerStartX={dataMarkerStartX}
          columnWidth={columnWidth}
          barWidth={barWidth}
          selectedIndex={selectedIndex}
          maxBarHeight={maxBarHeight}
          dataMarkerStartY={dataMarkerStartY}
          showAxisDash={xAxisLabelType === 'none'}
          useSkinnyLine={useSkinnyLine || xAxisLabelType === 'everyXDays'}
          xAxisCaptionY={xAxisCaptionY}
          axisMarkerFontSize={axisMarkerFontSize}
          xAxisMarkerTextColor={xAxisMarkerTextColor}
        />
        <G x="0" y="0">
          {data.map((dataPoint, index) => {
            // smallest possible bar is a round circle, regardless of value
            const barHeight = Math.max(
              (dataPoint.value.calories / maxValue) * maxBarHeight,
              dataPoint.value.calories > 0 ? barWidth : 0
            );
            return (
              <G id="Group" x="0" y="0" key={dataPoint.date}>
                <G x={dataMarkerStartX} y="0">
                  <G id="bar" x={index * columnWidth} y="0">
                    {xAxisLabelType === 'weekdays' && (
                      <Text
                        fontSize={axisMarkerFontSize}
                        fill={xAxisMarkerTextColor}
                        fillOpacity={
                          selectedDate && dataPoint.date !== selectedDate
                            ? unselectedDateOpacity
                            : 1
                        }
                        x={columnWidth / 2}
                        y={xAxisCaptionY}
                        weight="bold"
                        textAnchor="middle">
                        {formatDateAsWeekday(dataPoint.date)}
                      </Text>
                    )}
                    {xAxisLabelType === 'everyXDays' && index % 4 === 0 ? (
                      <Text
                        fontSize={axisMarkerFontSize}
                        fill={xAxisMarkerTextColor}
                        fillOpacity={
                          selectedDate && dataPoint.date !== selectedDate
                            ? unselectedDateOpacity
                            : 1
                        }
                        x={columnWidth / 2}
                        y={xAxisCaptionY}
                        weight="bold"
                        textAnchor="middle">
                        {formatDateAsDayOfMonth(dataPoint.date)}
                      </Text>
                    ) : null}
                    {this._renderBarSegments({
                      x: columnWidth / 2 - barWidth / 2,
                      y: dataMarkerStartY + maxBarHeight - barHeight,
                      value: dataPoint.value,
                      width: barWidth,
                      height: barHeight,
                      index,
                      fillOpacity:
                        selectedDate && dataPoint.date !== selectedDate ? unselectedDateOpacity : 1,
                    })}
                  </G>
                </G>
              </G>
            );
          })}
          {onPressDate || onHoverOverDate ? (
            <TouchTrackerOverlay
              svg={svg}
              height={height}
              width={width}
              selectedTooltipIndex={selectedIndex}
              onHoverOverIndex={
                onHoverOverDate
                  ? index => {
                      if (index < data.length) {
                        onHoverOverDate(data[index].date);
                      }
                    }
                  : undefined
              }
              onPressIndex={
                onPressDate
                  ? index => {
                      if (index < data.length) {
                        onPressDate(data[index].date);
                      }
                    }
                  : undefined
              }
              graphStartingXPos={dataMarkerStartX}
              graphDataPointColumnWidth={columnWidth}
              numDataPoints={data.length}
            />
          ) : null}
        </G>
      </Svg>
    );
  }
}

CaloriesGraph.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  // colors for the bars
  proteinsColor: PropTypes.string.isRequired,
  fatsColor: PropTypes.string.isRequired,
  carbsColor: PropTypes.string.isRequired,
  // only used when there's no other data
  caloriesColor: PropTypes.string.isRequired,
  tooltipBackgroundColor: PropTypes.string,
  xAxisLabelType: PropTypes.oneOf(['weekdays', 'everyXDays', 'none']),
  // number that represents the top target for the graph
  // This will be the number of calories
  target: PropTypes.number,
  // data array for calories.
  // Note that is has a value object with four different values
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.shape({
        calories: PropTypes.number,
        proteins: PropTypes.number,
        fats: PropTypes.number,
        carbs: PropTypes.number,
      }),
    })
  ).isRequired,
  // SVG wants absolute dimensions
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  // The value here will be a number (since targets can only be numeric)
  formatYAxisValue: PropTypes.func,
  // override date values
  formatDateAsWeekday: PropTypes.func,
  formatDateAsDayOfMonth: PropTypes.func,
  // style stuff
  axisMarkerFontSize: PropTypes.number,
  axisMarkerDescenderHeight: PropTypes.number,
  axisMarkerTextColor: PropTypes.string,
  // spacing props
  // this function will be called whenever the user clicks
  // use this to help "center" the margin
  // everything is based on the left because we need to align drawings and variable-width text
  // So, tricky!
  // Make this number larger on larger graph widths to at least get it sort of centered.
  legendLeftMargin: PropTypes.number,
  // *** toggle display modes ***
  // called with date when user drags/ hovers over date
  onHoverOverDate: PropTypes.func,
  // called with date when user presses date
  onPressDate: PropTypes.func,
  // reduce opacity for unselected dates
  unselectedDateOpacity: PropTypes.number,
  // date for which to show tooltip
  selectedDate: PropTypes.string,
  // spacing at edges of graph
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  selectionBoxFillColor: PropTypes.string,
  selectionBoxStrokeColor: PropTypes.string,
  selectionBoxHorizontalPadding: PropTypes.number,
  selectionBoxCornerRadius: PropTypes.number,
  useSkinnyLine: PropTypes.bool,
  // workaround Android bug where it seems to keep the first clip path when adding records
  // leading to the bar not going all the way to the bottom
  workaroundClipPathBug: PropTypes.bool,
};

CaloriesGraph.defaultProps = {
  target: 0,
  formatYAxisValue: val => numeral(val).format('0a'),
  formatDateAsWeekday: graphStyleDefaults.formatDateAsWeekday,
  formatDateAsDayOfMonth: graphStyleDefaults.formatDateAsDayOfMonth,
  axisMarkerFontSize: graphStyleDefaults.axisMarkerFontSize,
  axisMarkerDescenderHeight: graphStyleDefaults.axisMarkerDescenderHeight,
  axisMarkerTextColor: graphStyleDefaults.axisMarkerTextColor,
  xAxisLabelType: 'none',
  // called with date when user drags/ hovers over date
  onHoverOverDate: () => {},
  // called with date when user presses date
  onPressDate: () => {},
  // reduce opacity for unselected dates
  unselectedDateOpacity: 1,
  // date for which to show tooltip
  selectedDate: null,
  selectionBoxFillColor: 'white',
  selectionBoxStrokeColor: graphStyleDefaults.axisMarkerTextColor,
  selectionBoxCornerRadius: 5,
  selectionBoxHorizontalPadding: 16,
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 16,
  paddingTop: 16,
  useSkinnyLine: false,
  workaroundClipPathBug: false,
};

export default CaloriesGraph;
