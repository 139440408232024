import React, { Component } from 'react';
import PropTypes from 'prop-types';
import graphStyleDefaults from './graphStyleDefaults';
import YAxisLine from './util/YAxisLine';
import MultiLineGraph from './MultiLineGraph';
import CommonGraphSpacingProvider from './util/NewCommonGraphSpacingProvider';

class LineGraph extends Component {
  render() {
    const {
      head,
      tail,
      data,
      height,
      width,
      color,
      svg,
      formatYAxisValue,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
      axisMarkerTextColor,
      yAxisLineMode,
      yAxisLineProps,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      minDelta,
    } = this.props;

    const { G } = svg;

    let nonNullData = data.filter(d => d.value !== null && d.value !== undefined);

    const { maxBarHeight } = CommonGraphSpacingProvider.getCommonSpacingProps({
      numDates: data.length,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      graphWidth: width,
      graphHeight: height,
      axisMarkerFontSize,
      axisMarkerDescenderHeight,
    });

    // incorporate the tail and head into the min/max if needed, as the graph will try to draw a line between
    // head and tail (or just flat line tail if needed)
    // OR... just use it all the time? Seems like it might actually give some better context when moving between values
    //if (!nonNullData.length) {
    if (tail) {
      nonNullData.push(tail);
    }
    if (head) {
      nonNullData.push(head);
    }
    //}
    let maxValue = Math.max.apply(null, nonNullData.map(g => g.value));
    let minValue = Math.min.apply(null, nonNullData.map(g => g.value));

    let graphMinValue = minValue;
    let graphMaxValue = maxValue;

    // increase the graph min/ max if values are too tight together

    // new rules, set a min delta for standard line graphs, use if values are too close
    if (minDelta) {
      if (maxValue - minValue < minDelta) {
        const diffBetweenDeltaAndMinDelta = minDelta - (maxValue - minValue);
        graphMaxValue += diffBetweenDeltaAndMinDelta / 2;
        graphMinValue -= diffBetweenDeltaAndMinDelta / 2;
      }
    } else {
      // old rules - try to make min/ max a function of the max value if values are too close, ignore otherwise
      let topBottomBuffer = maxValue * 1.1 - maxValue;
      if (maxValue - minValue >= topBottomBuffer) {
        topBottomBuffer = 0;
      }
      graphMaxValue = maxValue + topBottomBuffer;
      graphMinValue = minValue - topBottomBuffer;
    }
    if (graphMinValue < 0) {
      graphMinValue = 0;
    }

    // should be impossible at this point
    if (graphMinValue === graphMaxValue) {
      graphMinValue = graphMaxValue / 2;
      // takes care of zero across the board
      // which should be impossible
      if (graphMaxValue === graphMinValue) {
        graphMaxValue = 1;
      }
    }

    // apply fixed min/ max values at this point
    if (this.props.minValue !== null && this.props.maxValue !== null) {
      graphMinValue = this.props.minValue;
      graphMaxValue = this.props.maxValue;
    }

    const commonTargetLineProps = {
      formatYAxisValue,
      width,
      axisMarkerFontSize,
      axisMarkerTextColor,
      svg,
      drawHeight: maxBarHeight,
      minValue: graphMinValue,
      maxValue: graphMaxValue,
      fixedLeftOffset: paddingLeft,
      fixedRightOffset: paddingRight,
      tipHeight: 0,
      ...yAxisLineProps,
    };

    let yAxisLines;
    if (yAxisLineMode === 'lowMidHigh') {
      const lowPoint = graphMinValue;
      const highPoint = graphMaxValue;
      const midPoint = (lowPoint + highPoint) / 2;
      yAxisLines = (
        <G>
          <YAxisLine
            key="0"
            target={lowPoint}
            highestTarget={highPoint}
            {...commonTargetLineProps}
          />
          <YAxisLine
            key="1"
            target={midPoint}
            highestTarget={highPoint}
            {...commonTargetLineProps}
          />
          <YAxisLine
            key="2"
            target={highPoint}
            highestTarget={highPoint}
            {...commonTargetLineProps}
          />
        </G>
      );
    }

    return (
      <MultiLineGraph
        {...this.props}
        color={color}
        data={data.map(d => ({ ...d, value: { value: d.value } }))}
        svg={svg}
        head={
          head ? { ...head, value: head.value ? { value: head.value } : { value: null } } : null
        }
        tail={
          tail ? { ...tail, value: tail.value ? { value: tail.value } : { value: null } } : null
        }
        toolTipValueTextColor="red"
        lineDefinitions={[
          {
            valuePropName: 'value',
            color,
            yAxisLabelSuffix: '',
          },
        ]}
        yAxisLineProps={{ reverseLabels: false }}
        alternativeYAxisLines={yAxisLines}
        overrideMinValue={graphMinValue}
        overrideMaxValue={graphMaxValue}
        yAxisLineMode="default"
      />
    );
  }
}

LineGraph.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  // color for the bars
  color: PropTypes.string.isRequired,
  // when generating automatic midpoints, how many lines to draw
  numYAxisLines: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ).isRequired,
  //Optionally include "head" and "tail" points so we can draw where the graph is "going"
  head: PropTypes.shape({
    date: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  tail: PropTypes.shape({
    date: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  // SVG wants absolute dimensions
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  xAxisLabelType: PropTypes.oneOf(['weekdays', 'everyXDays', 'none', 'everyMonth']),
  // override how to display any numerical values we show
  // Takes the value and returns the value as a number or string
  // This is applied to values and targets, so if you have values that
  // are seconds but you're formatting them as minutes, your values and targets should be seconds
  formatToolTipValue: PropTypes.func,
  formatToolTipUnits: PropTypes.func,
  formatYAxisValue: PropTypes.func,
  // override date values
  formatDateAsDayOfMonth: PropTypes.func,
  formatDateAsMonth: PropTypes.func,
  axisMarkerFontSize: PropTypes.number,
  axisMarkerDescenderHeight: PropTypes.number,
  axisMarkerTextColor: PropTypes.string,
  bottomCaptionText: PropTypes.string,
  yAxisLineMode: PropTypes.oneOf(['lowMidHigh', 'average', 'none']),
  // spacing props
  minTooltipPlusStemHeight: PropTypes.number,
  // this function will be called whenever the user clicks
  // or presses in on a data point on the graph
  onPressInDate: PropTypes.func,
  // line visuals
  lineWidth: PropTypes.number,
  pointRadius: PropTypes.number,
  // optionally fix the min/ max values of the graph
  // must set both of these for any to be used
  // useful for things with very set ranges, like Nudge Score
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  // alternative way to define range - suggest a minimum delta between min and max,
  // we'll center data around that
  minDelta: PropTypes.number,
  // snap tooltip to the nearest data point
  // useful if there are a lot of null values in the data
  snapToNearestData: PropTypes.bool,
  // passthrough props to Y Axis lines
  yAxisLineProps: PropTypes.any,
  // new tooltip stuff
  // *** toggle display modes ***
  // called with date when user drags/ hovers over date
  onHoverOverDate: PropTypes.func,
  // called with date when user presses date
  onPressDate: PropTypes.func,
  // reduce opacity for unselected dates
  unselectedTooltipDateOpacity: PropTypes.number,
  // date for which to show tooltip
  selectedDate: PropTypes.string,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

LineGraph.defaultProps = {
  numYAxisLines: 0,
  formatToolTipValue: val => val.toString(),
  formatYAxisValue: graphStyleDefaults.formatYAxisValue,
  formatToolTipUnits: () => 'units',
  formatDateAsDayOfMonth: graphStyleDefaults.formatDateAsDayOfMonth,
  formatDateAsMonth: graphStyleDefaults.formatDateAsMonth,
  axisMarkerFontSize: graphStyleDefaults.axisMarkerFontSize,
  axisMarkerDescenderHeight: graphStyleDefaults.axisMarkerDescenderHeight,
  axisMarkerTextColor: graphStyleDefaults.axisMarkerTextColor,
  yAxisLineMode: 'lowMidHigh',
  xAxisLabelType: 'none',
  bottomCaptionText: null,
  minTooltipPlusStemHeight: graphStyleDefaults.minTooltipPlusStemHeight,
  onPressInDate: () => {},
  lineWidth: 2,
  pointRadius: 3,
  minValue: null,
  maxValue: null,
  minDelta: null,
  snapToNearestData: false,
  yAxisLineProps: null,
  // called with date when user drags/ hovers over date
  onHoverOverDate: () => {},
  // called with date when user presses date
  onPressDate: null,
  // reduce opacity for unselected dates
  unselectedTooltipDateOpacity: 1,
  // date for which to show tooltip
  selectedDate: null,
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 16,
  paddingTop: 16,
};

export default LineGraph;
