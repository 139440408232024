const SHealthDataTypes = {
  DAILY_STEP_COUNT: "com.samsung.shealth.step_daily_trend",
  BLOOD_PRESSURE: "com.samsung.health.blood_pressure",
  TEMPERATURE: "com.samsung.health.body_temperature",
  HEART_RATE: "com.samsung.health.heart_rate",
  SPO2: "com.samsung.health.oxygen_saturation",
  GLUCOSE: "com.samsung.health.blood_glucose",
  HBA1C: "com.samsung.health.hba1c",
  WEIGHT: "com.samsung.health.weight",
  FOOD_INFO: "com.samsung.health.food_info",
  FOOD_INTAKE: "com.samsung.health.food_intake",
  WATER_INTAKE: "com.samsung.health.water_intake",
  CAFFEINE_INTAKE: "com.samsung.health.caffeine_intake",
  EXERCISE: "com.samsung.health.exercise",
  SLEEP: "com.samsung.health.sleep",
  SLEEP_STAGE: "com.samsung.health.sleep_stage"
}

export default SHealthDataTypes;
