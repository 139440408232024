import { DateTime } from 'luxon';

// Gets embedded definition information based on the type
// These inform the tracker.clientMeta custom properties that we add via the TrackerMetadataProvider
// Each tracker gets a virtual client-side type that dictates how it is displayed and filled in, graphed, etc.
// These definitions are shared by Nudge for clients/ coaches, with some use by Nudge Coach
// TODO: move Nudge Coach over to using these.

// fallback type that doesn't show any entry forms
const customUnknown = {
  primaryFieldUom: null,
  primaryField: 'quantity',
  zeroEntryAllowed: true,
  editor: 'number',
  canSync: false,
  validator: 'number',
  aggregateType: 'total',
  entryFields: [],
  graphConfig: {
    type: 'bar',
    summaryTimeframe: 'week',
    timeframes: ['week', 'month'],
  },
  ...commonProps,
  decimalPlaces: 2,
};

const activityOptions = [
  {
    id: 1,
    name: 'Run',
    showDistance: true,
  },
  {
    id: 2,
    name: 'Walk',
    showDistance: true,
  },
  {
    id: 3,
    name: 'Stairs',
  },
  {
    id: 4,
    name: 'Wheel Chair',
    showDistance: true,
  },
  {
    id: 5,
    name: 'Bike',
    showDistance: true,
  },
  {
    id: 6,
    name: 'Sports',
  },
  {
    id: 7,
    name: 'Hike',
    showDistance: true,
  },
  {
    id: 8,
    name: 'Fitness Class',
  },
  {
    id: 9,
    name: 'Weights',
  },
  {
    id: 10,
    name: 'Row',
    showDistance: true,
  },
  {
    id: 11,
    name: 'Hand Cycle',
    showDistance: true,
  },
  {
    id: 12,
    name: 'Elliptical',
    showDistance: true,
  },
  {
    id: 13,
    name: 'Swim',
    showDistance: true,
  },
  {
    id: 14,
    name: 'Yoga',
  },
  {
    id: 15,
    name: 'Cleaning',
  },
  {
    id: 16,
    name: 'Yard Work',
  },
  {
    id: 17,
    name: 'Martial Arts',
  },
  {
    id: 18,
    name: 'Plyometrics',
  },
  {
    id: 19,
    name: 'Dance',
  },
  {
    id: 20,
    name: 'Activity',
    showDistance: true,
  },
  {
    id: 21,
    name: 'Meditation',
  },
];

const commonProps = {
  // a ranking to apply before other ranking criteria for trackers
  // currently used for sorting regular vs. read-only trackers (heart rate)
  forcedRank: 0,
  decimalPlaces: 1,
  supportsGoals: true,
  interpolateDates: true,
};

const dividerField = {
  modelField: 'divider', // needed for list key, kind of a hack
  editor: 'divider',
  rank: 50,
};

const userTimeField = {
  modelField: 'jsUserTime',
  name: 'Time',
  rank: 99,
  validator: 'time',
  editor: 'time',
  uom: 'time',
};

const commentsField = {
  modelField: 'notes',
  name: 'Comments',
  rank: 90,
  validator: 'string',
  editor: 'freeform',
  uom: null,
  isRequired: false,
  hideIfSynced: true,
  maxLength: 1000,
};

const financeProps = {
  ...commonProps,
  entryFields: [
    {
      name: 'Total Revenue',
      modelField: 'revenue',
      editor: 'number',
      uom: '',
      rank: 0,
      isRequired: true,
    },
    {
      name: 'Total Cost of Goods Sold',
      modelField: 'cogs',
      editor: 'number',
      uom: '',
      rank: 1,
      isRequired: true,
    },
    {
      name: 'Total Gross Profit',
      modelField: 'grossProfit',
      editor: 'number',
      uom: '',
      rank: 2,
      isRequired: true,
    },
    {
      name: 'Net Income',
      modelField: 'netIncome',
      editor: 'number',
      uom: '',
      rank: 3,
      isRequired: true,
    },
  ],
  canSync: true,
  graphConfig: {
    type: 'bar',
    summaryTimeframe: 'year',
    timeframes: ['year'],
    web: {
      showStats: true,
      timeframes: ['year'],
    },
  },
  decimalPlaces: 2,
  interpolateDates: false,
  entryHeader: log => `Income Summary for ${DateTime.fromSQL(log.userTime).toFormat('MMMM, ’yy')}`,
  isReadOnly: true,
  dateFormat: 'MMM, ’yy',
};

const typeMap = {
  water: {
    entryFields: [
      {
        modelField: 'quantity',
        name: 'Servings',
        rank: 0,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: false,
        editor: 'number',
        //isPrimary: true,
        uom: 'svg',
        isRequired: true,
      },
      dividerField,
      commentsField,
      userTimeField,
    ],
    primaryFieldUom: 'svg',
    primaryField: 'quantity',
    zeroEntryAllowed: true,
    editor: 'number',
    canSync: true,
    validator: 'number',
    aggregateType: 'total',
    graphConfig: {
      greenTarget: 7,
      yellowTarget: 4,
      type: 'bar',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
    },
    ...commonProps,
    decimalPlaces: 2,
  },
  servings: {
    primaryFieldUom: 'svg',
    primaryField: 'quantity',
    entryFields: [
      {
        modelField: 'quantity',
        name: 'Servings',
        rank: 0,
        validator: 'number',
        decimals: 2,
        zeroAllowed: false,
        showUomInline: false,
        editor: 'number',
        //isPrimary: true,
        uom: 'svg',
        isRequired: true,
      },
      dividerField,
      commentsField,
      userTimeField,
    ],
    editor: 'number',
    canSync: false,
    validator: 'number',
    aggregateType: 'total',
    graphConfig: {
      type: 'bar',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
    },
    ...commonProps,
    decimalPlaces: 2,
  },
  weight: {
    entryFields: [
      {
        modelField: 'weight',
        name: null,
        rank: 0,
        validator: 'number',
        decimals: 2,
        zeroAllowed: false,
        editor: 'number',
        showUomInline: true,
        //isPrimary: true,
        uom: 'lbs',
        uom_metric: 'kg',
        isRequired: true,
      },
      dividerField,
      commentsField,
      userTimeField,
    ],
    primaryFieldUom: 'lbs',
    primaryFieldMetricUom: 'kg',
    editor: 'number',
    validator: 'number',
    canSync: true,
    primaryField: 'weight',
    aggregateType: 'latest',
    prefillWithHistorical: true,
    graphConfig: {
      type: 'line',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
      web: {
        hideLogsOnDetail: true,
        timeframes: ['month', 'quarter', 'year'],
        showLegacyStats: true,
      },
      // don't hide graph just because there's no recent data, because old data carries forward
      showSummaryIfAnyDataExists: true,
    },
    ...commonProps,
    supportsGoals: false,
  },
  pedometer: {
    entryFields: [
      {
        modelField: 'quantity',
        name: null,
        rank: 0,
        validator: 'number',
        decimals: 0,
        zeroAllowed: true,
        editor: 'number',
        uom: null,
        isRequired: true,
      },
      dividerField,
      commentsField,
      userTimeField,
    ],
    alternateName: 'Steps',
    deduplicationRule: 'activitiesAndSteps',
    editor: 'number',
    validator: 'number',
    canSync: true,
    primaryFieldUom: 'steps',
    primaryField: 'quantity',
    zeroEntryAllowed: true,
    aggregateType: 'total',
    isActivityTypeRequired: true,
    defaultActivityType: 22,
    graphConfig: {
      type: 'bar',
      greenTarget: 10000,
      yellowTarget: 5000,
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
    },
    ...commonProps,
    decimalPlaces: 0,
  },
  'total-revenue': {
    ...financeProps,
    primaryField: 'revenue',
  },
  'total-gross-profit': {
    ...financeProps,
    primaryField: 'grossProfit',
  },
  'total-gross-profit-margin': {
    ...financeProps,
    primaryField: 'grossProfitMargin',
    primaryFieldUom: 'percent',
  },
  'net-income': {
    ...financeProps,
    primaryField: 'netIncome',
  },
  'cash-flow': {
    ...financeProps,
    primaryField: 'netCashIncrease',
    entryFields: [
      {
        name: 'Cash Flow',
        modelField: 'netCashIncrease',
        editor: 'number',
        uom: '',
        rank: 0,
        isRequired: true,
      },
    ],
  },
  cardio: {
    entryFields: [
      {
        modelField: 'activityId',
        name: 'Type',
        rank: 20,
        validator: 'activity',
        editor: 'activity',
        //isPrimary: true,
        uom: null,
        isRequired: false,
      },
      {
        modelField: 'hiDuration',
        name: 'Minutes',
        rank: 0,
        validator: 'minutes',
        showUomInline: true,
        editor: 'number',
        uom: 'minutes',
        isRequired: true,
        decimals: 2,
        forceAutofocus: true, // allows us to autofocus even though this isn't the first field
      },
      dividerField,
      {
        modelField: 'distance',
        name: 'Distance',
        rank: 1,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        //isPrimary: true,
        uom: 'mi',
        uom_metric: 'km',
        isRequired: false,
        showBasedOnActivitySelected: true,
      },
      commentsField,
      userTimeField,
    ],
    alternateName: 'Activities',
    deduplicationRule: 'activitiesAndSteps',
    editor: 'duration',
    validator: 'number',
    canSync: true,
    primaryFieldUom: 'time',
    preferredTimeUnit: 'minutes',
    primaryField: 'hiDuration',
    primaryField_snakeCase: 'hi_duration',
    //api_field: 'hi_duration',
    aggregateType: 'total',
    activityOptions,
    isActivityTypeRequired: true,
    defaultActivityType: 20,
    changeActivityTypeBasedOnNotes: true,
    // basically just for cardio
    // Cardio has parent "total cardio" records along with child activity records
    // We can reorganize these or even hide some to give a better-formatted picture of the activity
    hasParentChildRelationships: true,
    graphConfig: {
      type: 'bar',
      greenTarget: 1800,
      yellowTarget: 900,
      converter: 'secondsToMinutes',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
    },
    ...commonProps,
    decimalPlaces: 0,
  },
  sleep: {
    entryFields: [
      {
        modelField: 'startTime',
        name: 'Start Time',
        rank: 0,
        validator: 'time',
        editor: 'time',
        uom: 'time',
        isRequired: true,
      },
      {
        modelField: 'endTime',
        name: 'End Time',
        rank: 1,
        validator: 'time',
        editor: 'time',
        uom: 'time',
        isRequired: true,
      },
      dividerField,
      commentsField,
    ],
    primaryFieldUom: 'time',
    deduplicationRule: 'sleep',
    editor: 'duration',
    canSync: true,
    validator: 'number',
    primaryField: 'duration',
    preferredTimeUnit: 'hours',
    aggregateType: 'total',
    showTimeRangeWithEntries: true,
    graphConfig: {
      type: 'duration',
      converter: 'secondsToHours',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
    },
    ...commonProps,
    decimalPlaces: 0,
  },
  'body-fat': {
    entryFields: [
      {
        modelField: 'fatRatio',
        name: null,
        rank: 0,
        validator: 'percent',
        showUomInline: true,
        editor: 'number',
        uom: 'percent',
        isRequired: true,
      },
      dividerField,
      commentsField,
      userTimeField,
    ],
    primaryFieldUom: 'percent',
    primaryField: 'fatRatio',
    editor: 'percent',
    validator: 'number',
    canSync: true,
    primaryField_snakeCase: 'fat_ratio',
    //api_field: 'fat_ratio',
    aggregateType: 'latest',
    prefillWithHistorical: true,
    graphConfig: {
      type: 'line',
      converter: 'decimalToPercent',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
      web: {
        hideLogsOnDetail: true,
        timeframes: ['month', 'quarter', 'year'],
        showLegacyStats: true,
      },
      // don't hide graph just because there's no recent data, because old data carries forward
      showSummaryIfAnyDataExists: true,
    },
    ...commonProps,
    supportsGoals: false,
  },
  calories: {
    entryFields: [
      {
        modelField: 'calories',
        name: null,
        rank: 0,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        editor: 'number',
        uom: 'cal',
        isRequired: true,
      },
      {
        modelField: 'carbohydrates',
        name: 'Carbohydrates',
        rank: 20,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        uom: 'g',
        isRequired: false,
      },
      {
        modelField: 'fat',
        name: 'Fat',
        rank: 21,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        uom: 'g',
        isRequired: false,
      },
      {
        modelField: 'protein',
        name: 'Protein',
        rank: 22,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        uom: 'g',
        isRequired: false,
      },
      {
        modelField: 'fiber',
        name: 'Fiber',
        rank: 23,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        uom: 'g',
        isRequired: false,
      },
      {
        modelField: 'sodium',
        name: 'Sodium',
        rank: 24,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        uom: 'mg',
        isRequired: false,
      },
      dividerField,
      commentsField,
      userTimeField,
    ],
    primaryFieldUom: 'cal',
    primaryField: 'calories',
    zeroEntryAllowed: true,
    editor: 'number',
    canSync: true,
    validator: 'number',
    aggregateType: 'total',
    graphConfig: {
      type: 'calories',
      greenTarget: 2000,
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
    },
    ...commonProps,
  },
  'blood-glucose': {
    entryFields: [
      {
        modelField: 'bloodGlucose',
        name: null,
        rank: 0,
        validator: 'number',
        showUomInline: true,
        decimals: 0,
        zeroAllowed: false,
        editor: 'number',
        uom: 'mg/dL',
        isRequired: true,
      },
      dividerField,
      commentsField,
      userTimeField,
    ],
    primaryFieldUom: 'mg/dL',
    primaryField: 'bloodGlucose',
    editor: 'number',
    canSync: true,
    validator: 'number',
    primaryField_snakeCase: 'blood_glucose',
    aggregateType: 'latest',
    graphConfig: {
      type: 'minMax',
      summaryTimeframe: 'week',
      // TODO: these applicable timeframes change between mobile and web
      timeframes: ['week', 'month'],
    },
    ...commonProps,
    decimalPlaces: 0,
  },
  'heart-rate': {
    primaryFieldUom: 'bpm',
    primaryField: ['minHeartRate', 'maxHeartRate', 'avgHeartRate', 'heartRate'],
    hideEmptyFields: true,
    validator: 'heartRate',
    canSync: true,
    editor: 'heartRate',
    deduplicationRule: 'heartRate',
    primaryField_snakeCase: 'heart_rate',
    aggregateType: 'latest',
    isReadOnly: true,
    entryFields: [
      {
        modelField: 'minHeartRate',
        name: 'Min Heart Rate',
        rank: 0,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        uom: 'bpm',
        hideIfEmpty: true,
        isRequired: true,
      },
      {
        modelField: 'maxHeartRate',
        name: 'Max Heart Rate',
        rank: 0,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        uom: 'bpm',
        hideIfEmpty: true,
        isRequired: true,
      },
      {
        modelField: 'avgHeartRate',
        name: 'Average Heart Rate',
        rank: 0,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        uom: 'bpm',
        hideIfEmpty: true,
        isRequired: true,
      },
      {
        modelField: 'heartRate',
        name: 'Heart Rate',
        rank: 0,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        uom: 'bpm',
        hideIfEmpty: true,
        isRequired: true,
      },
      dividerField,
      userTimeField,
    ],
    graphConfig: {
      type: 'multiLine',
      summaryTimeframe: 'week',
      lineDefinitions: [
        {
          valuePropName: 'minHeartRate',
          color: 'gray',
          yAxisLabelSuffix: 'min',
        },
        {
          valuePropName: 'avgHeartRate',
          color: 'cyan',
          yAxisLabelSuffix: 'avg',
        },
        {
          valuePropName: 'maxHeartRate',
          color: 'red',
          yAxisLabelSuffix: 'max',
          isPrimary: true,
        },
      ],
      web: {
        hideLogsOnDetail: false,
        timeframes: ['month'],
      },
      // TODO: these applicable timeframes change between mobile and web
      timeframes: ['week', 'month'],
    },
    ...commonProps,
    // should always show after other trackers
    forcedRank: 1,
    supportsGoals: false,
  },
  'blood-pressure': {
    entryFields: [
      {
        modelField: 'systolic',
        name: 'Systolic',
        rank: 0,
        validator: 'number',
        decimals: 0,
        zeroAllowed: false,
        editor: 'number',
        //isPrimary: true,
        uom: null,
        isRequired: true,
      },
      {
        modelField: 'diastolic',
        name: 'Diastolic',
        rank: 1,
        validator: 'number',
        decimals: 0,
        zeroAllowed: false,
        editor: 'number',
        //isPrimary: true,
        uom: null,
        isRequired: true,
      },
      dividerField,
      commentsField,
      userTimeField,
    ],
    primaryFieldUom: null,
    primaryField: ['systolic', 'diastolic'],
    canSync: true,
    editor: 'bloodPressure',
    validator: 'bloodPressure',
    aggregateType: 'latest',
    graphConfig: {
      type: 'multiLine',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
      lineDefinitions: [
        {
          valuePropName: 'systolic',
          color: 'gray',
          yAxisLabelSuffix: 'sys',
        },
        {
          valuePropName: 'diastolic',
          color: 'cyan',
          yAxisLabelSuffix: 'dia',
        },
      ],
      // don't hide graph just because there's no recent data, because old data carries forward
      showSummaryIfAnyDataExists: true,
    },
    ...commonProps,
    decimalPlaces: 0,
    supportsGoals: false,
  },
  'custom-counter': {
    primaryFieldUom: null,
    primaryField: 'quantity',
    zeroEntryAllowed: true,
    editor: 'number',
    canSync: false,
    validator: 'number',
    aggregateType: 'total',
    entryFields: [
      {
        modelField: 'quantity',
        name: null,
        rank: 0,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        //isPrimary: true,
        uom: null,
        isRequired: true,
      },
      dividerField,
      commentsField,
      userTimeField,
    ],
    graphConfig: {
      type: 'bar',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
    },
    ...commonProps,
    decimalPlaces: 2,
  },
  'custom-duration': {
    entryFields: [
      {
        modelField: 'startTime',
        name: 'Start Time',
        rank: 0,
        validator: 'time',
        editor: 'time',
        uom: 'time',
        isRequired: true,
      },
      {
        modelField: 'endTime',
        name: 'End Time',
        rank: 1,
        validator: 'time',
        editor: 'time',
        uom: 'time',
        isRequired: true,
      },
      dividerField,
      commentsField,
    ],
    editor: 'duration',
    validator: 'number',
    primaryFieldUom: 'time',
    preferredTimeUnit: 'userDefined',
    primaryField: 'duration',
    aggregateType: 'total',
    showTimeRangeWithEntries: true,
    graphConfig: {
      type: 'duration',
      converter: 'secondsToHours',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
    },
    ...commonProps,
    decimalPlaces: 0,
  },
  'custom-question-freeform': {
    entryFields: [
      {
        modelField: 'response',
        name: null,
        rank: 0,
        validator: 'string',
        editor: 'freeform',
        uom: null,
        isRequired: true,
        maxLength: 1000,
      },
      dividerField,
      commentsField,
    ],
    primaryFieldUom: null,
    primaryField: 'response',
    editor: 'freeform',
    canSync: false,
    validator: 'string',
    aggregateType: 'latest',
    multipleValuesDisabled: true,
    doesNotLogExactTime: true,
    graphConfig: {
      type: 'freeformQuestion',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
      showAsListOnLargeTimeframes: true,
    },
    ...commonProps,
    supportsGoals: false,
  },
  'custom-question-multiple-choice': {
    entryFields: [
      {
        modelField: 'response',
        name: null,
        rank: 0,
        validator: 'string',
        editor: 'multipleChoice',
        uom: null,
        isRequired: true,
      },
      dividerField,
      commentsField,
    ],
    primaryFieldUom: null,
    primaryField: 'response',
    editor: 'multipleChoice',
    canSync: false,
    validator: 'string',
    doesNotLogExactTime: true,
    aggregateType: 'latest',
    multipleValuesDisabled: true,
    graphConfig: {
      type: 'multipleChoiceQuestion',
      summaryTimeframe: 'week',
      timeframes: ['week', 'month'],
    },
    ...commonProps,
    supportsGoals: false,
  },
  exerbotics: {
    primaryFieldUom: 'lbs',
    primaryField: 'strIdx',
    selectOnlyDaysWithData: true, // this doesn't actually enable/ disable selection (that's built into the graph), but switches on related features (like disabling today button)
    showExtraInfoInTitle: true,
    extraInfoInTitle: ['exerbotics'],
    hideEmptyFields: true,
    validator: 'none',
    canSync: true,
    editor: 'exerbotics',
    aggregateType: 'latest',
    isReadOnly: true,
    entryFields: [
      {
        modelField: 'strIdx',
        name: null,
        rank: 0,
        validator: 'number',
        decimals: 2,
        zeroAllowed: true,
        showUomInline: true,
        editor: 'number',
        uom: 'lbs',
        isRequired: true,
      },
      {
        modelField: 'protocolName',
        name: 'Protocol',
        rank: 1,
        validator: 'string',
        editor: 'freeform',
        isRequired: true,
      },
      {
        modelField: 'protocolDescription',
        name: 'Protocol Description',
        rank: 2,
        validator: 'string',
        editor: 'freeform',
        isRequired: true,
      },
      dividerField,
      userTimeField,
    ],
    graphConfig: {
      type: 'exerbotics',
      summaryTimeframe: 'month',
      timeframes: ['month', 'quarter', 'year'],
      web: {
        timeframes: ['month', 'quarter', 'year'],
        showHoverStats: true,
      },
    },
    ...commonProps,
    // should always show after other trackers
    forcedRank: 1,
    supportsGoals: false,
  },
};

/**
 * Returns string representing the client-side type of tracker, which maps the API data to
 * a client-side configuration for how to display and enter data for the tracker
 * @param {Object} tracker tracker from API
 * @return string
 */
const getTrackerType = tracker => {
  let type = tracker.meta.slug;
  if (tracker.meta.logFormat === 'counters-log') {
    type = 'custom-counter';
  } else if (tracker.meta.logFormat === 'duration-log') {
    type = 'custom-duration';
  } else if (tracker.meta.logFormat === 'questions-log') {
    if (tracker.meta.config && tracker.meta.config.options) {
      type = 'custom-question-multiple-choice';
    } else {
      type = 'custom-question-freeform';
    }
  } else if (tracker.meta.tags.includes('Exerbotics')) {
    type = 'exerbotics';
  }
  // servings trackers have the servings tag
  if (tracker.meta.tags && tracker.meta.tags.find(tag => tag === 'Servings')) {
    type = 'servings';
  }
  return type;
};

/**
 * Takes log data for a range and outputs the corresponding graph slice.
 *
 * @param {Object} trackerOrTrackerType object from the API, camel-case-ized (e.g., with camelcase-keys) /
 *                    or the string tracker type.
 * @return {Object} object with additional tracker properties:
 *                  type: categorization of the tracker that's relevant to the client
 *                        (e.g. all trackers of this type have these props)
 *                  alternateName: alternative canonical name to the name provided by the API (optional)
 *                  primaryFieldUom: unit of measure key for the primary field. Will need to be translated into
 *                        plural or metric
 *                  primaryField: required data for log entry (e.g., quantity or duration).
 *                                Can be an array if there are multiple required filds
 *                  aggregateType: 'total' or 'latest'. How to condense data into a single value for a day
 *                  activityOptions: Array of objects with id and name fields defining activity types that be
 *                                associted with the log entry (activity only)
 *                  isActivityTypeRequired: if true, an activity type must be submitted with the log to the API
 *                  defaultActivityType: number id to use if not activity is specified by the user
 *                  changeActivityTypeBasedOnNotes: If true, notes field is used for choosing activity
 *                  prefillWithHistorical: If true, a new entry field should indicate what the previous value was
 *                  hasParentChildRelationships: If true, one log entry may include the quantities present in another.
 *                                The children will have "deduplicated" set to true. This flag indicates we may want to
 *                                present this data in another way
 *                  graphConfig: object defining graph-specific properties (TBD)
 **/
const getTrackerDisplayProperties = trackerOrTrackerType => {
  const type =
    typeof trackerOrTrackerType === 'string'
      ? trackerOrTrackerType
      : getTrackerType(trackerOrTrackerType);
  let definition = typeMap[type];
  if (!definition) {
    // usually custom-counter is a pretty permissive type
    return { type: 'custom-unknown', ...customUnknown };
  }
  return { type, ...definition };
};

export default getTrackerDisplayProperties;

export { getTrackerType };
