import * as nativeSvgComponents from './native';
import * as webSvgComponents from './web';

// Even though react native web translates native svg to web, there's still reason to specifically override this,
// see https://github.com/react-native-svg/react-native-svg/issues/1471
const getUniversalSvgComponents = OS => {
  return OS === 'web' ? webSvgComponents : nativeSvgComponents;
};

export { getUniversalSvgComponents };
