import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { last, first } from 'lodash';
import getLinePoints from './getLinePoints';
import svgPath from './curvedSvgPath';

export default class LiftGainGraphedLine extends Component {
  render() {
    const {
      data,
      head,
      tail,
      svg,
      drawHeight,
      drawWidth,
      color,
      minValue,
      maxValue,
      columnWidth,
      baseline,
      overBaselineColor,
      baselineColor,
      smoothing,
      lineWidth,
      pointRadius,
    } = this.props;
    const { G, Polygon, Defs, ClipPath, Rect, Path, Ellipse } = svg;

    const { pathPoints, dataPoints } = getLinePoints({
      dateValuePairs: data,
      minValue,
      maxValue,
      drawHeight,
      drawWidth,
      columnWidth,
      headValuePair: head,
      tailValuePair: tail,
    });

    if (!pathPoints.length) {
      return null;
    }

    let polygonPathString = '';
    const polygonPoints = pathPoints.slice();
    // end to bottom left
    polygonPoints.push({ x: last(pathPoints).x, y: drawHeight });
    // bottom left to bottom right
    polygonPoints.push({ x: first(pathPoints).x, y: drawHeight });
    // bottom right to first
    polygonPoints.push({ x: first(pathPoints).x, y: first(pathPoints).y });
    polygonPathString = polygonPoints.reduce(
      (acc, point, i, a) => `${acc} ${point.x},${point.y}`,
      ''
    );

    const bottomRectHeight = drawHeight * ((baseline - minValue) / (maxValue - minValue));

    // line at the top of the polygon
    // slight smoothing so the points are just a little bit round
    const pathString = svgPath(pathPoints, smoothing);

    return (
      <G fill="blue">
        {pathPoints &&
        pathPoints.length > 1 /* make sure only head/ tail just shows the line, nothing else */ ? (
          <G>
            <Defs>
              <ClipPath id={'clip1'}>
                <Polygon points={polygonPathString} />
              </ClipPath>
            </Defs>
            <Polygon
              stroke={overBaselineColor}
              fill={overBaselineColor}
              points={polygonPathString}
            />
            <Rect
              x="0"
              y={drawHeight - bottomRectHeight}
              width={drawWidth}
              height={bottomRectHeight}
              fill={baselineColor}
              clipPath="url(#clip1)"
            />
            {pathPoints && pathPoints.length > 1 ? (
              <Path stroke={color} fill="none" d={pathString} strokeWidth={lineWidth} />
            ) : null}
          </G>
        ) : null}
        {dataPoints.length === 1
          ? dataPoints.map(value => (
              <Ellipse
                key={value.date}
                cx={value.x}
                cy={value.y}
                rx={pointRadius}
                ry={pointRadius}
                fill={color}
              />
            ))
          : null}
      </G>
    );
  }
}

LiftGainGraphedLine.propTypes = {
  // object containing all of the needed SVG components.
  // This is used to pass the specific web/ mobile implementation
  svg: PropTypes.object.isRequired,
  // The actual width and height of the graphable area
  // So total height minus any elements that should not contain the actual graph
  drawHeight: PropTypes.number.isRequired,
  drawWidth: PropTypes.number.isRequired,
  // value between 0 and 1 defining how much to smooth the line transition between data points
  smoothing: PropTypes.number.isRequired,
  // The points to graph
  // Even if your data source has non number values (e.g., blood pressure), you must transform it
  // into a single value for purposes of graphing a single line.
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ).isRequired,
  //Optionally include off-chart "head" and "tail" points so we can draw where the graph is "going"
  head: PropTypes.shape({
    date: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  tail: PropTypes.shape({
    date: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  // color for the lines
  color: PropTypes.string.isRequired,
  // There's reasons not to let this line calculate these on its own (e.g., multiple lines on the same graph)
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  // line/ dot params
  lineWidth: PropTypes.number.isRequired,
  pointRadius: PropTypes.number.isRequired,
  // start the actual main graph points in by X pixels relative to the draw width
  // use this to create longer head/ tail lines with shorter distance between points
  pointsOffset: PropTypes.number,
};

LiftGainGraphedLine.defaultProps = {
  pointsOffset: 0,
};
