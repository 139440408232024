import { types, getEnv, isAlive } from 'mobx-state-tree';
import { v4 as uuid } from 'uuid';
import { uniqBy } from 'lodash';
import { dateStringToJsDate, jsDateToDateString } from '../../../lib/dates';
import { imageMetadataToAttribution, resolveCardImage } from '../imageUtils';

const ProgramCard = types
  .model('ProgramCard', {
    id: types.optional(types.identifier, () => uuid()),
    serverId: types.maybeNull(types.number),
    archived: types.optional(types.boolean, false),
    title: types.string,
    cardOpened: types.optional(types.boolean, false),
    //components: types.optional(types.array(ProgramCardComponent), []),
    // image from unsplash
    background: types.maybeNull(types.frozen()),
    // custom uploaded image
    upload: types.maybeNull(types.frozen()),
    permissionLevel: types.optional(types.number, 0),
    stackId: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    version: types.maybeNull(types.number),
    companyId: types.maybeNull(types.number),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
  })
  .views(self => ({
    get sortKey() {
      return self.startAt;
    },
    get image() {
      return resolveCardImage({ uploadedImage: self.upload, catalogImage: self.background });
    },
    get primaryGrouping() {
      if (!self.share.endAt || self.share.endAt >= getEnv(self).getToday()) {
        return 'current';
      } else {
        return 'previous';
      }
    },
    get trackingDate() {
      /*if (self.share.endAt) {
        return self.share.endAt;
      }*/
      return getEnv(self).getToday();
    },
    get hasTrackers() {
      // if bigger than three components or has 1+ buttons (and eventually 1+ trackers)
      return self.components.find(c => c.hasTracker);
    },
    get hasGoals() {
      return self.components.find(c => c.hasGoal);
    },
    get summaryComponents() {
      // Using regular image components until Chris hooks this up.
      const hasFeatureImage = self.image !== null;

      const summaryComponents = [];
      const firstTextComponent = self.components.find(
        c => c.type === 'text' || c.type === 'headline'
      );
      if (firstTextComponent) {
        summaryComponents.push(firstTextComponent);
      }

      // Just video for now but possibly images later.
      if (!hasFeatureImage) {
        const firstVisualComponent = self.components.find(c => c.type.startsWith('video'));
        if (firstVisualComponent) {
          summaryComponents.push(firstVisualComponent);
        }
      }

      return summaryComponents;
    },
    get expandedComponents() {
      return self.components;
    },
    get trackerComponents() {
      return self.expandedComponents.filter(
        c =>
          c.hasTracker /* really important to use this prop because it will filter out unresolved tracker refs */
      );
    },
    get isNew() {
      return self.serverId === null;
    },
    get isRead() {
      return self.cardOpened === true;
    },
    get createdOnDate() {
      return jsDateToDateString(dateStringToJsDate(self.createdAt));
    },
    get showInLibrary() {
      return self.permissionLevel > 0;
    },
    get isCompanyCard() {
      return self.permissionLevel > 1;
    },
    // returns object with { id, authorName, imageUrl, authorUrl } if there is a feature image
    // and that image requires attribution
    get featureImageAttribution() {
      return imageMetadataToAttribution(self.background);
    },
    /*
      returns object in the following format:
      {
        images: [{ id, authorName, imageUrl, authorUrl, source }], // one for each image
        authors: [{ authorName, authorUrl }] // one for each author
      }
    */
    get imageAttributionSummary() {
      // everything has an attribution prop that either returns null or attribution
      // so ask them all and remove the nulls
      // don't sort because this should keep attribution in the order the images appear on the card
      const attributableImages = [self.featureImageAttribution]
        .concat(self.components.map(c => c.imageAttribution))
        .filter(a => !!a);

      return {
        images: attributableImages,
        authors: uniqBy(attributableImages, i => i.authorName).map(i => ({
          authorName: i.authorName,
          authorUrl: i.authorUrl,
        })),
      };
    },
    get isAlive() {
      return isAlive(self);
    },
  }));

export default ProgramCard;
