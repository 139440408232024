import { types } from 'mobx-state-tree';

// This maybe should be in the stores area
// Kind of more of a UI Store than anything
const FormError = types.model('FormError', {
  field:
    types.string /*types.enumeration([
    'inviteId',
    'patientId',
    'email',
    'name',
    'firstName',
    'lastName',
    'password',
    'newPassword',
    'currentPassword',
    'birthDate',
    'emailConfirmationCode',
    'sso', // Not really a field, but this handling works well here
  ]),*/,
  type:
    types.string /* types.enumeration([
    'blank',
    'alreadyUsed',
    'invalid',
    'notMinimumLength',
    'passwordsDoNotMatch',
    // SSO errors
    'RESPONSE_MALFORMED',
    'NO_AUTHORIZATION_CODE',
    'UNRECOGNIZED_URL',
    'UNEXPECTED',
    'membershipLimitExceeded',
  ]), */,
});

export default FormError;
