import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ColumnHighlight extends Component {
  render() {
    const {
      selectedIndex,
      dataMarkerStartX,
      columnWidth,
      height,
      svg,
      selectionBoxFillColor,
      selectionBoxStrokeColor,
      selectionBoxCornerRadius,
      highlightOffsetFromTop,
      showAxisDash,
      xAxisCaptionY,
      axisMarkerFontSize,
      xAxisMarkerTextColor,
      useSkinnyLine,
      maxBarHeight,
      dataMarkerStartY,
      // only works with useSkinnyLine to make the line the same color as the dot
      colorMapper,
      // only needed if using the color mapper
      data,
      showDataPoint,
      dataPointRadius,
      getYPosForIndex,
    } = this.props;

    const { Rect, G, Text, Ellipse } = svg;

    // lines that might be fat buttons or skinny-ish lines depending on available width (bar graphs)

    const getXPosForIndex = index =>
      index < 0 ? null : dataMarkerStartX - columnWidth / 2 + columnWidth * index;

    // recond rectangle is because react-native-svg doesn't support shadows
    if (useSkinnyLine && selectedIndex >= 0) {
      return (
        <G>
          <Rect
            id="Rectangle-3"
            fill={colorMapper ? colorMapper(data[selectedIndex].value) : selectionBoxFillColor}
            strokeWidth={0}
            stroke={selectionBoxStrokeColor}
            x={getXPosForIndex(selectedIndex) + columnWidth - 0.5}
            y={highlightOffsetFromTop}
            width={1}
            height={
              maxBarHeight +
              dataMarkerStartY -
              1 /* cheat to avoid popping out of bottom */ -
              highlightOffsetFromTop /* cheat so it doesn't cut of lines at the edge */
            }
            rx={0}
            ry={0}
          />
          {showDataPoint && (
            <Ellipse
              cx={getXPosForIndex(selectedIndex) + columnWidth}
              cy={getYPosForIndex(selectedIndex) + axisMarkerFontSize + dataPointRadius * 2}
              rx={dataPointRadius}
              ry={dataPointRadius}
              fill={colorMapper ? colorMapper(data[selectedIndex].value) : selectionBoxFillColor}
            />
          )}
        </G>
      );
    }

    return selectedIndex >= 0 ? (
      <G>
        <Rect
          id="Rectangle-3"
          fill={selectionBoxFillColor}
          strokeWidth={0.25}
          stroke={selectionBoxStrokeColor}
          x={getXPosForIndex(selectedIndex) + columnWidth / 2}
          y={1 + highlightOffsetFromTop}
          width={columnWidth}
          height={
            height - 2 - highlightOffsetFromTop /* cheat so it doesn't cut of lines at the edge */
          }
          rx={selectionBoxCornerRadius}
          ry={selectionBoxCornerRadius}
        />
        {showAxisDash ? (
          <Text
            fontSize={axisMarkerFontSize}
            fill={xAxisMarkerTextColor}
            fillOpacity={1}
            x={getXPosForIndex(selectedIndex) + columnWidth}
            y={xAxisCaptionY}
            weight="bold"
            textAnchor="middle">
            |
          </Text>
        ) : null}
      </G>
    ) : null;
  }
}

ColumnHighlight.propTypes = {
  // number of pixels to shrink the bar down, from; used by question trackers to not put highlight overlapping with graph
  highlightOffsetFromTop: PropTypes.number,
  showDataPoint: PropTypes.bool,
};

ColumnHighlight.defaultProps = {
  highlightOffsetFromTop: 0,
  showDataPoint: false,
};

export default ColumnHighlight;
